import React from "react";

const ABOUtPROSPECTCAPITAL = () => {
  return (
    <>
      <section>
        <div className="py-50 et_pb_section et_pb_section_1 about-section et_section_regular et_section_transparent  ">
          <div
            className="et_pb_row et_pb_row_1"
            style={{ maxWidth: " 1650px", width: " 95%" }}
          >
            <div className="et_pb_column et_pb_column_4_4 et_pb_column_2  et_pb_css_mix_blend_mode_passthrough et-last-child">
              <div className="et_pb_module et_pb_text et_pb_text_2 about-title et_pb_bg_layout_light  et_pb_text_align_left">
                <div className="et_pb_text_inner text-980-center text-center">
                  <h1>About Prospect Capital</h1>
                </div>
              </div>
            </div>
          </div>
          <div
            className="et_pb_row et_pb_row_2 about-block about-card-area"
            id="about-widget"
            style={{ maxWidth: " 1650px", width: " 95%" }}
          >
            <div className="row about-prospect-capital-card-area">
              <div
                className="col-3"
                data-aos="flip-left"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="2000"
              >
                <div className="et_pb_column et_pb_column_1_4 et_pb_column_3  et_pb_css_mix_blend_mode_passthrough">
                  <div className="et_pb_module et_pb_text et_pb_text_3 about-blurb et_pb_bg_layout_light  et_pb_text_align_center">
                    <div className="et_pb_text_inner">
                      <h4>Team</h4>
                      <div className="textwidget">
                        <p
                          className="presentations-content"
                          style={{
                            minHeight: "0px",
                            maxHeight: "none",
                            height: "210px",
                          }}
                        >
                          PCM's senior executives have worked together for over
                          20 years, through multiple economic and investing
                          cycles.
                        </p>
                      </div>
                      <div className="spacer">spacer</div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-3"
                data-aos="flip-left"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="2000"
              >
                <div className="et_pb_column et_pb_column_1_4 et_pb_column_4  et_pb_css_mix_blend_mode_passthrough">
                  <div className="et_pb_module et_pb_text et_pb_text_4 et_pb_bg_layout_light  et_pb_text_align_center">
                    <div className="et_pb_text_inner" id="presentations-widget">
                      <h4>Strategy</h4>
                      <div className="textwidget">
                        <p
                          className="presentations-content"
                          style={{
                            minHeight: "0px",
                            maxHeight: "none",
                            height: "210px",
                          }}
                        >
                          Focused on the U.S. middle market, with 81% of
                          investment assets in loans secured by a first lien or
                          other senior secured debt<sup>(2)</sup> and with 122
                          portfolio companies spanning 36 separate industries.
                        </p>
                      </div>
                      <div className="spacer">spacer</div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-3"
                data-aos="flip-left"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="2000"
              >
                <div className="et_pb_column et_pb_column_1_4 et_pb_column_5  et_pb_css_mix_blend_mode_passthrough">
                  <div className="et_pb_module et_pb_text et_pb_text_5 et_pb_bg_layout_light et_pb_text_align_center">
                    <div className="et_pb_text_inner">
                      <h4>MISSION</h4>
                      <div className="textwidget">
                        <p
                          className="presentations-content"
                          style={{
                            minHeight: "0px",
                            maxHeight: "none",
                            height: "210px",
                          }}
                        >
                          Deliver consistent returns and preserve capital,
                          independent of market timing, through a culture of
                          transparency, collaboration, and alignment with
                          investors
                        </p>
                      </div>
                      <div className="spacer">spacer</div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-3"
                data-aos="flip-left"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="2000"
              >
                <div className="et_pb_column et_pb_column_1_4 et_pb_column_3  et_pb_css_mix_blend_mode_passthrough et-last-child">
                  <div className="et_pb_module et_pb_text et_pb_text_3 about-blurb et_pb_bg_layout_light et_pb_text_align_center">
                    <div className="et_pb_text_inner about-propect">
                      <h4>AWARDS</h4>
                      <div className="textwidget">
                        <div className=" presentations-content">
                          <div>
                            <div style={{ height: "10px" }}>
                              <span
                                style={{
                                  display: "inline-block",
                                  textAlign: "right",
                                  width: "100%",
                                  maxWidth: "min(100%, 200px)",
                                  fontSize: "20px",
                                }}
                              >
                                <sup>(3)</sup>
                              </span>
                            </div>
                            <img
                              style={{ maxWidth: "min(100%, 200px)" }}
                              src="//s23.q4cdn.com/831501628/files/images/2023/03/CFI-co-Winner-Awards-2023-transparent.png"
                              alt=""
                            />
                          </div>
                          <div>
                            <p
                              className=""
                              style={{ minHeight: "0px", maxHeight: "none" }}
                            >
                              Best Middle-Market Lending Investor US
                            </p>
                            <p
                              className=""
                              style={{ minHeight: "0px", maxHeight: "none" }}
                            >
                              Best Real Estate Investor US
                            </p>
                          </div>
                        </div>
                        <div className="spacer">spacer</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ABOUtPROSPECTCAPITAL;
