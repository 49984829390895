import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import SingleItem from './SingleItem';
import EnergisesSingleItem from './EnergisesSingleItem';

const Metals = ({ MetalsData, MetalslivePrice, Energisesdata, EnergiseslivePrice }) => {

    return (
        <>

<div class="tab-content" id="pills-tabContent">
              <div
                class="tab-pane fade show active"
                id="pills-top"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
                tabindex="0"
              >
                
                <div class="d-flex gap-2 flex-column my-3" id="coinContainer">

                {MetalsData.map((data, index) => <SingleItem data={data} key={data?._id} index={index} MetalslivePrice={MetalslivePrice}></SingleItem>)}

                {Energisesdata.map((data, index) => <EnergisesSingleItem data={data} key={data?._id} index={index} EnergiseslivePrice={EnergiseslivePrice}></EnergisesSingleItem>)}

                    </div>
              </div>

             
            </div>

        </>
    );
};


export default Metals;