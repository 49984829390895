import React, { useEffect, useState } from 'react';
import SingleItem from './SingleItem';

const Energises = ({handleClose}) => {
    const [data, setData] = useState([]);


    useEffect(() => {
        fetch(`https://api2.prospectstreetx.com/api/user/trade/market/energises/view`, {
            method: 'GET',
        })
            .then(res => res.json())
            .then(data => {
                setData(data.data);
            });


    }, [])

        
    const IndicesSymbolString = data.reduce(
        (acc, curr) => `${acc}${curr.symbol},`,
        ""
      );
      const IndicesSymbol = IndicesSymbolString.substring(0, IndicesSymbolString?.length - 1);
    
      const [livePrice, setLivePrice] = useState([]);
    
      useEffect(() => {
        IndicesfetchData();
      }, [data]);
    
      const IndicesfetchData = async () => {
        if (IndicesSymbol?.length !== 0) {
          const data = await fetch(
            `https://marketdata.tradermade.com/api/v1/live?currency=${IndicesSymbol}&api_key=9iQMBHV42RgmV60x5iEi`
          );
          const apiResponse = await data.json();
          setLivePrice(apiResponse?.quotes);
        }
      };


      
    
    return (
        <>
             <div className="tab-content" id="pills-tabContent">
        <div className="tab-pane fade show active">
          <ul className="coin_menu mt-2">
          {data.map((data, index) => <SingleItem data={data} key={data?._id} index={index} livePrice={livePrice} handleClose={handleClose}></SingleItem>)}

           
          </ul>
        </div>
      </div>
        </>
    );
};

export default Energises;