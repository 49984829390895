import React, { useContext, useRef, useState } from "react";
import { AuthContext } from "../../../../Contexts/AuthContext/AuthProvider";
import { toast } from "react-toastify";
import axios from "axios";

const MobileWithdrawPage = () => {
  const { authUser } = useContext(AuthContext);

  const [dataValue, setDataValue] = useState({});

  const handleInputBlur = (event) => {
    const value = event.target.value;
    const field = event.target.name;
    const newUser = { ...dataValue };
    newUser[field] = value;
    setDataValue(newUser);
  };

  const refSubmitDis = useRef();

  const handleSubmitData = (event) => {
    event.preventDefault();
    refSubmitDis.current.setAttribute("disabled", true);
    if (dataValue?.NetworkType) {
      const storeData = { user_id: authUser?._id, ...dataValue };
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      axios
        .post(
          `https://api2.prospectstreetx.com/api/user/withdrawal/confirm`,
          storeData,
          config
        )
        .then((data) => {
          event.target.reset();
          toast.success(`${data.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          refSubmitDis.current.removeAttribute("disabled");
        })
        .catch((error) => {
          if (error?.response?.data?.success === false) {
            toast.error(`${error?.response?.data?.message}`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            refSubmitDis.current.removeAttribute("disabled");
          }
        });
    } else {
      toast.error(`Network field is required`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      refSubmitDis.current.removeAttribute("disabled");
    }
  };

  return (
    <>
      <section className="mobile-py-20-500 user-dashboard">
        <div className="user-dashboard">
          <div className="user-profile-area">
            <div className="container my-4">
              <div className="row gutters">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="card h-100">
                    <div className="card-body">
                      <form onSubmit={handleSubmitData}>
                        <div className="row gutters">
                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <h6 className="mb-3 text-primary">Withdrawal</h6>
                          </div>
                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                            <div className="form-group">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                Network
                              </label>
                              <select
                                name="NetworkType"
                                onBlur={handleInputBlur}
                                className="form-select"
                                required
                              >
                                <option selected disabled>
                                  select
                                </option>
                                <option value="BTC">BTC</option>
                                <option value="ETH">ETH</option>
                                <option value="USDT">USDT</option>
                              </select>
                            </div>
                          </div>

                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                            <div className="form-group">
                              <label
                                htmlFor="exampleFormControlInput2"
                                className="form-label"
                              >
                                Amount
                              </label>
                              <input
                                type="number"
                                name="Amount"
                                onBlur={handleInputBlur}
                                className="form-control"
                                id="exampleFormControlInput2"
                                aria-describedby="emailHelp"
                                required
                              />
                            </div>
                          </div>

                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                            <div className="form-group">
                              <label
                                htmlFor="exampleFormControlInput2"
                                className="form-label"
                              >
                                Withdrawal Address
                              </label>
                              <input
                                type="text"
                                name="WithdrawalAddress"
                                onBlur={handleInputBlur}
                                className="form-control"
                                id="exampleFormControlInput2"
                                aria-describedby="emailHelp"
                                required
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row gutters">
                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="text-right">
                              <button
                                type="submit"
                                id="submit"
                            className="btn btn-primary px-5 fw-normal d-block mt-3"
                                ref={refSubmitDis}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MobileWithdrawPage;
