import React, { useRef } from "react";

const INVEST = () => {
  const dataOneShow = useRef();
  const dataTwoShow = useRef();
  const dataThreeShow = useRef();
  const dataFourShow = useRef();
  const dataFiveShow = useRef();
  const dataSixShow = useRef();
  const dataSevenShow = useRef();
  const dataEightShow = useRef();
  const dataClickRef = useRef();

  const HandeShow = (data) => {
    switch (data) {
      case "data1":
        dataOneShow.current.style.display = "block";

        dataTwoShow.current.style.display = "none";
        dataThreeShow.current.style.display = "none";
        dataFourShow.current.style.display = "none";
        dataFiveShow.current.style.display = "none";
        dataSixShow.current.style.display = "none";
        dataSevenShow.current.style.display = "none";
        dataEightShow.current.style.display = "none";
        dataClickRef.current.style.display = "none";
        break;
      case "data2":
        dataTwoShow.current.style.display = "block";

        dataOneShow.current.style.display = "none";
        dataThreeShow.current.style.display = "none";
        dataFourShow.current.style.display = "none";
        dataFiveShow.current.style.display = "none";
        dataSixShow.current.style.display = "none";
        dataSevenShow.current.style.display = "none";
        dataEightShow.current.style.display = "none";
        dataClickRef.current.style.display = "none";
        break;
      case "data3":
        dataThreeShow.current.style.display = "block";

        dataTwoShow.current.style.display = "none";
        dataTwoShow.current.style.display = "none";
        dataFourShow.current.style.display = "none";
        dataFiveShow.current.style.display = "none";
        dataSixShow.current.style.display = "none";
        dataSevenShow.current.style.display = "none";
        dataEightShow.current.style.display = "none";
        dataClickRef.current.style.display = "none";
        break;
      case "data4":
        dataFourShow.current.style.display = "block";

        dataOneShow.current.style.display = "none";
        dataTwoShow.current.style.display = "none";
        dataThreeShow.current.style.display = "none";
        dataFiveShow.current.style.display = "none";
        dataSixShow.current.style.display = "none";
        dataSevenShow.current.style.display = "none";
        dataEightShow.current.style.display = "none";
        dataClickRef.current.style.display = "none";
        break;
      case "data5":
        dataFiveShow.current.style.display = "block";

        dataOneShow.current.style.display = "none";
        dataTwoShow.current.style.display = "none";
        dataThreeShow.current.style.display = "none";
        dataFourShow.current.style.display = "none";
        dataSixShow.current.style.display = "none";
        dataSevenShow.current.style.display = "none";
        dataEightShow.current.style.display = "none";
        dataClickRef.current.style.display = "none";
        break;
      case "data6":
        dataSixShow.current.style.display = "block";

        dataOneShow.current.style.display = "none";
        dataTwoShow.current.style.display = "none";
        dataThreeShow.current.style.display = "none";
        dataFourShow.current.style.display = "none";
        dataFiveShow.current.style.display = "none";
        dataSevenShow.current.style.display = "none";
        dataEightShow.current.style.display = "none";
        dataClickRef.current.style.display = "none";
        break;
      case "data7":
        dataSevenShow.current.style.display = "block";

        dataOneShow.current.style.display = "none";
        dataTwoShow.current.style.display = "none";
        dataThreeShow.current.style.display = "none";
        dataFourShow.current.style.display = "none";
        dataFiveShow.current.style.display = "none";
        dataSixShow.current.style.display = "none";
        dataEightShow.current.style.display = "none";
        dataClickRef.current.style.display = "none";
        break;
      case "data8":
        dataEightShow.current.style.display = "block";

        dataOneShow.current.style.display = "none";
        dataTwoShow.current.style.display = "none";
        dataThreeShow.current.style.display = "none";
        dataFourShow.current.style.display = "none";
        dataFiveShow.current.style.display = "none";
        dataSixShow.current.style.display = "none";
        dataSevenShow.current.style.display = "none";
        dataClickRef.current.style.display = "none";
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="et_pb_section et_pb_section_2 why-invest-section et_section_regular et_section_transparent py-50">
        <div className="et_pb_row et_pb_row_3 why-invest et_pb_row_4col">
          <div className="row">
            <div className="col-4 invest-col-4">
              <div className="et_pb_column et_pb_column_1_4 et_pb_column_6  et_pb_css_mix_blend_mode_passthrough">
                <div className="et_pb_module et_pb_text et_pb_text_6 why-invest-blurb et_pb_bg_layout_dark  et_pb_text_align_left">
                  <div className="et_pb_text_inner">
                    <h4 className="text-980-center">WHY INVEST IN PROSPECT?</h4>
                    {/* <!-- text widget  --> */}
                    <div className="textwidget m-980-auto" id="displayed-text">
                      <p ref={dataOneShow}>
                        Prospect Capital Corporation is among the oldest and
                        largest BDCs. Throughout our 20 years as a public
                        company, we have provided consistent returns to our
                        shareholders through our disciplined approach to
                        investing in the U.S. middle market.
                      </p>
                      <p ref={dataTwoShow} style={{ display: "none" }}>
                        Focus on Senior and Secured Lending PSEC is focused on
                        providing senior and secured term loans to U.S. middle
                        market businesses. Of our total investments, 81% are in
                        the form of loans secured by a first lien or other
                        secured debt2. For the quarter ended on March 31, 2024,
                        91% of our total investment income was interest income
                      </p>
                      <p ref={dataThreeShow} style={{ display: "none" }}>
                        Proven Organization Strategies Our team has developed a
                        broad and deep network of U.S. middle market
                        relationships over many years of investing, including
                        extensive relationships with private equity firms, other
                        capital providers, business owners and managers, and
                        intermediaries.
                      </p>
                      <p ref={dataFourShow} style={{ display: "none" }}>
                        Dividend Reinvestment Plan We encourage any shareholder
                        interested in participating in our dividend reinvestment
                        plan (also known as a “DRIP” or “DRP”) to contact his or
                        her broker to make sure such DRIP participation election
                        has been made for the benefit of such shareholder. In
                        making such DRIP election, be sure to specify to your
                        broker the desire to participate in the "Prospect
                        Capital Corporation DRIP plan through DTC" that issues
                        shares based on 95% of the market price (a 5% discount
                        to the market price), and not the broker's own
                        "synthetic DRIP” plan with 0% discount.
                      </p>
                      <p ref={dataFiveShow} style={{ display: "none" }}>
                        Broad Investment Portfolio Since its 2004 IPO, PSEC has
                        made over 400 investments totaling $20.7 billion of
                        capital with 122 current portfolio companies spanning 36
                        separate industries.
                      </p>
                      <p ref={dataSixShow} style={{ display: "none" }}>
                        Large and Experienced Team We cover the U.S. middle
                        market with a team of over 120 professionals with
                        experience investing across a range of industries and
                        through multiple economic and investing cycles, with
                        offices in New York, Florida, and Connecticut. Our
                        investment professionals are supported by a dedicated
                        team of attorneys, accountants, and other specialists.
                      </p>
                      <p ref={dataSevenShow} style={{ display: "none" }}>
                        Conservative, Strong Capitalization With $7.9 billion of
                        assets, PSEC is among the largest of the BDCs. We
                        benefit from a strong balance sheet with long-term
                        matched-book funding, reasonable leverage, and a high
                        level of unencumbered assets. As affirmation of our
                        financial profile, we have investment grade ratings from
                        S&P, Moody’s, Kroll, DBRS, and Egan-Jones. Management
                        and affiliates of Prospect own approximately 27% of
                        outstanding PSEC shares as of March 31, 2024, so we are
                        very much aligned with our shareholders
                      </p>
                      <p ref={dataEightShow} style={{ display: "none" }}>
                        Direct Stock Purchase Plan Prospect Capital Corporation
                        offers the opportunity to directly purchase its stock
                        through a Direct Stock Purchase Plan administered by
                        Equiniti Trust Company, LLC.
                      </p>
                      <p>&nbsp;</p>
                      <p  className="m-980-auto">
                        <em ref={dataClickRef}>
                          Click the links on the right to learn more about
                          Prospect.
                        </em>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-8 invest-col-8">
              <div className="button-container mt-980-20">
                {/* <!-- btn-cntr1 --> */}
                <div
                  className="et_pb_column et_pb_column_1_4 et_pb_column_7 icon-grid  et_pb_css_mix_blend_mode_passthrough btn-cntr1-area pb-3"
                  id="column1"
                >
                  <div className="row invest-icon-row g-0">
                    <div className="col-3">
                      <div
                        className="btnContainer"
                        onClick={() => HandeShow("data1")}
                      >
                        <div
                          id="hover-1"
                          data-aos="fade-right"
                          data-aos-duration="1500"
                          className="et_pb_module et_pb_image et_pb_image_0 wi-icons high-dividend et-animated et_had_animation aos-init aos-animate"
                        >
                          {" "}
                          <span className="et_pb_image_wrap ">
                            <img
                              src="//s23.q4cdn.com/831501628/files/images/irw/Money-Hand-icon.png"
                              sizes="(max-width: 143px) 100vw, 143px"
                              style={{ cursor: "pointer" }}
                              alt=""
                            />
                          </span>
                        </div>
                        <div className="et_pb_module et_pb_text et_pb_text_7 wi-label wi-label-row-1 et_pb_bg_layout_dark  et_pb_text_align_center">
                          <div className="et_pb_text_inner">
                            <p>
                              {" "}
                              Attractive <br className="break2" />
                              Dividend <br className="break1" /> Yield
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div
                        className="btnContainer"
                        onClick={() => HandeShow("data2")}
                      >
                        <div
                          id="hover-4"
                          data-aos="fade-right"
                          data-aos-duration="1500"
                          className="et_pb_module et_pb_image et_pb_image_1 wi-icons investment-portfolio et-animated et_had_animation aos-init aos-animate"
                        >
                          <span className="et_pb_image_wrap ">
                            <img
                              src="//s23.q4cdn.com/831501628/files/images/irw/Money-Branches-Icon.png"
                              sizes="(max-width: 144px) 100vw, 144px"
                              style={{ cursor: "pointer" }}
                              alt=""
                            />
                          </span>
                        </div>
                        <div className="et_pb_module et_pb_text et_pb_text_8 wi-label wi-label-row-2 et_pb_bg_layout_dark  et_pb_text_align_center">
                          <div className="et_pb_text_inner">
                            <p>
                              {" "}
                              Broad <br className="break2" /> Investment{" "}
                              <br className="break1" />
                              Portfolio
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div
                        className="btnContainer"
                        onClick={() => HandeShow("data3")}
                      >
                        <div
                          id="hover-2"
                          data-aos="fade-right"
                          data-aos-duration="1500"
                          className="et_pb_module et_pb_image et_pb_image_2 wi-icons secure-lending et-animated et_had_animation aos-init aos-animate"
                        >
                          {" "}
                          <span className="et_pb_image_wrap ">
                            <img
                              src="//s23.q4cdn.com/831501628/files/images/irw/Handshake-Icon.png"
                              sizes="(max-width: 144px) 100vw, 144px"
                              style={{ cursor: "pointer" }}
                              alt=""
                            />
                          </span>
                        </div>
                        <div className="et_pb_module et_pb_text et_pb_text_9 wi-label wi-label-row-1 et_pb_bg_layout_dark  et_pb_text_align_center">
                          <div className="et_pb_text_inner">
                            <p>
                              {" "}
                              Focus on <br className="break2" /> Senior and{" "}
                              <br className="break1" />
                              Secured Lending
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div
                        className="btnContainer"
                        onClick={() => HandeShow("data4")}
                      >
                        <div
                          id="hover-5"
                          data-aos="fade-right"
                          data-aos-duration="1500"
                          className="et_pb_module et_pb_image et_pb_image_3 wi-icons experienced-team et-animated et_had_animation aos-init aos-animate"
                        >
                          {" "}
                          <span className="et_pb_image_wrap ">
                            <img
                              src="//s23.q4cdn.com/831501628/files/images/irw/People-group-Icon.png"
                              sizes="(max-width: 144px) 100vw, 144px"
                              style={{ cursor: "pointer" }}
                              alt=""
                            />
                          </span>
                        </div>
                        <div className="et_pb_module et_pb_text et_pb_text_10 wi-label wi-label-row-2 et_pb_bg_layout_dark  et_pb_text_align_center">
                          <div className="et_pb_text_inner">
                            <p>
                              {" "}
                              Large and <br className="break2" /> Experienced{" "}
                              <br className="break1" />
                              Team
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="et_pb_column et_pb_column_1_4 et_pb_column_8 icon-grid et_pb_css_mix_blend_mode_passthrough btn-cntr1-area"
                  id="column2"
                >
                  <div className="row invest-icon-row g-0">
                    <div className="col-3">
                      <div
                        className="btnContainer"
                        onClick={() => HandeShow("data5")}
                      >
                        <div
                          id="hover-3"
                          data-aos="fade-right"
                          data-aos-duration="1500"
                          className="et_pb_module et_pb_image et_pb_image_4 wi-icons strategies et-animated et_had_animation aos-init aos-animate"
                        >
                          {" "}
                          <span className="et_pb_image_wrap ">
                            <img
                              src="//s23.q4cdn.com/831501628/files/images/irw/Money-Flower-Icon.png"
                              sizes="(max-width: 144px) 100vw, 144px"
                              style={{ cursor: "pointer" }}
                              className=""
                              alt=""
                            />
                          </span>
                        </div>
                        <div className="et_pb_module et_pb_text et_pb_text_11 wi-label wi-label-row-1 et_pb_bg_layout_dark  et_pb_text_align_center">
                          <div className="et_pb_text_inner">
                            <p>
                              {" "}
                              Proven <br className="break2" /> Origination{" "}
                              <br className="break1" />
                              Strategies
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div
                        className="btnContainer"
                        onClick={() => HandeShow("data6")}
                      >
                        <div
                          id="hover-6"
                          data-aos="fade-right"
                          data-aos-duration="1500"
                          className="et_pb_module et_pb_image et_pb_image_5 wi-icons under-management et-animated et_had_animation aos-init aos-animate"
                        >
                          <span className="et_pb_image_wrap ">
                            <img
                              style={{
                                borderRadius: "50%",
                                cursor: "pointer",
                              }}
                              src="//s23.q4cdn.com/831501628/files/images/irw/Bank-Columns-Icon.png"
                              sizes="(max-width: 144px) 100vw, 144px"
                              className=""
                              alt=""
                            />
                          </span>
                        </div>

                        <div className="et_pb_module et_pb_text et_pb_text_12 wi-label wi-label-row-2 et_pb_bg_layout_dark  et_pb_text_align_center">
                          <div className="et_pb_text_inner">
                            <p>
                              {" "}
                              Conservative, <br className="break2" /> Strong{" "}
                              <br className="break1" />
                              Capitalization
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div
                        className="btnContainer"
                        onClick={() => HandeShow("data7")}
                      >
                        <div
                          id="hover-7"
                          data-aos="fade-right"
                          data-aos-duration="1500"
                          className="et_pb_module et_pb_image et_pb_image_5 wi-icons under-management et-animated et_had_animation aos-init aos-animate"
                        >
                          <span className="et_pb_image_wrap ">
                            <img
                              style={{
                                borderRadius: "50%",
                                cursor: "pointer",
                              }}
                              src="//s23.q4cdn.com/831501628/files/images/irw/Dividend-Reinvestment-icon.png"
                              sizes="(max-width: 144px) 100vw, 144px"
                              className="added-icon"
                              alt=""
                            />
                          </span>
                        </div>
                        <div
                          className="et_pb_module et_pb_text et_pb_text_11 wi-label wi-label-row-1
                                    et_pb_bg_layout_dark et_pb_text_align_center"
                        >
                          <div className="et_pb_text_inner">
                            <p>
                              {" "}
                              Dividend <br className="break1" /> Reinvestment{" "}
                              <br className="break2" />
                              Plan
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div
                        className="btnContainer"
                        onClick={() => HandeShow("data8")}
                      >
                        <div
                          id="hover-8"
                          data-aos="fade-right"
                          data-aos-duration="1500"
                          className="et_pb_module et_pb_image et_pb_image_5 wi-icons under-management et-animated et_had_animation aos-init aos-animate"
                        >
                          <span className="et_pb_image_wrap ">
                            <img
                              style={{
                                borderRadius: "50%",
                                cursor: "pointer",
                              }}
                              src="//s23.q4cdn.com/831501628/files/images/irw/Direct-Stock-Purchase-Plan-icon.png"
                              sizes="(max-width: 144px) 100vw, 144px"
                              className="added-icon"
                              alt=""
                            />
                          </span>
                        </div>
                        <div className="et_pb_module et_pb_text et_pb_text_12 wi-label wi-label-row-2 et_pb_bg_layout_dark  et_pb_text_align_center">
                          <div className="et_pb_text_inner">
                            <p>
                              Direct <br className="break2" /> Stock{" "}
                              <br className="break1" /> Purchase Plan
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default INVEST;
