import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const MobileHomeRoute = ({children}) => {

   
      
      function getCookie(name) {
        const cookieValue = document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`);
        return cookieValue ? cookieValue.pop() : '';
      }

      
    const location = useLocation();
    let token = getCookie('mobile_version_gffex_token');

    if(token ){
        return <Navigate to='/user/dashboard' sate={{from:location}} replace ></Navigate>
        
    }else{
        return children;
    }

  
    
}

export default MobileHomeRoute;