import React from "react";
import "./Loader.css";
const Loader = () => {
  return (
    <>
      <div className="loader-area">
        <div className="loader-section d-flex justify-content-center align-items-center">
          <figure>
            <object
              type="image/svg+xml"
              data="https://cdn.svgator.com/images/2023/06/shield-svg-preloader.svg"
              alt="Checkmark Shield loading animation"
              img=""
              width="100%"
            >  
              <img
                src="https://cdn.svgator.com/images/2023/06/shield-svg-preloader.svg"
                alt="Checkmark Shield loading animation"
                class="lightense-target"
              />
            </object>
          </figure>
        </div>
      </div>
    </>
  ); 
};

export default Loader;
