import React from "react";
import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown";
const SingleItem = ({ data, handleMiningInputBlur, handleSubmitData, setFindMiningId, refSubmitDis }) => {
  return (
    <>
      <div class="col-4">
        <div class="card-custom">
          <div class="badge-custom2"></div>
          <div class="badge-custom">{data?.percentage}%</div>
          <h5 class="mt-3">
            {data?.instant === 1 ? (
              <>
                {" "}
                <b>
                  <span style={{ color: "gold" }}>Daily for </span>
                </b>{" "}
                <span>
                  {" "}
                  {`${data?.period}`} {`${data?.period_type}`}{" "}
                </span>
              </>
            ) : (
              <>
                {" "}
                <b>
                  <span style={{ color: "gold" }}>AFTER for</span>
                </b>{" "}
                <span>
                  {" "}
                  {`${data?.period}`} {`${data?.period_type}`}{" "}
                </span>
              </>
            )}
          </h5>
          <div class="d-flex flex-row-reverse">
            <div class="text-box"> {data?.promotion_name} </div>
          </div>
          <div class="investment-info">
            <p>Min Deposit: ${data?.min_amount}</p>
            <p>Max Deposit: ${data?.max_amount}</p>
            <p>
              Principal:
              {data?.principal === 1 ? (
                <span> Returns</span>
              ) : data?.principal === 2 ? (
                <span> Included</span>
              ) : (
                ""
              )}
            </p>
          </div>
          <p className="btn btn-primary expired_time-css">
            <FlipClockCountdown
              to={data?.expired_time}
              className="flip-clock"
              labels={["", "", "", ""]}
            ></FlipClockCountdown>
          </p>
          <form onSubmit={handleSubmitData}>
            <label class="mt-2">Amount</label>
            <input
              name="amount"
              class="form-control"
              placeholder="Enter amount "
              required=""
              id="amount"
              onBlur={handleMiningInputBlur}
            />
            <div className="pt-3">
              <button type="submit" onClick={()=>setFindMiningId(data?._id)} ref={refSubmitDis} className="but1 hvr-shutter-in-horizontal">
                {" "}
                Subscribe Here<i className="ri-play-fill"></i>{" "}
              </button>
            </div>
          </form>
        </div>
      </div>

      {/* <div class="col-4">
                              <div class="card-custom">
                                <div class="badge-custom2"></div>
                                <div class="badge-custom">2.00%</div>
                                <h5 class="mt-3">
                                  <b>
                                    <span style={{ color: "gold" }}>
                                      Weekly for
                                    </span>
                                  </b>{" "}
                                  7 Days
                                </h5>
                                <div class="d-flex flex-row-reverse">
                                  <div class="text-box">Normal - 7 </div>
                                </div>
                                <div class="investment-info">
                                  <p>Min Deposit: $100.00</p>
                                  <p>Max Deposit: $50000</p>
                                  <p>Principal: Returns</p>
                                </div>
                                <div class="countdown">
                                  <div class="countdown-timer" id="countdown-24">
                                    <div class="days">149</div> :
                                    <div class="hours">15</div> :
                                    <div class="minutes">59</div> :
                                    <div class="seconds">49</div>
                                  </div>
                                </div>
                                <form>
                                  <label class="mt-2">Amount</label>
                                  <input
                                    name="amount"
                                    class="form-control"
                                    placeholder="Enter amount Eg. 5000"
                                    required=""
                                    id="amount"
                                  />
                                  <div className="pt-3">
                                    <button
                                      type="submit"
                                      className="but1 hvr-shutter-in-horizontal"
                                    >
                                      {" "}
                                      Invest Now <i className="ri-play-fill"></i>{" "}
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>
             */}
    </>
  );
};

export default SingleItem;
