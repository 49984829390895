import React, { useState } from "react";
import { Link } from "react-router-dom";
import FAQ from "./FAQ";
const Portfolio = () => {
  const [faqs, setFaqs] = useState([
    {
      question: "8th Avenue Food & Provisions, Inc.",
      Originations: "Middle-Market Lending	",
      Industry: "Food Products",
      Region: "MidWest US",
      Secured: ".",
      Unsecured: "",
      Equity: "",
      open: false,
    },
    {
      question: "Atlantis Health Care Group (Puerto Rico), Inc.",
      Originations: "Middle-Market Lending	",
      Industry: "Health Care Providers & Services	",
      Region: "Puerto Rico",
      Secured: ".",
      Unsecured: "",
      Equity: "",
      open: false,
    },
    {
      question: "Aventiv Technologies, LLC",
      Originations: "Middle-Market Lending",
      Industry: "Communications Equipment",
      Region: "SouthWest US",
      Secured: ".",
      Unsecured: "",
      Equity: "",
      open: false,
    },
    {
      question: "Aventiv Technologies, LLC",
      Originations: "Middle-Market Lending",
      Industry: "Communications Equipment",
      Region: "SouthWest US",
      Secured: ".",
      Unsecured: "",
      Equity: "",
      open: false,
    },
    {
      question: "Aventiv Technologies, LLC",
      Originations: "Middle-Market Lending",
      Industry: "Communications Equipment",
      Region: "SouthWest US",
      Secured: ".",
      Unsecured: "",
      Equity: "",
      open: false,
    },
  ]);

  const toggleFAQ = (index) => {
    setFaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }

        return faq;
      })
    );
  };

  return (
    <>
      <section className="portfolio-area">
        <div class="pane pane--breadcrumb container">
          <span class="BreadcrumbPaneDiv6">
            <div
              id="_ctrl0_ctl19_divModuleContainer"
              class="module module-page-title"
            >
              <div class="module_container module_container--outer">
                <div class="module_container module_container--inner">
                  <h2 class="module_title pageTitle">Portfolio</h2>
                </div>
              </div>
            </div>
          </span>
        </div>
        <div
          className="pane pane--content container protfolio-data-area"
          id="irwWrapper"
        >
          <span className="ContentPaneDiv">
            <span className="ContentPaneDiv1">
              <div
                id="_ctrl0_ctl37_divModuleContainer"
                className="module module-embed"
              >
                <div className="row" style={{ width: "100%" }}>
                  <div className=" col-3" style={{ marginBottom: "10px" }}>
                    <input
                      id="filter-search"
                      type="text"
                      className="srch-bx form-control"
                      placeholder="Search"
                      data-target-cols="0,1,2,3"
                    />
                    {/* <span
                      className="fa fa-search form-control-feedback form-search"
                      id="docsSearchIcon"
                    ></span> */}
                  </div>
                  <div className="col-3" style={{ marginBottom: "10px" }}>
                    <select
                      id="filter-strategy"
                      className="filter-status form-control"
                      data-target-cols="1"
                    >
                      <option value="">Origination Strategy</option>
                      <option value="Middle-Market Lending">
                        Middle-Market Lending
                      </option>
                      <option value="Middle-Market Lending / Buyout">
                        Middle-Market Lending / Buyout
                      </option>
                      <option value="Other">Other</option>
                      <option value="Real Estate">Real Estate</option>
                    </select>
                  </div>
                  <div className=" col-3" style={{ marginBottom: "10px" }}>
                    <select
                      id="filter-industry"
                      className="filter-status form-control"
                      data-target-cols="2"
                    >
                      <option value="">Industry</option>
                      <option value="Aerospace &amp; Defense">
                        Aerospace &amp; Defense
                      </option>
                      <option value="Air Freight &amp; Logistics">
                        Air Freight &amp; Logistics
                      </option>
                      <option value="Automobile Components">
                        Automobile Components
                      </option>
                      <option value="Building Products">
                        Building Products
                      </option>
                      <option value="Capital Markets">Capital Markets</option>
                      <option value="Commercial Services &amp; Supplies">
                        Commercial Services &amp; Supplies
                      </option>
                      <option value="Communications Equipment">
                        Communications Equipment
                      </option>
                      <option value="Construction &amp; Engineering">
                        Construction &amp; Engineering
                      </option>
                      <option value="Consumer Finance">Consumer Finance</option>
                      <option value="Distributors">Distributors</option>
                      <option value="Diversified Consumer Services">
                        Diversified Consumer Services
                      </option>
                      <option value="Diversified Financial Services">
                        Diversified Financial Services
                      </option>
                      <option value="Diversified Telecommunication Services">
                        Diversified Telecommunication Services
                      </option>
                      <option value="Electrical Equipment">
                        Electrical Equipment
                      </option>
                      <option value="Energy Equipment &amp; Services">
                        Energy Equipment &amp; Services
                      </option>
                      <option value="Equity Real Estate Investment Trusts (REITs)">
                        Equity Real Estate Investment Trusts (REITs)
                      </option>
                      <option value="Food &amp; Staples Retailing">
                        Food &amp; Staples Retailing
                      </option>
                      <option value="Food Products">Food Products</option>
                      <option value="Health Care Providers &amp; Services">
                        Health Care Providers &amp; Services
                      </option>
                      <option value="Health Care Technology">
                        Health Care Technology
                      </option>
                      <option value="Hotels, Restaurants &amp; Leisure">
                        Hotels, Restaurants &amp; Leisure
                      </option>
                      <option value="Household Durables">
                        Household Durables
                      </option>
                      <option value="IT Services">IT Services</option>
                      <option value="Interactive Media &amp; Services">
                        Interactive Media &amp; Services
                      </option>
                      <option value="Internet &amp; Direct Marketing Retail">
                        Internet &amp; Direct Marketing Retail
                      </option>
                      <option value="Leisure Products">Leisure Products</option>
                      <option value="Machinery">Machinery</option>
                      <option value="Media">Media</option>
                      <option value="Personal Products">
                        Personal Products
                      </option>
                      <option value="Pharmaceuticals">Pharmaceuticals</option>
                      <option value="Professional Services">
                        Professional Services
                      </option>
                      <option value="Software">Software</option>
                      <option value="Textiles, Apparel &amp; Luxury Goods">
                        Textiles, Apparel &amp; Luxury Goods
                      </option>
                      <option value="Trading Companies &amp; Distributors">
                        Trading Companies &amp; Distributors
                      </option>
                    </select>
                  </div>
                  <div className=" col-3" style={{ marginBottom: "10px" }}>
                    <select
                      id="filter-region"
                      className="filter-status form-control"
                      style={{
                        width: "150px",
                        float: "left",
                        marginRight: "10px",
                      }}
                      data-target-cols="3"
                    >
                      <option value="">Region</option>
                      <option value="MidWest US">MidWest US</option>
                      <option value="NorthEast US">NorthEast US</option>
                      <option value="NorthWest US">NorthWest US</option>
                      <option value="Puerto Rico">Puerto Rico</option>
                      <option value="SouthEast US">SouthEast US</option>
                      <option value="SouthWest US">SouthWest US</option>
                      <option value="Various">Various</option>
                      <option value="Western US">Western US</option>
                    </select>
                    <a
                      href="#clear"
                      className="clear-filter"
                      title="clear filter"
                      style={{ float: "left" }}
                      id="trackE42"
                    >
                      [Clear]
                    </a>
                  </div>
                  <div className="clearfix" style={{ height: "10px" }}></div>
                </div>
                <table
                  className="csv-table footable footable-1 breakpoint-lg footable-area"
                  data-sorting="true"
                >
                  <thead>
                    <tr className="footable-header">
                      <th
                        width="25%"
                        className="footable-sortable footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        Company<span className="fooicon fooicon-sort"></span>
                      </th>
                      <th
                        width="15%"
                        data-breakpoints="xs"
                        className="footable-sortable"
                        style={{ display: "table-cell" }}
                      >
                        Originations Strategy
                        <span className="fooicon fooicon-sort"></span>
                      </th>
                      <th
                        width="20%"
                        data-breakpoints="xs"
                        className="footable-sortable"
                        style={{ display: "table-cell" }}
                      >
                        Industry<span className="fooicon fooicon-sort"></span>
                      </th>
                      <th
                        width="10%"
                        data-breakpoints="xs"
                        className="footable-sortable"
                        style={{ display: "table-cell" }}
                      >
                        Region<span className="fooicon fooicon-sort"></span>
                      </th>
                      <th
                        width="10%"
                        data-breakpoints="xs sm"
                        className="footable-sortable"
                        style={{ display: "table-cell" }}
                      >
                        Secured Debt
                        <span className="fooicon fooicon-sort"></span>
                      </th>
                      <th
                        width="10%"
                        data-breakpoints="xs sm"
                        className="footable-sortable"
                        style={{ display: "table-cell" }}
                      >
                        Unsecured Debt
                        <span className="fooicon fooicon-sort"></span>
                      </th>
                      <th
                        width="10%"
                        data-breakpoints="xs sm"
                        className="footable-sortable footable-last-visible"
                        style={{ display: "table-cell" }}
                      >
                        Equity / Other
                        <span className="fooicon fooicon-sort"></span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        8th Avenue Food &amp; Provisions, Inc.
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>Food Products</td>
                      <td style={{ display: "table-cell" }}>MidWest US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      ></td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        Atlantis Health Care Group (Puerto Rico), Inc.
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Health Care Providers &amp; Services
                      </td>
                      <td style={{ display: "table-cell" }}>Puerto Rico</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      ></td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        Aventiv Technologies, LLC{" "}
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Communications Equipment
                      </td>
                      <td style={{ display: "table-cell" }}>SouthWest US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      ></td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        Barracuda Parent, LLC
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>IT Services</td>
                      <td style={{ display: "table-cell" }}>Western US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      ></td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        BCPE North Star US Holdco 2, Inc.
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>Food Products</td>
                      <td style={{ display: "table-cell" }}>MidWest US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      ></td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        BCPE Osprey Buyer, Inc.
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Health Care Technology
                      </td>
                      <td style={{ display: "table-cell" }}>MidWest US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      ></td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        Belnick, LLC (d/b/a The Ubique Group)
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Household Durables
                      </td>
                      <td style={{ display: "table-cell" }}>SouthEast US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      ></td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        Boostability Parent, Inc.
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>IT Services</td>
                      <td style={{ display: "table-cell" }}>SouthWest US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      ></td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        Broder Bros., Co.
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Textiles, Apparel &amp; Luxury Goods
                      </td>
                      <td style={{ display: "table-cell" }}>NorthEast US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      ></td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        Burgess Point Purchaser Corporation
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Automobile Components
                      </td>
                      <td style={{ display: "table-cell" }}>SouthEast US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      ></td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        Capstone Logistics Acquisition, Inc.
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Commercial Services &amp; Supplies
                      </td>
                      <td style={{ display: "table-cell" }}>SouthEast US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      ></td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        Collections Acquisition Company, Inc.
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Diversified Financial Services
                      </td>
                      <td style={{ display: "table-cell" }}>MidWest US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      ></td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        CP Energy Services Inc.
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending / Buyout
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Energy Equipment &amp; Services
                      </td>
                      <td style={{ display: "table-cell" }}>SouthWest US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      >
                        •
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        CP IRIS Holdco I, Inc.
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Building Products
                      </td>
                      <td style={{ display: "table-cell" }}>Western US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      ></td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        Credit Central Loan Company, LLC
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending / Buyout
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Consumer Finance
                      </td>
                      <td style={{ display: "table-cell" }}>SouthEast US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      >
                        •
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        Credit.com Holdings, LLC
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Diversified Consumer Services
                      </td>
                      <td style={{ display: "table-cell" }}>SouthWest US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      >
                        •
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        DRI Holding Inc.
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Commercial Services &amp; Supplies
                      </td>
                      <td style={{ display: "table-cell" }}>Western US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      ></td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        DTI Holdco, Inc.{" "}
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Professional Services
                      </td>
                      <td style={{ display: "table-cell" }}>NorthEast US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      ></td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        Dukes Root Control Inc.
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Commercial Services &amp; Supplies
                      </td>
                      <td style={{ display: "table-cell" }}>MidWest US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      ></td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        Easy Gardener Products, Inc.
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Household Durables
                      </td>
                      <td style={{ display: "table-cell" }}>SouthWest US</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      >
                        •
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        Echelon Transportation, LLC
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending / Buyout
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Aerospace &amp; Defense
                      </td>
                      <td style={{ display: "table-cell" }}>NorthEast US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      >
                        •
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        Engineered Machinery Holdings, Inc.
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>Machinery</td>
                      <td style={{ display: "table-cell" }}>MidWest US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      ></td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        Emerge Intermediate, Inc.
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Health Care Providers &amp; Services
                      </td>
                      <td style={{ display: "table-cell" }}>SouthWest US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      ></td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        Enseo Acquisition, Inc.
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>IT Services</td>
                      <td style={{ display: "table-cell" }}>SouthWest US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      ></td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        Eze Castle Integration, Inc.
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>IT Services</td>
                      <td style={{ display: "table-cell" }}>NorthEast US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      ></td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        Faraday Buyer, LLC
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Electrical Equipment
                      </td>
                      <td style={{ display: "table-cell" }}>SouthEast US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      ></td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        First Brands Group
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Automobile Components
                      </td>
                      <td style={{ display: "table-cell" }}>MidWest US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      ></td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        First Tower Finance Company LLC
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending / Buyout
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Consumer Finance
                      </td>
                      <td style={{ display: "table-cell" }}>SouthEast US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      >
                        •
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        Freedom Marine Solutions, LLC
                      </td>
                      <td style={{ display: "table-cell" }}>Other</td>
                      <td style={{ display: "table-cell" }}>
                        Energy Equipment &amp; Services
                      </td>
                      <td style={{ display: "table-cell" }}>SouthEast US</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      >
                        •
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        Help/Systems Holdings, Inc. (d/b/a Forta, LLC)
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>Software</td>
                      <td style={{ display: "table-cell" }}>MidWest US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      ></td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        The Hiller Companies, LLC
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Commercial Services &amp; Supplies
                      </td>
                      <td style={{ display: "table-cell" }}>SouthEast US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      ></td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        InterDent, Inc.
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Health Care Providers &amp; Services
                      </td>
                      <td style={{ display: "table-cell" }}>Western US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      >
                        •
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        Interventional Management Services, LLC
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Health Care Providers &amp; Services
                      </td>
                      <td style={{ display: "table-cell" }}>SouthEast US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      ></td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        Japs-Olson Company, LLC
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Commercial Services &amp; Supplies
                      </td>
                      <td style={{ display: "table-cell" }}>MidWest US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      ></td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        Julie Lindsey, Inc.
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Textiles, Apparel &amp; Luxury Goods
                      </td>
                      <td style={{ display: "table-cell" }}>NorthEast US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      ></td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        K&amp;N HoldCo, LLC
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Automobile Components
                      </td>
                      <td style={{ display: "table-cell" }}>Western US</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      >
                        •
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        Kickapoo Ranch Pet Resort
                      </td>
                      <td style={{ display: "table-cell" }}>Other</td>
                      <td style={{ display: "table-cell" }}>
                        Diversified Consumer Services
                      </td>
                      <td style={{ display: "table-cell" }}>SouthWest US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      >
                        •
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        KM2 Solutions LLC
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>IT Services</td>
                      <td style={{ display: "table-cell" }}>NorthEast US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      ></td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        LGC US FINCO, LLC
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>Machinery</td>
                      <td style={{ display: "table-cell" }}>SouthWest US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      ></td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        Lucky US BuyerCo LLC
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Professional Services
                      </td>
                      <td style={{ display: "table-cell" }}>SouthWest US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      ></td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        MAC Discount, LLC
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Household Durables
                      </td>
                      <td style={{ display: "table-cell" }}>NorthEast US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      >
                        •
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        Magnate Worldwide, LLC
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Air Freight &amp; Logistics
                      </td>
                      <td style={{ display: "table-cell" }}>MidWest US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      ></td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        Mamba Purchaser, Inc.
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Health Care Providers &amp; Services
                      </td>
                      <td style={{ display: "table-cell" }}>SouthEast US</td>
                      <td style={{ display: "table-cell" }}>*</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      ></td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        Medical Solutions Holdings, Inc.
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Health Care Providers &amp; Services
                      </td>
                      <td style={{ display: "table-cell" }}>MidWest US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      ></td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        MITY, Inc.
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending / Buyout
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Commercial Services &amp; Supplies
                      </td>
                      <td style={{ display: "table-cell" }}>SouthWest US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      >
                        •
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        National Property REIT Corp.
                      </td>
                      <td style={{ display: "table-cell" }}>Real Estate</td>
                      <td style={{ display: "table-cell" }}>
                        Equity Real Estate Investment Trusts (REITs)
                      </td>
                      <td style={{ display: "table-cell" }}>Various</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      >
                        •
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        Nationwide Loan Company LLC
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending / Buyout
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Consumer Finance
                      </td>
                      <td style={{ display: "table-cell" }}>MidWest US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      >
                        •
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        Nexus Buyer LLC
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>Capital Markets</td>
                      <td style={{ display: "table-cell" }}>NorthEast US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      ></td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        NH Kronos Buyer, Inc.
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>Pharmaceuticals</td>
                      <td style={{ display: "table-cell" }}>NorthEast US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      ></td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        Nixon, Inc.
                      </td>
                      <td style={{ display: "table-cell" }}>Other</td>
                      <td style={{ display: "table-cell" }}>
                        Textiles, Apparel &amp; Luxury Goods
                      </td>
                      <td style={{ display: "table-cell" }}>Western US</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      >
                        •
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        NMMB, Inc.
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending / Buyout
                      </td>
                      <td style={{ display: "table-cell" }}>Media</td>
                      <td style={{ display: "table-cell" }}>NorthEast US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      >
                        •
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        OneTouchPoint Corp
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Professional Services
                      </td>
                      <td style={{ display: "table-cell" }}>MidWest US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      ></td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        Pacific World Corporation
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Personal Products
                      </td>
                      <td style={{ display: "table-cell" }}>Western US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      >
                        •
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        PeopleConnect Holdings, LLC
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Interactive Media &amp; Services
                      </td>
                      <td style={{ display: "table-cell" }}>NorthWest US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      ></td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        PlayPower, Inc.
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Leisure Products
                      </td>
                      <td style={{ display: "table-cell" }}>SouthEast US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      ></td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        Precisely Software Incorporated
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>IT Services</td>
                      <td style={{ display: "table-cell" }}>NorthEast US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      ></td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        Preventics, Inc. (d/b/a Legere Pharmaceuticals)
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Health Care Providers &amp; Services
                      </td>
                      <td style={{ display: "table-cell" }}>SouthWest US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      >
                        •
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        Raisin Acquisition Co, Inc.
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>Pharmaceuticals</td>
                      <td style={{ display: "table-cell" }}>NorthEast US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      ></td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        Reception Purchaser, LLC
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Air Freight &amp; Logistics
                      </td>
                      <td style={{ display: "table-cell" }}>MidWest US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      ></td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        Redstone Holdco 2 LP
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>IT Services</td>
                      <td style={{ display: "table-cell" }}>NorthEast US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      ></td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        Research Now Group, LLC and Dynata, LLC
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Professional Services
                      </td>
                      <td style={{ display: "table-cell" }}>NorthEast US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      ></td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        RGIS Services, LLC
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Commercial Services &amp; Supplies
                      </td>
                      <td style={{ display: "table-cell" }}>MidWest US</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      >
                        •
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        Rising Tide Holdings, Inc.
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Diversified Consumer Services
                      </td>
                      <td style={{ display: "table-cell" }}>SouthEast US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      >
                        •
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        RME Group Holding Company
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>Media</td>
                      <td style={{ display: "table-cell" }}>SouthEast US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      ></td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        Rosa Mexicano
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Hotels, Restaurants &amp; Leisure
                      </td>
                      <td style={{ display: "table-cell" }}>NorthEast US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      ></td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        R-V Industries, Inc.
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending / Buyout
                      </td>
                      <td style={{ display: "table-cell" }}>Machinery</td>
                      <td style={{ display: "table-cell" }}>NorthEast US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      >
                        •
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        ShiftKey, LLC
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Health Care Technology
                      </td>
                      <td style={{ display: "table-cell" }}>SouthWest US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      ></td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        Shutterfly Finance, LLC
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Internet &amp; Direct Marketing Retail
                      </td>
                      <td style={{ display: "table-cell" }}>Western US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      ></td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        Sorenson Communications, LLC
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Diversified Telecommunication Services
                      </td>
                      <td style={{ display: "table-cell" }}>SouthWest US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      ></td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        Spectrum Vision Holdings, LLC
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Health Care Providers &amp; Services
                      </td>
                      <td style={{ display: "table-cell" }}>NorthEast US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      ></td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        Staples, Inc.
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>Distributors</td>
                      <td style={{ display: "table-cell" }}>NorthEast US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      ></td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        Strategic Materials Holding Corp.
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Household Durables
                      </td>
                      <td style={{ display: "table-cell" }}>SouthWest US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      ></td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        Stryker Energy, LLC
                      </td>
                      <td style={{ display: "table-cell" }}>Other</td>
                      <td style={{ display: "table-cell" }}>
                        Energy Equipment &amp; Services
                      </td>
                      <td style={{ display: "table-cell" }}>MidWest US</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      >
                        •
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        The RK Logistics Group, Inc.
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Commercial Services &amp; Supplies
                      </td>
                      <td style={{ display: "table-cell" }}>Western US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      >
                        •
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        Town &amp; Country Holdings, Inc.
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>Distributors</td>
                      <td style={{ display: "table-cell" }}>NorthEast US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      >
                        •
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        TPS, LLC
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>Machinery</td>
                      <td style={{ display: "table-cell" }}>MidWest US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      ></td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        United Sporting Companies, Inc.
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>Distributors</td>
                      <td style={{ display: "table-cell" }}>SouthEast US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      ></td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        Universal Turbine Parts, LLC
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Trading Companies &amp; Distributors
                      </td>
                      <td style={{ display: "table-cell" }}>SouthEast US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      >
                        •
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        Upstream Newco, Inc.
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Health Care Providers &amp; Services
                      </td>
                      <td style={{ display: "table-cell" }}>SouthEast US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      ></td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        USES Corp.
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Commercial Services &amp; Supplies
                      </td>
                      <td style={{ display: "table-cell" }}>SouthWest US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      >
                        •
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        USG Intermediate, LLC
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Leisure Products
                      </td>
                      <td style={{ display: "table-cell" }}>SouthWest US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      >
                        •
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        Valley Electric Company, Inc.
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending / Buyout
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Construction &amp; Engineering
                      </td>
                      <td style={{ display: "table-cell" }}>NorthWest US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      >
                        •
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        VC GB Holdings I Corp
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Household Durables
                      </td>
                      <td style={{ display: "table-cell" }}>MidWest US</td>
                      <td style={{ display: "table-cell" }}>*</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      ></td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        ViaPath Technologies.
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Diversified Telecommunication Services
                      </td>
                      <td style={{ display: "table-cell" }}>NorthEast US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      ></td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        Victor Technology, LLC
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Commercial Services &amp; Supplies
                      </td>
                      <td style={{ display: "table-cell" }}>MidWest US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      ></td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        WatchGuard Technologies, Inc.
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>IT Services</td>
                      <td style={{ display: "table-cell" }}>NorthWest US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      ></td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        Wellful Inc.
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Food &amp; Staples Retailing
                      </td>
                      <td style={{ display: "table-cell" }}>NorthEast US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      ></td>
                    </tr>
                    <tr>
                      <td
                        className="footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        Wellpath Holdings, Inc.
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Middle-Market Lending
                      </td>
                      <td style={{ display: "table-cell" }}>
                        Health Care Providers &amp; Services
                      </td>
                      <td style={{ display: "table-cell" }}>SouthEast US</td>
                      <td style={{ display: "table-cell" }}>•</td>
                      <td style={{ display: "table-cell" }}></td>
                      <td
                        className="footable-last-visible"
                        style={{ display: "table-cell" }}
                      ></td>
                    </tr>
                  </tbody>
                  <div></div>
                </table>

                {/* Mobile  */}

                <div className="faq-area">
                  <h3 className="faq-title">Company</h3>

                  <div className="faqs">
                    {faqs.map((faq, index) => (
                      <FAQ
                        faq={faq}
                        index={index}
                        key={index}
                        toggleFAQ={toggleFAQ}
                      />
                    ))}
                  </div>
                </div>
                {/* tablet  */}

                <section className="tablet-footable-area">

                <table
                  className="csv-table footable footable-1 breakpoint-lg "
                  data-sorting="true"
                >
                  <thead>
                    <tr className="footable-header">
                      <th
                        width="25%"
                        className="footable-sortable footable-first-visible"
                        style={{ display: "table-cell" }}
                      >
                        Company<span className="fooicon fooicon-sort"></span>
                      </th>
                      <th
                        width="15%"
                        data-breakpoints="xs"
                        className="footable-sortable"
                        style={{ display: "table-cell" }}
                      >
                        Originations Strategy
                        <span className="fooicon fooicon-sort"></span>
                      </th>
                      <th
                        width="20%"
                        data-breakpoints="xs"
                        className="footable-sortable"
                        style={{ display: "table-cell" }}
                      >
                        Industry<span className="fooicon fooicon-sort"></span>
                      </th>
                      <th
                        width="10%"
                        data-breakpoints="xs"
                        className="footable-sortable"
                        style={{ display: "table-cell" }}
                      >
                        Region<span className="fooicon fooicon-sort"></span>
                      </th>
                    </tr>
                  </thead>
                  <tbody></tbody>
                </table>

                <div className="faqs">
                  {faqs.map((faq, index) => (
                    <FAQ
                      faq={faq}
                      index={index}
                      key={index}
                      toggleFAQ={toggleFAQ}
                    />
                  ))}
                </div>
                </section>
              </div>
            </span>
          </span>
        </div>

        <div className="portfolio-more-btn py-4 text-center">
          <a
            href="https://companiesmarketcap.com/prospect-capital/marketcap/"
             target="_blank"
                      rel="noopener noreferrer"
            className="btn btn-primary py-2 px-4"
          >
           Market Capital Report
          </a>
        </div>
      </section>
    </>
  );
};

export default Portfolio;
