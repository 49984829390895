import React from 'react';

const QUOTE = () => {
    return (
        <>
             <div className="py-80 et_pb_section et_pb_section_3 testimonial-section et_pb_with_background et_section_regular py-50">
          <div className="et_pb_row et_pb_row_6 quote-area">
            <div
              data-aos="fade-right"
              data-aos-duration="1000"
              className="et_pb_column et_pb_column_4_4 et_pb_column_14 et_pb_css_mix_blend_mode_passthrough et-last-child aos-init aos-animate"
            >
              <div className="et_pb_module et_pb_image et_pb_image_6 curl-quote left-quote et-animated et_had_animation">
                {" "}
                <span className="et_pb_image_wrap ">
                  <img
                    src="//s23.q4cdn.com/831501628/files/images/irw/Open-Quote.png"
                    sizes="(max-width: 131px) 100vw, 131px"
                    alt=""
                  />
                </span>
              </div>
              <div className="et_pb_module et_pb_text et_pb_text_17 testimonial-quote et_pb_bg_layout_light  et_pb_text_align_left">
                <div className="et_pb_text_inner">
                  <p>
                    {" "}
                    We are proud of our long history of providing important
                    capital to U.S. middle market businesses while delivering to
                    our shareholders consistent returns. We have locations
                    across the U.S. and manage a large and diversified
                    investment portfolio. We are among the leading providers of
                    capital to the middle market.
                  </p>
                </div>
              </div>
              <div
                data-aos="fade-left"
                data-aos-duration="1000"
                className="et_pb_module et_pb_image et_pb_image_7 curl-quote right-quote et-animated et_had_animation aos-init aos-animate"
              >
                {" "}
                <span className="et_pb_image_wrap ">
                  <img
                    src="//s23.q4cdn.com/831501628/files/images/irw/Close-Quote.png"
                    sizes="(max-width: 131px) 100vw, 131px"
                    alt=""
                  />
                </span>
              </div>
              <div className="et_pb_module et_pb_text et_pb_text_18 testimonial-sig et_pb_bg_layout_light  et_pb_text_align_left">
                <div className="et_pb_text_inner">
                  <p>
                    JOHN BARRY
                    <br />
                    Chairman and CEO
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        </>
    );
};

export default QUOTE;