import React, { useContext, useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { Autoplay } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import axios from "axios";
import NoticeModal from "./NoticeModal.js";

const MobileNotices = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`https://api2.prospectstreetx.com/api/frontend/home/notices/view`)
      .then((res) => {
        setData(res?.data?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log("API call error:", err?.message);
      });
  }, []);
  const progressCircle = useRef(null);
  const progressContent = useRef(null);
  const onAutoplayTimeLeft = (s, time, progress) => {
    progressCircle.current.style.setProperty("--progress", 1 - progress);
    progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
  };

  const [show, setShow] = useState(false);
  const [singleData, setSingleData] = useState([]);
  
  const NoticeModalShow = (id) => {
    if (id) {
      fetch(
        `https://api2.prospectstreetx.com/api/admin/home/notice/edit/${id}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setSingleData(data?.data[0]);
          setShow(true);
        });
    }
  };


  
  if (loading) {
    return (
      <>
        <div className="container-custom">
          <Skeleton height={10} count={1} />
        </div>
      </>
    );
  } else {
    return (
      <>
        <section className="notices-section py-xl-4 d-flex bd-highlight mobile-version mobile-notice-area">
          <div className="notice-icon bd-highlight mobile-version">
            <i className="fa-solid fa-volume-high"></i>
          </div>
          <div className="notice-silder mobile-version mobile-notice-slider flex-grow-1 bd-highlight ">
            <LazyLoadComponent effect="blur">
              {data?.length !== 0 ? (
                <Swiper
                  direction={"vertical"}
                  slidesPerView={1}
                  centeredSlides={true}
                  autoplay={{
                    delay: 2500,
                    pauseOnMouseEnter: true,
                  }}
                  onAutoplayTimeLeft={onAutoplayTimeLeft}
                  modules={[Autoplay]}
                  loop={true}
                >
                  {data.map((data, index) => (
                    <SwiperSlide key={data?._id}>
                      <button onClick={() => NoticeModalShow(data?._id)}>
                        {(data?.name).slice(0, 28)}{" "}
                        {data?.name?.length > 28 ? "..." : ""}
                      </button>
                    </SwiperSlide>
                  ))}
                  <div
                    className="autoplay-progress d-none"
                    slot="container-end"
                  >
                    <svg viewBox="0 0 48 48" ref={progressCircle}>
                      <circle cx="24" cy="24" r="20"></circle>
                    </svg>
                    <span ref={progressContent}></span>
                  </div>
                </Swiper>
              ) : (
                <></>
              )}
            </LazyLoadComponent>
          </div>
          <div className=" mobile-notice-btn mobile-version">
            <Link>
              <i className="fa-solid fa-list-ul"></i>
            </Link>
          </div>
        </section>

        <section className="pt-3 pb-2 mobile-home-sft-area text-center mobile-version">
          <div className="container-custom">
            <div className="mobile-home-sft ">
              <div className="d-flex align-items-center justify-content-between">
                <Link to="/user/assets/deposit">
                  <i
                    className="fa-solid fa-money-bill-1-wave"
                    style={{ color: "#536DFE", borderRadius: "30px" }}
                  ></i>
                  <i className=""></i>
                  {/* <i className="fa-solid fa-sack-dollar " style={{color:"#536DFE",  borderRadius: "30px"}}></i> */}
                  <p>Deposit</p>
                </Link>
                <Link to="/user/bitcoin/mining">
                  <i
                    className="fa-brands fa-bitcoin"
                    style={{ color: "orange", borderRadius: "30px" }}
                  ></i>
                  {/* <i style={{color:"orange", borderRadius: "30px"}} className="fas bg-white section_div_i  fa-industry"></i> */}
                  {/* <i className="fa-solid fa-chart-line"></i> */}
                  <p>B. Mining</p>
                </Link>
                <Link to="/user/gold/mining">
                  <i
                    className="fa-solid fa-coins"
                    style={{ color: "green", borderRadius: "30px" }}
                  ></i>
                  {/* <i className="fa-solid fa-arrow-up-right-dots"></i> */}
                  {/* <i style={{color:"green", borderRadius: "30px"}} className="fas bg-white section_div_i  fa-location-arrow"></i> */}
                  <p>G. Mining</p>
                </Link>
                <Link to="/user/forex">
                  <i
                    className="fa-solid fa-sack-dollar"
                    style={{ color: "red", borderRadius: "30px" }}
                  ></i>
                  {/* <i style={{color:"red", borderRadius: "30px"}}  className="fas bg-white section_div_i  fa-percent"></i> */}
                  {/* <i className="fa-solid fa-f"></i> */}
                  <p>Forex</p>
                </Link>
              </div>
            </div>
            <div className="mobile-home-sft ">
              <div className="d-flex align-items-center justify-content-between">
                <Link to="/user/stocks">
                  <i
                    className="fa-brands fa-meta"
                    style={{ color: "blue", borderRadius: "30px" }}
                  ></i>
                  {/* <i className="fa-solid fa-chart-line" style={{color:"yello", borderRadius: "30px"}}></i> */}
                  <p>Stocks</p>
                </Link>
                <Link to="/user/invest/plan">
                  <i
                    className="fa-solid fa-circle-dollar-to-slot"
                    style={{ color: "#ff00f2", borderRadius: "30px" }}
                  ></i>
                  <p>Invest Plan</p>
                </Link>

                <Link to="#">
                  <i
                    className="fa-solid fa-gifts"
                    style={{ color: "red", borderRadius: "30px" }}
                  ></i>
                  <p>Gift</p>
                </Link>
                <Link to="/user/contact">
                  <i
                    className="fa-solid fa-headset"
                    style={{ color: "green", borderRadius: "30px" }}
                  ></i>
                  {/* <i className="fa-brands fa-hire-a-helper" style={{color:"green", borderRadius: "30px"}}></i> */}
                  <p>Support</p>
                </Link>
              </div>
            </div>
          </div>
        </section>

        {(() => {
          if (show) {
            return (
              <NoticeModal
                singleData={singleData}
                setShow={setShow}
                show={show}
              ></NoticeModal>
            );
          }
        })()}
      </>
    );
  }
};

export default MobileNotices;
