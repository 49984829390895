import React, { useState } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import Modal from "react-bootstrap/Modal";
import ComplateTrade from "./ComplateTrade";

const TradeCountdown = (props) => {
  const { data, marketData, setcountdownmodalshow } = props;

  const [complateTradeModalShow, setComplateTradeModalShow] = useState(false);

  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      setcountdownmodalshow(false);
      setComplateTradeModalShow(true);
    }

    return (
      <div className="timer">
        <div className="value">{remainingTime}</div>
      </div>
    );
  };

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        className="trade-results-modal-area"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
          {marketData?.Name ?  marketData?.Name :  marketData?.name } 
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="trade-timer">
            <CountdownCircleTimer
              isPlaying
              duration={data?.Time}
              colors={[
                "#004777",
                "#F7B801",
                "#A30000",
                "#A30000",
                "#004777",
                "#F7B801",
                "#A30000",
                "#A30000",
                "#004777",
                "#F7B801",
                "#A30000",
                "#A30000",
              ]}
              colorsTime={[300, 250, 200, 150, 110, 80, 50, 40, 30, 20, 10, 0]}
              size={100}
              strokeWidth={5}
              trailColor={[["#0075BB"]]}
            >
              {renderTime}
            </CountdownCircleTimer>
          </div>

          <div class="result-details mt-4">
            <div className="trade-results">
              <p>Current price:</p>
              <p>{data?.Crypto_price}</p>
            </div>
            <div className="trade-results">
              <p>Direction:</p>
              <p>{data?.HighLow}</p>
            </div>
            <div className="trade-results">
              <p>Code:</p>
              <p>{data?.Symbol}</p>
            </div>
            <div className="trade-results">
              <p>Amount:</p>
              <p>{data?.Amount}</p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      
      <ComplateTrade
       className="posce-trade-modal"
        data={data}
        complateTradeModalShow={complateTradeModalShow}
        show={complateTradeModalShow}
        onHide={() => setComplateTradeModalShow(false)}
      ></ComplateTrade>
    </>
  );
};

export default TradeCountdown;
