import React, { useEffect, useState } from "react";
import { Circles } from "react-loader-spinner";
import { Link, useParams } from "react-router-dom";
import HTMLReactParser from "html-react-parser";
import dateFormat from "dateformat";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Loader from "../../../Loader/Loader";

const NewsDetails = () => {
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch(`https://api2.prospectstreetx.com/api/frontend/home/news/view/${id}`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data?.data);
        setLoading(false);
      });
  }, [id]);


  if(loading){
    return (
      <>
        <Loader></Loader>
 
    </>
    );
  }else{
    return (
      <>
        <div className="mobile-news-details-section news-details-area">
          <div className="news-details">
            <div className="news-details-title">
              <h4>{data?.category}</h4>
              <Link to="/news">View all news</Link>
            </div>
  
            <main>
              <div className="news-details-main">
              <LazyLoadImage
                                              alt='img'
                                              effect="blur"
                                              width="32"
                                               height="32"
                                               className=""
                                              src={`https://api2.prospectstreetx.com/${data?.image}`} />
                <div className="news-title">
                  <h5>
                    {data?.title}
                  </h5>
                </div>
                <div className="news-details-time">
                  <span>{dateFormat(data.createdAt, "d/m/yyyy")}</span>
                </div>
                <div className="news-detail-all">
                  <p className="mb-2">{data?.dis}</p>
                {data?.news ?
                                      HTMLReactParser(data?.news)
                                      :
                                      ''}
                </div>
              </div>
            </main>
  
            <div className="">
              <div className="news-detials-footer-item">
                <div>
                  <a href="">
                  <LazyLoadImage
                                              alt='img'
                                              effect="blur"
                                              width="32"
                                               height="32"
                                               className=""
                                              src='https://ml.globenewswire.com/media/740475b3-12dd-45b6-a716-678bc54c70a0/small/prospect-capital-logo-final-rgb-0-117-188-01-jpg.jpg' />
                   
                   
                  </a>
                </div>
                <div>
                  <p>Source: Prospect Capital Corporation</p>
                  <Link to="/user/news">View all news</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

 

 

};

export default NewsDetails;
