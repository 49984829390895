import React from 'react';
import "./PrivacyPolicy.css"
const PrivacyPolicy = () => {
    return (
        <>
        <section className="privacy-policy-section">
        <div className="privacy-policy-title">
            <h5>Privacy Policy</h5>
        </div>
        <div className="container">
            <div className="privacy-policy">
                <p>Privacy is of utmost importance at PCTC. We recognize the significance of protecting information that
                    is stored on our servers or network or is intended to be stored on our servers or network and which
                    relates to an individual. The data we protect are the “Personal Data” which is any information that
                    can be used to identify you directly or indirectly.
                </p>
                <p>This Privacy Policy is designed to address the regulatory requirements of the jurisdictions in which
                    Bitcoin.com offers its Services, including the General Data Protection Regulation (“GDPR”), as
                    enacted by the European Commission and the California Consumer Privacy Act (“CCPA”). In this Privacy
                    Policy, the terms “Service” and “Services” have the same meaning as described in the User Agreement.
                </p>
                <p>
                    This Privacy Notice applies to all processing of Personal Data on the website of the PCTC and all
                    other PCTC sites on which it appears (the “Sites”). If you do not agree with this Privacy Policy, in
                    general, or any part of it, you should not use the Sites or Services. This Privacy Policy is
                    periodically reviewed to ensure that any new obligations and changes to our business model are taken
                    into consideration. We may amend this Privacy Policy at any time by posting an amended version on
                    our website.
                </p>
                <p>In this privacy policy, we will tell you: Who we are? What Personal Data do we collect from you and
                    how do we collect it; On what legal basis do we collect your Personal Data; For what purpose do we
                    collect your Personal Data; How long we store it for; Who do we share your Personal Data with;
                    Whether we intend to transfer it to another country; What are your rights concerning your Personal
                    Data; and Children’s Privacy; and Changes to this Privacy Policy.

                </p>

            </div>

            <div className="disclaimer-lists">
                <ol>
                    <li>
                        Who are we and how can you contact us if you have any concerns?
                        <p>The organization that is responsible for ensuring that your Personal Data is processed in
                            compliance with applicable regulations is Saint Bitts LLC, Nelson Springs Commercial
                            Complex, Calhoun Estate, Nevis, company number L17015 (the “Data Controller”).
                            <br />
                            <br />
                            If you have any concerns or questions or if you would like to exercise any of your rights
                            with respect to your Personal Data, you may contact our Data Protection Officer (“DPO”) at
                            PCTC.


                        </p>
                    </li>
                    <li>
                        What data do we collect about you and how do we collect it?

                        <p>
                            We collect your Personal Data through a variety of methods: either you provide it to us
                            directly, we collect it from external sources, or we collect it by using automated means.
                            Information you may provide us: You may give us information about you by filling in forms on
                            our Sites or through our app or by corresponding with us by phone, email, or otherwise. This
                            includes but is not limited to the following information:
                        </p>

                        <br />
                        <p>
                            Account Administration. We may collect personal information from you in connection with the
                            administration of your account. This personal information may include but is not limited to,
                            your name, date of birth, age, nationality, country of residence, gender, occupation,
                            signature, utility bills, professional details, photographs, video footage, phone number,
                            home address, email address, government-issued identification such as passport, driver’s
                            license, national identification card with photograph, tax identification number, national
                            insurance number, social security number, visa information and/or any other information
                            deemed necessary to comply with our legal obligations.


                        </p>

                        <br />
                        <p>
                            Financial and Transactional Information. We may collect financial information such as your
                            wallet address, bank account numbers, bank statements, transaction history, trading data,
                            and credit/debit card numbers. We may collect personal information and details associated
                            with your transactions including, but not limited to, information about the transactions you
                            make using our services, the purpose of the transactions, information on sources of your
                            funds, sender and receiver of funds, and ultimate beneficiary information.


                        </p>

                        <br />
                        <p>
                            Your Communications with Us. We may collect personal information, such as email address,
                            phone number, or mailing address when you request information about our Services, register
                            for our newsletter, request customer or technical support, or otherwise communicate with us.


                        </p>

                        <br />
                        <p>

                            Surveys. We may contact you to participate in surveys. If you decide to participate, we may
                            collect personal information from you in connection with the survey.

                        </p>

                        <br />
                        <p>

                            Interactive Features. We and others who use our services may collect personal information
                            that you submit or make available through our interactive features (e.g., messaging and chat
                            features, commenting functionalities, forums, blogs, and social media pages). Any
                            information you provide using the public sharing features of the Services will be considered
                            “public,” unless otherwise required by applicable law, and is not subject to the privacy
                            protections referenced herein.

                        </p>

                        <br />
                        <p>
                            Sweepstakes or Contests. We may collect personal information you provide for any sweepstakes
                            or contests that we offer. In some jurisdictions, we are required to publicly share
                            information about sweepstakes and contest winners.


                        </p>

                        <br />
                        <p>
                            Conferences, Trade Shows, and Other Events. We may collect personal information from you
                            when we attend or host conferences, trade shows, and other events.


                        </p>

                        <br />
                        <p>
                            Business Development and Strategic Partnerships. We may collect personal information from
                            you and third parties to assess and pursue potential business opportunities.


                        </p>

                        <br />
                        <p>
                            Job Applications. We may post job openings and opportunities on our Services. If you respond
                            to one of these postings, we may collect your personal information, such as your
                            application, CV, cover letter, and/or any other information you provide to us.



                        </p>

                        <br />
                        <p>
                            Information Collected Automatically: About each of your visits to our Sites or our app we
                            automatically collect the following information:


                        </p>

                        <br />
                        <p>
                            Automatic Collection of Personal Information. We may collect certain information
                            automatically when you use our Services, such as your Internet protocol (IP) address, user
                            settings, MAC address, cookie identifiers, mobile carrier, mobile advertising, and other
                            unique identifiers, browser or device information, location information (including precise
                            location information and/or approximate location derived from IP address), and Internet
                            service provider. We may also automatically collect information regarding your use of our
                            Services, such as pages that you visit before, during, and after using our Services,
                            information about the links you click, the types of content you interact with, the frequency
                            and duration of your activities, and other Information about how you use our Services.


                        </p>

                        <br />
                        <p>
                            Device Information: We collect information about the device you use to access your account,
                            including the hardware model, operating system version, and unique device identifiers, but
                            this information is anonymized and not tied to any particular person.
                            <br />
                            <br />
                            Transaction Information: In connection with our Services (as such term is defined in our
                            User Agreement), we may collect and maintain information relating to transactions you effect
                            using the PCTC Wallet (e.g. converting one virtual currency to another or purchasing virtual
                            currencies).
                            <br />
                            <br />
                            Information We Collect Required By Law, Rule, or Regulation: Depending on the Service, we
                            may collect information from you in order to meet regulatory obligations around
                            know-your-customer (“KYC”) and anti-money laundering (“AML”) requirements. Information that
                            we collect from you includes the following:
                            <br />
                            <br />
                            Full name Residential address Contact details (telephone number, email address) Date and
                            place of birth, gender, place of citizenship Bank account information and/or credit card
                            details Your status as a politically exposed person Source of funds & proof of address
                            Passport and/or national driver’s license or government-issued identification card to verify
                            your identity.
                            <br />
                            <br />
                            Transaction history and account balances in connection with your use of certain Services.
                            Information We Collect from External Sources: We also receive information from other sources
                            and combine that with the information we collect through our Services. For instance:
                            <br />
                            <br />
                            We use "cookies" from time to time to help personalize your online experience with us. A
                            cookie is a small text file that is stored on your computer to help us make your visit to
                            our Sites more “user-friendly.” Please see our Cookie Policy for more details about the
                            cookies we use. Cookies provide us with information about your use of the Sites that can
                            help us improve the Sites and your experience with them. We will process Personal Data
                            collected through cookies under this Privacy Policy. If you have set your browser to alert
                            you before accepting cookies, you should receive an alert message with each cookie. You may
                            refuse cookies by turning them off in your browser, however, you should be aware that our
                            Sites, like most other popular sites, may not work well with cookies disabled.
                            <br />
                            <br />
                            We may analyze public blockchain data to ensure parties utilizing our Services are not
                            engaged in illegal or prohibited activity under our User Agreement and to analyze
                            transaction trends for research and development purposes.
                            <br />
                            <br />
                            We use third-party services that may be co-branded as PCTC but will do so with clear notice.
                            Any third-party services may collect information as determined by their own privacy
                            policies.
                            <br />
                            <br />
                            Banks or payment processors that you use to transfer fiat currency may provide us with basic
                            Personal Data, such as your name and address, as well as, your bank account information.
                            <br />
                            <br />
                            Advertising or analytics providers may provide us with anonymized information about you,
                            including but not limited to, how you found our website.

                        </p>
                    </li>
                    <li>
                        On what basis do we collect your data?
                        <p>
                            We only use your Personal Data where we have a legal basis to do so: Consent. For some
                            processing activities, we require your prior consent. This applies for example to some of
                            our direct marketing activities which fall under the scope of the GDPR. You will always be
                            prompted to take clear, affirmative action so that we can ensure that you agree with the
                            processing of your Personal Data. This action may, for example, take the form of a checkbox.
                            If you have given us your consent for processing operations, you may always change your
                            mind, and withdraw your consent at any time and easily; all you need to do is send us an
                            email at the following address PCTC
                            <br />
                            <br />
                            Performance of a contract. Some Personal Data we process about you is for the performance of
                            a contract to which you are a party or in order to take steps at your request prior to
                            entering into a contract with us.
                            <br />
                            <br />
                            Legal obligation. In some cases, we have to process your Personal Data to comply with legal
                            obligations, including those applicable to financial services institutions, such as under
                            the Bank Secrecy Act and other anti-money laundering laws. You may not be eligible for
                            certain Services if we cannot collect the Personal Data necessary to meet our legal
                            obligations.
                            <br />
                            <br />
                            Legitimate interest. In most cases where we process Personal Data in the context of our
                            Services we rely on our legitimate interests in conducting our normal business as a legal
                            basis for such processing. Our legitimate interests are to identify or prevent fraud, to
                            enhance the security of our network and information systems, or to carry out processing
                            operations for statistical purposes. We will use legitimate interest only when we have
                            carried out an assessment on the impact that this processing may have on you, and concluded
                            that the processing does not unduly infringe your rights and freedoms. For example, we do
                            not use this justification if we process sensitive data, or when the processing would be
                            unexpected for you, or if we consider it to be too intrusive.
                            <br />
                            <br />
                            Legitimate interest. In most cases where we process Personal Data in the context of our
                            Services we rely on our legitimate interests in conducting our normal business as a legal
                            basis for such processing. Our legitimate interests are to identify or prevent fraud, to
                            enhance the security of our network and information systems, or to carry out processing
                            operations for statistical purposes. We will use legitimate interest only when we have
                            carried out an assessment on the impact that this processing may have on you, and concluded
                            that the processing does not unduly infringe your rights and freedoms. For example, we do
                            not use this justification if we process sensitive data, or when the processing would be
                            unexpected for you, or if we consider it to be too intrusive.

                        </p>
                    </li>

                    <li>
                        For what purposes do we collect your data?
                        <p>
                            We collect your Personal Data to: Provide our Services (including customer support); Process
                            transactions and send notices about your transactions; Resolve disputes, collect fees, and
                            troubleshoot problems; Communicate with you about our Services and business and inform you
                            of matters that are important for your account and/or use of the Sites. We also use your
                            Personal Data to respond to any questions, comments, or requests you filed with us and the
                            handling of any complaints; Comply with applicable laws and regulations; Establish,
                            exercise, and defend legal claims; Monitor and report compliance issues;
                            <br />
                            <br />
                            Customize, measure, and improve our business, the Services, and the content and layout of
                            our Sites and apps (including developing new products and services; managing our
                            communications; determining the effectiveness of our sales, marketing, and advertising;
                            analyzing and enhancing our products, services, websites, and apps; ensuring the security of
                            our networks and information systems; performing accounting, auditing, invoicing,
                            reconciliation, and collection activities; and improving and maintaining the quality of our
                            customer services);
                            <br />
                            <br />
                            Deliver targeted marketing, service update notices, and promotional offers based on your
                            communication preferences, and measure the effectiveness of it. To approach you via email
                            for marketing purposes, we request your consent, unless it is not required by law. You
                            always have the option to unsubscribe from our mailings, e.g., via the unsubscribe link in
                            our newsletter;
                            <br />
                            <br />
                            Perform risk management, including comparing information for accuracy and verifying it with
                            third parties and protecting against, identifying, and preventing fraud and other prohibited
                            or illegal activity, claims, and other liabilities; and Enforce our contractual terms.

                        </p>
                    </li>
                    <li>
                        How long do we keep your data?
                        <p>
                            We will only retain your personal information for as long as necessary to fulfill the
                            purposes for which we collected it, including to satisfy any legal, accounting, or reporting
                            obligations or resolve disputes. While retention requirements vary by jurisdiction,
                            information about our typical retention periods for different aspects of your personal
                            information is described below.
                            <br />
                            <br />
                            Contact Information such as your name, email address, and telephone number for marketing
                            purposes is retained on an ongoing basis until you unsubscribe. Thereafter we will add your
                            details to our suppression list to ensure we do not inadvertently market to you.
                            <br />
                            <br />
                            Personal information collected to comply with our legal obligations under financial or
                            anti-money laundering laws may be retained after account closure for as long as required
                            under such laws.
                            <br />
                            <br />
                            Content that you post on our Sites such as support desk comments, photographs, videos, blog
                            posts, and other content may be kept after you close your account for audit and crime
                            prevention purposes (e.g. to prevent a known fraudulent actor from opening a new account).
                            <br />
                            <br />
                            Information collected via technical means such as cookies, web page counters, and other
                            analytics tools is kept for a period of up to one year from the expiry of the cookie.
                            <br />
                            <br />
                            When Personal Data is no longer necessary for the purpose for which it was collected, we
                            will remove any details that identify you or we will securely destroy the records, where
                            permissible. However, we may need to maintain records for a significant period of time
                            (after you cease using a particular Service) as mandated by regulation. For example, we are
                            subject to certain anti-money laundering laws that require us to retain the following, for a
                            period of five (5) years after our business relationship with you has ended.


                        </p>
                    </li>
                    <li>
                        Who do we share your Personal Data with?

                        <p>
                            We may share your information with selected recipients to perform functions required to
                            provide certain Services to you and/or in connection with our efforts to prevent and
                            investigate fraudulent or other criminal activity. All such third parties will be
                            contractually bound to protect data in compliance with our Privacy Policy. The categories of
                            recipients include:
                            <br />
                            <br />
                            Other PCTC entities, including to help detect and prevent potentially illegal acts and
                            violations of our policies, and to guide decisions about our products, services, and
                            communications;
                            <br />
                            <br />
                            Service providers who help with our business operations and deliver our Services, such as
                            Cloud service providers providing cloud infrastructure; Providers of ID verification
                            solutions and other due diligence solutions (such as KYC and anti-money laundering);
                            Providers of website analytics; Providers of customer service solutions; and Providers of
                            marketing automation platforms.
                            <br />
                            <br />
                            Law enforcement, government officials, regulatory agencies, our banks, and other third
                            parties under a subpoena, court order, or other legal process or requirement if applicable
                            to Bitcoin.com; or when we believe, in our sole discretion, that the disclosure of data is
                            necessary to report suspected illegal or fraudulent activity or to investigate violations of
                            our User Agreement.
                            <br />
                            <br />
                            We also may share Personal Data with a buyer or other successor in the event of a merger,
                            divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some
                            or all of PCTC assets, whether as a going concern or as part of bankruptcy, liquidation or
                            similar proceeding, in which Personal Data held by Bitcoin.com is among the assets
                            transferred.
                            <br />
                            <br />
                            Funding and transaction information related to your use of certain Services may be recorded
                            on a public blockchain. Public blockchains are distributed ledgers, intended to immutably
                            record transactions across wide networks of computer systems. Many blockchains are open to
                            forensic analysis which can lead to deanonymization and the unintentional revelation of
                            private financial information, especially when blockchain data is combined with other data.
                            <br />
                            <br />
                            Because blockchains are decentralized or third-party networks that are not controlled or
                            operated by PCTC or its affiliates, we are not able to erase, modify, or alter Personal Data
                            from such networks.

                        </p>
                    </li>
                    <li>
                        Is your data transferred outside of the European Union?
                        <p>
                            As we are located outside of the European Union, we will ensure that any entity, whether our
                            processor or affiliate that transfers Personal Data to us from the EU employs appropriate
                            safeguards. If your Personal Data is transferred to us from the European Economic Area by
                            our service providers (i.e., Data Processors who are engaged on our behalf) and business
                            partners, we will ensure that the transfer is lawful.

                        </p>
                    </li>
                    <li>
                        What are your rights?
                        <p>
                            Under the GDPR and relevant implementation acts, individuals have statutory rights related
                            to their Personal Data. Please note that rights are not absolute and may be subject to
                            conditions.
                            <br />
                            <br />
                            One key right is the Right to object. You have the right to object to the processing of your
                            Personal Data where we are relying on legitimate interests as our legal basis (see above).
                            Under certain circumstances, we may have compelling legitimate grounds that allow us to
                            continue processing your Personal Data. Insofar as the processing of your Personal Data
                            takes place for direct marketing purposes, including profiling for direct marketing, we will
                            always honor your request.
                            <br />
                            <br />
                            Other rights are as follows: Right to withdraw consent. Insofar as our processing of your
                            Personal Data is based on your consent (see above), you have the right to withdraw consent
                            at any time. Right of access. You have the right to request access to your Personal Data.
                            Right to rectification. You have the right to request rectification of the Personal Data
                            that we hold about you. Right to erasure. You have the right to request erasure of your
                            Personal Data. This enables you to ask us to delete or remove Personal Data in certain
                            circumstances. Right to restriction. You have the right to request restriction of processing
                            of your Personal Data. Right to data portability. In some cases, you have the right to
                            request to transfer your Personal Data to you or to a third party of your choice. The
                            exercise of the aforementioned rights is free of charge and can be carried out by accessing
                            our Data Subject Request Portal or contacting us at PCTC.
                            <br />
                            <br />
                            Before responding to your request, we will verify your identity and/or ask you to provide us
                            with more information to respond to your request, if we have any doubts about your identity.
                            We will do our best to respond to your request within one month unless your request is
                            particularly complex (for example if your request concerns a large amount of sensitive
                            data). In such a case, we will inform you of the need to extend this response time by two
                            additional months.

                        </p>
                    </li>
                    <li>
                        How about children’s privacy?
                        <p>
                            PCTC does not address anyone under the age of 18. We do not knowingly collect personally
                            identifiable information from anyone under the age of 18. If you are a parent or guardian
                            and you are aware that your child has provided us with Personal Data, please contact us at
                            PCTC. If we become aware that we have collected Personal Data from children without
                            verification of parental consent, we take steps to remove that information from our servers.

                        </p>
                    </li>
                    <li>
                        What happens if we make changes to this Privacy Policy?
                        <p>
                            We may update this policy to reflect changes to our information practices but we will not
                            reduce your rights under this Privacy Policy without your express consent. We will post any
                            changes to this page and, if the changes are significant, we will provide a more prominent
                            notice. We will also keep prior versions of this Privacy Policy in an archive for your
                            review. We encourage you to periodically review our Privacy Policy for the latest
                            information on our privacy practices and to contact us if you have any questions or
                            concerns.

                        </p>
                    </li>

                </ol>
            </div>
        </div>


    </section>
           
 
        </>
    );
};

export default PrivacyPolicy;