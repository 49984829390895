import React from 'react';

const SingleItem = ({data, NoticeModalShow}) => {
    return (
        <>
                    <div className="announcement-page-item">
            
                        <label for="announcement-page1" className="announcement-page-item-title" onClick={() => NoticeModalShow(data?._id)}>
                        {data?.name}
                            <span>Click Here</span>
                        </label>

                </div>
                   
        </>
    );
};

export default SingleItem;