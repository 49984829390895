import React, { useEffect } from "react";
import {  NavLink } from 'react-router-dom';
import "./MenuIndicator/MenuIndicator.css"
 
const MobileUserFooter = () => {
 

    useEffect(() => {
        const list = document.querySelectorAll('.list-link');

        function activeLink() {
          list.forEach((li) => {
            li.classList.remove('active')
          });
          this.classList.add('active');
        }
        
        list.forEach((li) => {
          li.addEventListener(
            'click',
            activeLink,
          );
        });

    }, [])
  
  return (
    <>

<div className="menu-indicator-navigation">
  <ul>
  <li className="list-link active">
      <NavLink to="/user/dashboard">
        <span className="icon">
          <i className="fa-solid fa-house"></i>
        </span>
        <span className="text">Home</span>
      </NavLink>
    </li>
 
 
    <li className="list-link">
      <NavLink to="/user/markets">
        <span className="icon">
         <i className="fa-solid fa-square-poll-vertical"></i>
        </span>
        <span className="text">Markets</span>
      </NavLink>
    </li>
    <li className="list-link">
      <NavLink to="/user/trade">
        <span className="icon">
        <i className="fa-solid fa-chart-pie"></i>
        </span>
        <span className="text">Trade</span>
      </NavLink>
    </li>

    <li className="list-link">
      <NavLink to="/user/news">
        <span className="icon">
       
        <i className="fa-regular fa-newspaper"></i>
        </span>
        <span className="text">News</span>
      </NavLink>
    </li>
    <li className="list-link">
      <NavLink to="/user/assets">
        <span className="icon">
        <i className="fa-regular fa-gem"></i>
        </span>
        <span className="text">Assets</span>
      </NavLink>
    </li>
    {/* <div className="indicator"></div> */}
  </ul>
</div>

 
      {/* <SupportIcon></SupportIcon> */}
    </>
  );
};

export default MobileUserFooter;
