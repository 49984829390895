import React, { useRef } from "react";
import Crypto from "./Crypto/Crypto";
import StocksPage from "./Stocks/StocksPage";
import EnergisesPage from "./Energises/EnergisesPage";
import MetalsPage from "./Metals/MetalsPage";


const Markets = () => {
    const StocksShowRef = useRef();
    const CryptoShowRef = useRef();
    const EnergisesShowRef = useRef();
  
    const MetalsShowRef = useRef();
  
    const StocksActiveClassRef = useRef();
    const CryptoActiveClassRef = useRef();
    const EnergisesActiveClassRef = useRef();
  
    const MetalsActiveClassRef = useRef();
  
    const CryptoMarketShow = (data) => {
      if (data === "Stocks") {
        StocksShowRef.current.style.display = "block";
        StocksActiveClassRef.current.classList.add("active");
  
        CryptoShowRef.current.style.display = "none";
        EnergisesShowRef.current.style.display = "none";
        MetalsShowRef.current.style.display = "none";
        CryptoActiveClassRef.current.classList.remove("active");
        EnergisesActiveClassRef.current.classList.remove("active");
        MetalsActiveClassRef.current.classList.remove("active");
      }
      if (data === "Crypto") {
        CryptoShowRef.current.style.display = "block";
        CryptoActiveClassRef.current.classList.add("active");
  
        StocksShowRef.current.style.display = "none";
        EnergisesShowRef.current.style.display = "none";
        MetalsShowRef.current.style.display = "none";
        StocksActiveClassRef.current.classList.remove("active");
        EnergisesActiveClassRef.current.classList.remove("active");
        MetalsActiveClassRef.current.classList.remove("active");
      }
      if (data === "Energises") {
        EnergisesShowRef.current.style.display = "block";
        EnergisesActiveClassRef.current.classList.add("active");
  
        CryptoShowRef.current.style.display = "none";
        StocksShowRef.current.style.display = "none";
        MetalsShowRef.current.style.display = "none";
        CryptoActiveClassRef.current.classList.remove("active");
        StocksActiveClassRef.current.classList.remove("active");
        MetalsActiveClassRef.current.classList.remove("active");
      }
  
      if (data === "Metals") {
        MetalsShowRef.current.style.display = "block";
        MetalsActiveClassRef.current.classList.add("active");
  
        CryptoShowRef.current.style.display = "none";
        EnergisesShowRef.current.style.display = "none";
        StocksShowRef.current.style.display = "none";
        CryptoActiveClassRef.current.classList.remove("active");
        EnergisesActiveClassRef.current.classList.remove("active");
        StocksActiveClassRef.current.classList.remove("active");
      }
    };
    return (
        <>
          <div className="user-mobile-market market-area-sw mobile-market-view">
            <div className="  mobile-view">
              <div className="container-fluid">
                <ul className="nav nav-pills mt-3" id="pills-tab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="pills-top-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-top"
                      type="button"
                      role="tab"
                      aria-controls="pills-top"
                      aria-selected="true"
                      onClick={() => CryptoMarketShow("Crypto")}
                      ref={CryptoActiveClassRef}
                    >
                      Crypto
                    </button>
                  </li>
                  
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-stocks-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-stocks"
                      type="button"
                      role="tab"
                      aria-controls="pills-stocks"
                      aria-selected="false"
                      tabindex="-1"
                      ref={MetalsActiveClassRef}
                      onClick={() => CryptoMarketShow("Metals")}
                    >
                      Metal &amp; Energises
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      class="nav-link"
                      id="pills-metals-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-metals"
                      type="button"
                      role="tab"
                      aria-controls="pills-metals"
                      aria-selected="false"
                      tabindex="-1"
                      onClick={() => CryptoMarketShow("Stocks")}
                      ref={StocksActiveClassRef}
                    >
                      Stock &amp; Indices
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-forex-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-forex"
                      type="button"
                      role="tab"
                      aria-controls="pills-forex"
                      aria-selected="false"
                      tabindex="-1"
                      ref={EnergisesActiveClassRef}
                      onClick={() => CryptoMarketShow("Energises")}
                    >
                      Forex
                    </button>
                  </li>
                </ul>
    
                <div ref={CryptoShowRef}>
                  <Crypto></Crypto>
                </div>
    
                <div style={{ display: "none" }} ref={StocksShowRef}>
                  <StocksPage></StocksPage>
                </div>
    
                <div style={{ display: "none" }} ref={EnergisesShowRef}>
                  <EnergisesPage></EnergisesPage>
                </div>
    
                <div style={{ display: "none" }} ref={MetalsShowRef}>
                  <MetalsPage></MetalsPage>
                </div>
              </div>
            </div>
          </div>
        </>
      );
};

export default Markets;