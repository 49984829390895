import React from "react";
import SingleItem from "./SingleItem";

const Energises = ({ EnergisesData, livePrice }) => {
  return (
    <>
      <div class="tab-content" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="pills-top"
          role="tabpanel"
          aria-labelledby="pills-home-tab"
          tabindex="0"
        >
          <div class="d-flex gap-2 flex-column my-3" id="coinContainer">
            {EnergisesData.map((data, index) => (
              <SingleItem
                data={data}
                key={data?._id}
                index={index}
                livePrice={livePrice}
              ></SingleItem>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Energises;
