import React from "react";
import MobileCandleChart from "./MobileCandleChart";

const MobileCandleChartPage = ({ Symbol, CandleChartLoading }) => {
  if (CandleChartLoading) {
    return (
      <>
        <MobileCandleChart Symbol={Symbol}></MobileCandleChart>
      </>
    );
  } else {
    // return (
    //   <>
    //     <div style={{ width: "300px", height: "400px" }}></div>
    //   </>
    // );
  }
};

export default MobileCandleChartPage;
