import React, { useContext, useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AuthContext } from "../Contexts/AuthContext/AuthProvider";
import { Circles } from "react-loader-spinner";
import Loader from "../Components/Loader/Loader";
const KycPrivateRoute = ({ children }) => {
  const { authUser } = useContext(AuthContext);
  const location = useLocation();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (authUser?._id) {
      fetch(
        `https://api2.prospectstreetx.com/api/admin/user/view/single/${authUser?._id}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setData(data.data);
          setLoading(false);
        });
    }
  }, [authUser?._id]);

  if (loading) {
    return (
      <>
        <Loader></Loader>
      </>
    );
  } else {
    if (!data?.kv) {
      return (
        <Navigate
          to="/id-auth/Kyc"
          sate={{ from: location }}
          replace
        ></Navigate>
      );
    } else {
      return children;
    }
  }
};

export default KycPrivateRoute;
