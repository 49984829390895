import React from "react";
import "./Features.css";

const Features = () => {
  return (
    <>
    <section className="features-section">
    <div className="css-1guolr0 eu09pc10">
        <div className="et_pb_row et_pb_row_9  css-5mcapj e1phv45o0">
          <span className="eu09pc13 css-1yrhdf5 e193smzc0">
            <span dir="auto">Features</span>
          </span>
          <div>
            <div className="css-t3vzqc eu09pc15">
              <div className="css-0 eu09pc12">
                <div className="css-d26b1b e1kdp8s70">
                  <span
                    dir="auto"
                    data-aos="fade-down"
                    data-aos-easing="linear"
                    data-aos-duration="1500"
                  >
                    Get the most out of fundamental and technical analysis with
                    our News Feed and Economic Calendars. More than 100 most
                    widely used technical indicators and widgets. Always stay
                    up-to-date on what is happening in the financial markets
                    with our customizable price alerts.
                  </span>
                </div>
              </div>
              <picture className="css-ducgpr eu09pc14" id="1">
                <img
                  src="https://static.cdnroute.io/files/storage/public/5d/af/120de9d275b1e3c1i1/840@1x.jpeg"
                  alt="Analysis &amp; Alerts"
                  data-aos="zoom-in-up"
                  data-aos-duration="3000"
                />
              </picture>
            </div>
            <div className="css-t3vzqc eu09pc15">
              <div
                className="css-0 eu09pc12"
                data-aos="fade-down"
                data-aos-easing="linear"
                data-aos-duration="1500"
              >
                <div className="css-1fpjsvf eu09pc11">
                  <span className="css-1clpds4 e193smzc0">
                    <span dir="auto">Risk Management</span>
                  </span>
                </div>
                <div className="css-d26b1b e1kdp8s70">
                  <span dir="auto">
                    With features such as Stop Loss/Take Profit, Negative
                    Balance Protection and Trailing Stop, you can manage your
                    losses and profits at the levels you set.
                  </span>
                </div>
              </div>
              <picture className="css-1re51v4 eu09pc14" id="2">
                <img
                  src="https://static.cdnroute.io/files/storage/public/5d/af/0c37f0e523h0b6e6g3/480@2x.jpeg"
                  alt="Risk Management"
                  data-aos="zoom-in-up"
                  data-aos-duration="3000"
                />
              </picture>
            </div>
          </div>
        </div>
      </div>
    </section>
     
    </>
  );
};

export default Features;
