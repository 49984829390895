import React from 'react';
import { Link } from 'react-router-dom';
import dateFormat from "dateformat";
const GridSingleItems = ({data}) => {
    return (
        <>
            <div className="col">
                  <div className="card">
                    <div className="card-body">
                      <h6 className="">{data?.category}</h6>
                      <h5>
                        <Link
                         to={`/news/details/${data?._id}`}
                          className="irwGaLabel irwEventTracking irwGaHasFile"
                        >
                         {data?.title}
                        </Link>
                      </h5>
                      <p>
                        <Link
                          to={`/news/details/${data?._id}`}
                          className="irwGaLabel irwEventTracking irwGaHasFile"
                        >
                          {data?.dis}
                        </Link>
                        <Link to={`/news/details/${data?._id}`} className="coninue-btn">
                          <i className="fa-solid fa-chevron-right"></i> Continue
                          Reading
                        </Link>
                      </p>

                      <span> {dateFormat(data?.createdAt, "d/m/yyyy")}</span>
                    </div>
                  </div>
                </div>
        </>
    );
};

export default GridSingleItems;