import React from 'react';
import dateFormat from 'dateformat';
const SingleItem = ({ data }) => {
    return (
        <>
            <tr>
                <td >
                    <div className='fw-bolder'>
                        {data?.trx}
                    </div>
                </td>
                <td>{dateFormat(data?.createdAt, 'mmmm dS yyyy')}</td>

                <td>
                    {data?.amount}$
                </td>
                <td>
                    {data?.post_balance}$
                </td>
                <td>{data?.details}</td>

            </tr>

        </>
    );
};

export default SingleItem;