import React from "react";
import "./EXCHANGETODAY.css";
import about from "./about-bg.png";
import { LazyLoadImage } from "react-lazy-load-image-component";

const EXCHANGETODAY = () => {
  return (
    <>
      <section className="exchange-today-section">
        <div className="about_block">
          <div className="container">
            <div className="row">
              <div className="col-lg-5">
                <figure>
                  <LazyLoadImage
                    alt="img"
                    effect="blur"
                    data-aos="zoom-in-down"
                    data-aos-duration="1000"
                    src={about}
                  />
                </figure>
              </div>

              <div
                className="col-lg-7 d-flex justify-content-center flex-column"
                data-aos="flip-left"
                data-aos-duration="3000"
              >
                <div className="plans-title mt-3 mb-3">
                  <span>
                    GET STARTED INVESTING <br />
                    WITH PROSPECT CAPITAL EXCHANGE TODAY
                  </span>
                </div>
                <p>
                  Prospect Capital Corporation is a leading publicly-traded
                  Business Development Company (“BDC”). We make debt and equity
                  investments in U.S. middle market businesses across a range of
                  industries, and seek to deliver steady, attractive returns to
                  our shareholders.
                </p>
                <p>We trade on NASDAQ under the ticker symbol “PSEC”.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EXCHANGETODAY;
