import React from 'react';
import dateFormat from 'dateformat';
const SingleItem = ({ data }) => {
    return (
        <>
            <tr>
                <td>
                    {data?.NetworkType} <br />
                    {data?.Transaction}
                </td>
                <td> {dateFormat(data?.createdAt, "d-m-yyyy h:MM:ss TT")}</td>
                <td> {data?.AmountWithVat}$</td>
                <td> {data?.Status === 0
                    ?
                    <span className="badge bg-warning ">Pending</span>
                    :
                    ''
                }
                    {data?.Status === 1
                        ?
                        <span className="badge bg-success">Approve</span>
                        :
                        ''
                    }
                    {data?.Status === 2
                        ?
                        <span className="badge bg-danger">Reject</span>
                        :
                        ''
                    }</td>

            </tr>
        </>
    );
};

export default SingleItem;