import React from "react";
import dateFormat from "dateformat";
import Countdown from "react-countdown";
const SingleItem = ({ data }) => {
  const c_profit = parseFloat(data?.profit_amount) - parseFloat(data?.amount);

  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <span className="badge bg-primary">completed</span>;
    } else {
      // Render a countdown
      return <>{days}d&nbsp;{hours}h&nbsp;{minutes}m&nbsp;{seconds}s</>;
    }
  };

  return (
    <>
      <tr>
        <td>
          <div class="multiline">
            <span class="date d-block">
              {dateFormat(data?.createdAt, "dd-mm-yyyy")}
            </span>
            <span class="time d-block">
              {dateFormat(data?.createdAt, " h:MM TT")}
            </span>
          </div>
        </td>
        <td>{data?.amount}$</td>
        <td>{data?.profit_amount}$</td>
        <td>{c_profit}$</td>

        <td>
          <div class="multiline">
            <span
              class="remaining-time remainingTime"
              data-time_remaining="240325"
            >
              <Countdown date={data?.expired_time} renderer={renderer} />
              {/* 2d 18h 27m 51s */}
            </span>
           
          </div>
        </td>
        <td>
          <div class="multiline">
            <span class="date d-block">
              {dateFormat(data?.expired_time, "dd-mm-yyyy")}
            </span>
            <span class="time d-block">
              {dateFormat(data?.expired_time, "h:MM TT")}
            </span>
          </div>
        </td>
      </tr>
    </>
  );
};

export default SingleItem;
