import React, { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../../../Contexts/AuthContext/AuthProvider";
import { toast } from "react-toastify";
import axios from "axios";
import DashBoardSideBar from "../UserDashBoard/DashBoardSideBar";
import "./Loan.css";
import SingleItem from "./SingleItem";
import CustomPagination from "../../../CustomPagination/CustomPagination";
import LoanSingleHistoryItem from "./LoanSingleHistoryItem";
import { Circles } from "react-loader-spinner";
const Loan = () => {
  const { authUser } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [paginateData, setPaginateData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`https://api2.prospectstreetx.com/api/user/loan/package/view`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data?.data);
        setPaginateData(data);
        setLoading(false);
      });
  }, []);

  const handlePage = (event) => {
    try {
      const pageNumber = event?.selected + 1;
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      axios
        .get(
          `https://api2.prospectstreetx.com/api/user/loan/package/view/${authUser?._id}?page=${pageNumber}`,
          config
        )
        .then((response) => {
          setData(response?.data?.data);
          setPaginateData(response?.data);
        })
        .catch((error) => {});
    } catch (error) {
      console.log(error);
    }
  };

  const [loanHistory, setLoanHistory] = useState([]);
  const [updateData, setUpdateData] = useState([]);
  const [loanHistoryPaginateData, setLoanHistoryPaginateData] = useState([]);

  useEffect(() => {
    if (authUser?._id) {
      fetch(
        `https://api2.prospectstreetx.com/api/user/loan/history/view/${authUser?._id}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setLoanHistory(data.data);
          setLoanHistoryPaginateData(data);
          setLoading(false);
        });
    }
  }, [authUser?._id, updateData]);

  const handleloanHistoryPage = (event) => {
    try {
      const pageNumber = event?.selected + 1;
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      axios
        .get(
          `https://api2.prospectstreetx.com/api/user/loan/history/view/${authUser?._id}?page=${pageNumber}`,
          config
        )
        .then((response) => {
          setLoanHistory(response?.data?.data);
          setLoanHistoryPaginateData(response?.data);
        })
        .catch((error) => {});
    } catch (error) {
      console.log(error);
    }
  };

  // Handle submit form

  const refSubmitDis = useRef();
  const [InputData, setInputData] = useState([]);
  const [findLoanId, setFindLoanId] = useState([]);
  const [errorMessage, setErrorMessage] = useState([]);

  const handleSubmitData = async (event) => {
    event.preventDefault();
    refSubmitDis.current.setAttribute("disabled", true);

    try {
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };

      await axios
        .post(
          `https://api2.prospectstreetx.com/api/user/loan/store/${findLoanId}`,
          { ...InputData, user_id: authUser?._id },
          config
        )
        .then((data) => {
          setUpdateData(data?.data);
          toast.success(`${data.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          refSubmitDis.current.removeAttribute("disabled");

          event.target.reset();
        })
        .catch((error) => {
          setErrorMessage(error?.response?.data);
          toast.error(`${error?.response?.data?.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          refSubmitDis.current.removeAttribute("disabled");
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputBlur = (event) => {
    const value = event.target.value;
    const field = event.target.name;
    const newUser = { ...InputData };
    newUser[field] = value;
    setInputData(newUser);
  };

  if (loading) {
    return (
      <>
        <div className="loader-section d-flex justify-content-center align-items-center">
          <Circles
            height="80"
            width="80"
            color="#4fa94d"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperclassName=""
            visible={true}
          />
        </div>
      </>
    );
  } else {
    return (
      <>
        <section className="user-dashboard">
          <div className="row">
            <div className="col-3 user-menu-left">
              <DashBoardSideBar></DashBoardSideBar>
            </div>
            <div className="col-9">
              <section class="user-loan-area">
                <div class="container col-12 text-right py-2">
                  {/* <div class="d-flex py-2 nav-buttons flex-align gap-md-3 gap-2">
                    <a
                      href="https://swoopventures.com/user/loan/plans"
                      class="btn btn-warning w-50 active"
                    >
                      Loan Plans
                    </a>
                    <a
                      href="https://swoopventures.com/user/loan/list"
                      class="btn btn-primary w-50"
                    >
                      My Loan List
                    </a>
                  </div> */}
                  <div class="row g-4 justify-content-center">
                    {data?.length !== 0 ? (
                      data.map((data, index) => {
                        if (data) {
                          return (
                            <SingleItem
                              data={data}
                              index={index}
                              key={data._id}
                              handleSubmitData={handleSubmitData}
                              handleInputBlur={handleInputBlur}
                              setFindLoanId={setFindLoanId}
                              refSubmitDis={refSubmitDis}
                            ></SingleItem>
                          );
                        }
                      })
                    ) : (
                      <>
                        <div className="text-center h-50">
                          <h6> Data not found</h6>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="pt-3">
                    {/* paginateLinks */}
                    <CustomPagination
                      data={paginateData}
                      handlePage={handlePage}
                    ></CustomPagination>
                    {/* paginateLinks */}
                  </div>
                </div>
  
                {/* <!-- ////// Histry --> */}
  
                <div class="py-4">
                  <div class="mining-area">
                    <div class="col-xxl-12 col-md-12 pt-5">
                      <div class=" text-white card ">
                        <div class="card-header d-flex flex-wrap justify-content-between">
                          <h4 class="title text-primary">My Investemt</h4>
                        </div>
                        <div class="card-body  text-white p-2">
                          <div class="text-white table-responsive ">
                            <table
                              style={{ background: "#1A233A !important" }}
                              class="text-white table table-responsive-md "
                            >
                              <thead>
                                <tr
                                  style={{ color: "#bcd0f7 !important" }}
                                  class=" mx-auto table-header"
                                >
                                  <th>S.N.</th>
                                  <th>Loan No. | Plan</th>
                                  <th>Amount</th>
                                  <th>Installment Amount</th>
                                  <th>Installment</th>
                                  <th>Next Installment</th>
                                  <th>Paid</th>
                                  <th>Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {loanHistory?.length !== 0 ? (
                                  loanHistory?.map((data, index) => {
                                    if (data) {
                                      return (
                                        <LoanSingleHistoryItem
                                          data={data}
                                          index={index}
                                          key={data?._id}
                                          paginateData={loanHistoryPaginateData}
                                        ></LoanSingleHistoryItem>
                                      );
                                    }
                                  })
                                ) : (
                                  <tr>
                                    <td
                                      className="text-muted text-center"
                                      colSpan="100%"
                                    >
                                      No records available
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                          {/* paginateLinks */}
                          <CustomPagination
                            data={loanHistoryPaginateData}
                            handlePage={handleloanHistoryPage}
                          ></CustomPagination>
                          {/* paginateLinks */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
      </>
    );
  }

 
};

export default Loan;
