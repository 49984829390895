import React, { useEffect,  useState, useRef } from "react";
import { createChart } from "lightweight-charts";
import moment from 'moment-timezone';

const MobileCandleChart = ({Symbol}) => {
    const GraphRef = useRef();
  const [data, setData] = useState([]);
  const [candleSeries, setCandleSeries] = useState(null);

  const chartProperties = {
    width: 300,
    height: 400,
    timeScale: {
      timeVisible: true,
      secondsVisible: false,
    },
  };



  useEffect(() => {
    const chart = createChart(GraphRef?.current, chartProperties);
    setCandleSeries(chart.addCandlestickSeries());
  }, [Symbol]);


  useEffect(() => {
    const date = new Date();
    let daysMinus =new Date( date + 1000 * 60 * 5 );
    daysMinus.setDate(daysMinus.getDate() - 2);
    let GMTTime = moment.tz(new Date(), "GMT").format("YYYY-MM-DD-HH:mm");
    let GMTTimedaysMinus = moment.tz(daysMinus, "GMT").format("YYYY-MM-DD-HH:mm");
    // console.log(GMTTime )
    // console.log(GMTTimedaysMinus)

    fetch(
      `https://marketdata.tradermade.com/api/v1/timeseries?currency=${Symbol}&api_key=WNq543KBXkl6fvX4r5_o&start_date=${GMTTimedaysMinus}&end_date=${GMTTime}&format=records&interval=minute&period=1`,
      {
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setData(data?.quotes);
        // console.log(data)
      });
  }, [Symbol]);

  useEffect(() => {
    if (data.length !== 0) {
      const cdata = data.map((d) => {
        let date = new Date(d?.date); 
        let milliseconds = date.getTime();
        return {
          time: parseFloat(milliseconds / 1000),
          open: parseFloat(d?.open),
          high: parseFloat(d?.high),
          low: parseFloat(d?.low),
          close: parseFloat(d?.close),
        };
      });
      candleSeries.setData(cdata);
    }
  },  [data]);

  // live price Show

  useEffect(() => {
    const interval = setInterval(() => {
      if (data) {
        fetchData();
      }
    }, 60000);
    return () => clearInterval(interval);
  }, [data]);

  const fetchData = async () => {
    var GMTTime = moment.tz(new Date(), "GMT").format("YYYY-MM-DD-HH:mm");

    const data = await fetch(
      `https://marketdata.tradermade.com/api/v1/minute_historical?currency=${Symbol}&date_time=${GMTTime}&api_key=nCu5ZPDQpkMmGvtYyMC3`
    );
    const apiResponse = await data.json();

    let dateFormat = moment.tz(new Date(), "GMT").format("YYYY-MM-DD HH:mm:ss");
    let Newdate = new Date(dateFormat); 
    let milliseconds = Newdate.getTime();
    // console.log(apiResponse);
    // console.log(dateFormat);

    const chartData = {
      time: parseFloat(milliseconds / 1000),
      open: parseFloat(apiResponse?.open),
      high: parseFloat(apiResponse?.high),
      low: parseFloat(apiResponse?.low),
      close: parseFloat(apiResponse?.close),
    };
    candleSeries.update(chartData);
  };

  // live price end




  return (
    <>
      <div className="m-auto">
        <div  ref={GraphRef}></div>
      </div>
    </>
  );
};

export default MobileCandleChart;