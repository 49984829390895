import React from 'react';
import MobileNotices from './MobileNotices/MobileNotices';
import MobileSilder from './MobileSilder/MobileSilder';
import MobileCryptoChain from './MobileCryptoChain/MobileCryptoChain';

const MobileUserDashboard = () => {
    return (
        <>
            <MobileSilder></MobileSilder>
            <MobileNotices></MobileNotices>
            <MobileCryptoChain></MobileCryptoChain>
        </>
    );
};

export default MobileUserDashboard;

// bit 

// et 
// so 
// gold