import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
const TradingPlatform = () => {
    return (
        <>
            <section className='trading-platform-area'>
                <div className='py-4 '>
                     {/* container  div*/}
                    <div className=" "> 
                        <div className="trading-platform-title">
                            <h4>Online Trading Platform</h4>
                            <p>CFDs on Stocks, ETFs, Commodities, Indices, Crypto currencies and Forex</p>
                        </div>
                        <div className="trading-platform-dis">
                            <p> Prospect Capital is one of the fastest growing online trading brands in the world. Voted the best mobile trading platform, we have now expanded our offerings to include CFDs on stocks, ETFs and Forex trading.</p>

                            <p className='pt-2'>We have also refined our offering and introduced plenty of new products in our bid to continue giving our customers the ultimate online trading experience and to help them optimize their investment portfolio. Now, using our platform, our members can try CFDs on currency pairs, CFDs on stocks, CFDs on commodities, CFDs on crypto currencies, as well as CFDs on ETFs.</p>
                        </div>
                        <div className="trading-platform-market">
                            <div className="row pb-4">
                                <div className="col-6"   data-aos="zoom-out-down"
                  data-aos-duration="1000">
                                    <div className="d-flex align-items-center trading-platform-market-image-one trading-platform-market-card">
                                        <LazyLoadImage
                                            alt='img'
                                            effect="blur"
                                            src="https://static.cdnroute.io/files/storage/public/61/71/35304542c5j5j9h7d8.svg" />
                                        <div className='trading-platform-marke-dis'>
                                            <h6>Forex</h6>
                                            <p>Explore and trade major, minor and exotic currency pairs with efficient spreads</p>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-6" data-aos="zoom-out-down"
                  data-aos-duration="1000">
                                    <div className="d-flex align-items-center trading-platform-market-image-four trading-platform-market-card">
                                        <LazyLoadImage
                                            alt='img'
                                            effect="blur"
                                            src="https://static.cdnroute.io/files/storage/public/5b/50/7f9b89847.svg" />
                                        <div className='trading-platform-marke-dis'>
                                            <h6>CFD Stocks</h6>
                                            <p>Trade CFDs on stocks of leading companies and industry giants without actually owning them.</p>
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div className="row pb-4">
                                <div className="col-6"   data-aos="zoom-out-down"
                  data-aos-duration="1000">
                                    <div className="d-flex align-items-center trading-platform-market-card">
                                        <LazyLoadImage
                                            alt='img'
                                            effect="blur"
                                            src="https://static.cdnroute.io/files/storage/public/5c/b5/f4eea648b0h5i8b0g5.svg" />
                                        <div className='trading-platform-marke-dis'>
                                            <h6>Cryptocurrencies</h6>
                                            <p>Trade CFDs on popular digital currencies with leverage.</p>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-6"   data-aos="zoom-out-down"
                  data-aos-duration="1000">
                                    <div className="d-flex align-items-center trading-platform-market-image-two trading-platform-market-card">
                                        <LazyLoadImage
                                            alt='img'
                                            effect="blur"
                                            src="https://static.cdnroute.io/files/storage/public/5b/86/68389bf6b.svg" />
                                        <div className='trading-platform-marke-dis'>
                                            <h6>Commodities</h6>
                                            <p>Speculate with CFDs on hard or soft commodities such as gold, silver, oils and grains.</p>
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div className="row pb-4">
                                <div className="col-6" data-aos="zoom-out-down"
                  data-aos-duration="1000" >
                                    <div className="d-flex align-items-center trading-platform-market-image-three trading-platform-market-card">
                                        <LazyLoadImage
                                            alt='img'
                                            effect="blur"
                                            src="https://static.cdnroute.io/files/storage/public/5b/50/81f9b71a0.svg" />
                                        <div className='trading-platform-marke-dis'>
                                            <h6>ETFs</h6>
                                            <p>Diversify your trading with CFDs on the exchange-traded funds, where multiple assets are gathered in one basket.</p>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-6" data-aos="zoom-out-down"
                  data-aos-duration="1000">
                                    <div className="d-flex align-items-center trading-platform-market-image-six trading-platform-market-card">
                                        <LazyLoadImage
                                            alt='img'
                                            effect="blur"
                                            src="https://static.cdnroute.io/files/storage/public/5b/50/7f7d36763.svg" />
                                        <div className='trading-platform-marke-dis'>
                                            <h6>Indices</h6>
                                            <p>Select from a list of CFDs on widely traded indices representing a particular market sector or national economy.</p>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </section>
           

        </>
    );
};

export default TradingPlatform;