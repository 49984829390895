import React, { useEffect, useState } from 'react';
import Stocks from './Stocks';
import { Link } from 'react-router-dom';
;
const StocksPage = () => {
    const [StocksData, setStocksData] = useState([]);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        fetch(`https://api2.prospectstreetx.com/api/user/trade/market/stocks/view`, {
            method: 'GET',
        })
            .then(res => res.json())
            .then(data => {
                setStocksData(data.data);
                setLoading(false);
            });


    }, [])

   

    const StocksSymbolString = StocksData.reduce((acc, curr) => `${acc}${curr.symbol},`, "");
    const StocksSymbol = StocksSymbolString.substring(0, StocksSymbolString?.length - 1);
  
    const [StockslivePrice, setStocksLivePrice] = useState([]);
  
    useEffect(() => {
        StocksfetchData();
    }, [StocksData]);
  
    const StocksfetchData = async () => {
      if (StocksSymbol?.length !== 0) {
        const data = await fetch(
          `https://marketdata.tradermade.com/api/v1/live?currency=${StocksSymbol}&api_key=l2cBbXnp1M8kqDaVjC2H`
        );
        const apiResponse = await data.json();
        setStocksLivePrice(apiResponse?.quotes);
      }
    };
  
    const [Indicesdata, setIndicesData] = useState([]);


    useEffect(() => {
        fetch(`https://api2.prospectstreetx.com/api/user/trade/market/indices/view`, {
            method: 'GET',
        })
            .then(res => res.json())
            .then(data => {
                setIndicesData(data.data);
            });


    }, [])

         
    const IndicesSymbolString = Indicesdata.reduce(
        (acc, curr) => `${acc}${curr.symbol},`,
        ""
      );
      const IndicesSymbol = IndicesSymbolString.substring(0, IndicesSymbolString?.length - 1);
    
      const [IndiceslivePrice, setIndicesLivePrice] = useState([]);
    
      useEffect(() => {
        IndicesfetchData();
      }, [Indicesdata]);
    
      const IndicesfetchData = async () => {
        if (IndicesSymbol?.length !== 0) {
          const data = await fetch(
            `https://marketdata.tradermade.com/api/v1/live?currency=${IndicesSymbol}&api_key=l2cBbXnp1M8kqDaVjC2H`
          );
          const apiResponse = await data.json();
          setIndicesLivePrice(apiResponse?.quotes);
        }
      };
    
 
    return (
        <>


            <Stocks StocksData={StocksData} StockslivePrice={StockslivePrice} Indicesdata={Indicesdata}IndiceslivePrice={IndiceslivePrice}></Stocks>
        </>
    );


};

export default StocksPage;