import React from 'react';
import INVESTMENTPLANS from './INVESTMENTPLANS/INVESTMENTPLANS';
import COMMISION from './COMMISION/COMMISION';
const InvestPage = () => {
    return (
        <>
           
            <INVESTMENTPLANS></INVESTMENTPLANS>
            
        </>
    );
};

export default InvestPage;