import React, { createContext, useEffect, useState } from 'react';
const getlocalStorageItem = () =>{
    let userId = localStorage.getItem("ID");
    if(userId){

        return JSON.parse(userId);
    }else{
// console.log("is ok")

        return null;
    }

}

export const AuthContext = createContext('');

const AuthProvider = ({children}) => {
    const [authId, setAuthId] = useState(getlocalStorageItem());
    const [authUser, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const LoginWithEmail = (data) =>{
        setAuthId(data);
        // console.log("context api : ", data);
    }
  
      
    function getCookie(name) {
        const cookieValue = document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`);
        return cookieValue ? cookieValue.pop() : '';
      }

      let token = getCookie('gffex_token');

      useEffect(() => {
        if(authId){
            fetch(`https://api2.prospectstreetx.com/api/admin/user/view/single/${authId?.user_id}`, {
                method: 'GET',
            })
                .then((res) => res.json())
                .then((data) => {
                    setUser(data.data);
                    setLoading(false)
                })
        }else{
            setLoading(false)
        }
        
    }, [authId])

    const authInfo = {authUser, LoginWithEmail,  setUser, token  };
    if(loading){
        return;
    }
    return (
        <AuthContext.Provider value={authInfo}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;