import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import SingleItem from "./SingleItem";
import CustomPaginationMobile from "../../../../CustomPagination/CustomPaginationMobile";
import Loader from "../../../../Loader/Loader";
import { AuthContext } from "../../../../../Contexts/AuthContext/AuthProvider";

const MobileInvestPlanHistory = () => {
  const { authUser } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [paginateData, setPaginateData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(
      `https://api2.prospectstreetx.com/api/user/tnvestment/plan/history/view/${authUser?._id}`,
      {
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setData(data?.data);
        setPaginateData(data);
        setLoading(false);
      });
  }, []);

  const handlePage = (event) => {
    try {
      const pageNumber = event?.selected + 1;
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      axios
        .get(
          `https://api2.prospectstreetx.com/api/user/tnvestment/plan/history/view/${authUser?._id}?page=${pageNumber}`,
          config
        )
        .then((response) => {
          setData(response?.data?.data);
          setPaginateData(response?.data);
        })
        .catch((error) => {});
    } catch (error) {
      console.log(error);
    }
  };
  if (loading) {
    return (
      <>
        <>
          <Loader />
        </>
      </>
    );
  } else {
    return (
      <>
        <section className="container">
          <div className="mobile-history-table mb-5 mt-2 ">
          <div className="pb-5">
          <div className="user-deposit-history  px-2 pb-5">
              <div className="user-referral-history-titile ps-2">
                <h3> Invest Plan  History</h3>
              </div>
              <div className="user-referral-history-area  ">
                <div className="user-referral-history-items trade-history-table ">
                  <table className="user-referral-history-table ">
                    <thead>
                      <tr className="table-headers">
                        <th>Invest&nbsp;Date</th>
                        <th>Inves&nbsp;Amount</th>
                        <th>Total&nbsp;Return</th>
                        <th>C.Profit</th>
                        <th>Remaining</th>
                        <th>End&nbsp;At</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.length !== 0 ? (
                        data.map((data, index) => {
                          if (data) {
                            return (
                              <SingleItem
                                data={data}
                                index={index}
                                key={data?._id}
                                paginateData={paginateData}
                              ></SingleItem>
                            );
                          }
                        })
                      ) : (
                        <tr>
                          <td className="text-muted text-center" colSpan="100%">
                            No records available
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                {/* paginateLinks */}
                <CustomPaginationMobile
                  data={paginateData}
                  handlePage={handlePage}
                ></CustomPaginationMobile>
                {/* paginateLinks */}
              </div>
            </div>
          </div>
           
          </div>
        </section>
      </>
    );
  }
};

export default MobileInvestPlanHistory;
