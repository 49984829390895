import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../../../Contexts/AuthContext/AuthProvider';
import axios from 'axios';
import { Circles } from 'react-loader-spinner';
import CustomPagination from '../../../../CustomPagination/CustomPagination';
import SingleItem from './SingleItem';
import { DatePicker} from "antd";
import dateFormat from "dateformat";
const WithdrawalHistroy = () => {
    const { authUser } = useContext(AuthContext);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [paginateData, setPaginateData] = useState([]);

    useEffect(() => {
        if (authUser?._id) {
            fetch(`https://api2.prospectstreetx.com/api/user/withdrawal/view/${authUser?._id}`, {
                method: 'GET',
            })
                .then(res => res.json())
                .then(data => {
                    setData(data.data);
                    setPaginateData(data);
                    setLoading(false);

                });


        }

    }, [authUser?._id])

    const [dateValue, setDateValue] = useState([]);
    const [dateValueShow, setDateValueShow] = useState([]);
    const { RangePicker } = DatePicker;

    const Time = () => {
        return (
            <>
                <RangePicker
                    value={dateValueShow}
                    onChange={(e) => {
                        setDateValue(e.map(item => {
                            return dateFormat(item, "isoUtcDateTime")
                        }));
                        setDateValueShow(e);
                    }}
                />
            </>
        );
    };

    const handlePage = (event) => {
        try {
            const pageNumber = event?.selected + 1;
            const config = {
                headers: {
                    "content-type": "application/json",
                },
            };
            axios
                .get(
                    `https://api2.prospectstreetx.com/api/user/withdrawal/view/${authUser?._id}?page=${pageNumber}&start_date=${dateValue[0]}&end_date=${dateValue[1]}`,
                    config
                )
                .then((response) => {
                    setData(response?.data?.data);
                    setPaginateData(response?.data);
                })
                .catch((error) => { });
        } catch (error) {
            console.log(error);
        }
    };

    if (loading) {
        return (
            <>
                <div className="loader-section d-flex justify-content-center align-items-center">
                    <Circles
                        height="80"
                        width="80"
                        color="#4fa94d"
                        ariaLabel="circles-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                    />
                </div>
            </>
        );

    } else {
        return (
            <>
                <div className='history-filter'>
                    <div className='d-flex justify-content-end'>
                        <Time className="datepicker-here form-control " />
                        <button className="btn btn-primary" onClick={handlePage}>Filter</button>
                    </div>
                </div>
                <table className='user-referral-history-table'>
                    <thead>
                        <tr className="table-headers">
                            <th>Network Type</th>
                            <th>Date</th>
                            <th>Amount</th>
                            <th>Wallet&nbsp;Address</th>
                            <th>Transaction</th>
                            <th>Progress</th>
                        </tr>
                    </thead>
                    <tbody>

                        {
                            data?.length !== 0 ? (
                                data.map((data, index) => {
                                    if (data) {
                                        return (
                                            <SingleItem
                                                data={data} key={data?._id} paginateData={paginateData}
                                            ></SingleItem>
                                        );
                                    }
                                })
                            ) : (
                                <tr>
                                    <td className="text-muted text-center" colSpan="100%">
                                        No records available
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
                {/* paginateLinks */}
                <CustomPagination data={paginateData} handlePage={handlePage}>
                </CustomPagination>
                {/* paginateLinks */}
            </>
        );
    }


};

export default WithdrawalHistroy;