import React, { useEffect, useState } from 'react';
import SingleItem from './SingleItem';
import axios from 'axios';
import { Circles } from 'react-loader-spinner';
import CustomPagination from '../../../../CustomPagination/CustomPagination';

const IndicesPage = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [paginateData, setPaginateData] = useState([]);


    useEffect(() => {
        fetch(`https://api2.prospectstreetx.com/api/user/trade/market/indices/view`, {
            method: 'GET',
        })
            .then(res => res.json())
            .then(data => {
                setData(data.data);
                setPaginateData(data);
                setLoading(false);
            });


    }, [])

    const handlePage = (event) => {
        try {
            const pageNumber = event?.selected + 1;
            const config = {
                headers: {
                    "content-type": "application/json",
                },
            };
            axios
                .get(
                    `https://api2.prospectstreetx.com/api/user/trade/market/indices/view?page=${pageNumber}`,
                    config
                )
                .then((response) => {
                    setData(response?.data?.data);
                    setPaginateData(response?.data);
                })
                .catch((error) => { });
        } catch (error) {
            console.log(error);
        }
    };


    
    const SymbolString = data.reduce(
        (acc, curr) => `${acc}${curr.symbol},`,
        ""
      );
      const Symbol = SymbolString.substring(0, SymbolString?.length - 1);
    
      const [livePrice, setLivePrice] = useState([]);
    
      useEffect(() => {
        fetchData();
      }, [data]);
    
      const fetchData = async () => {
        if (Symbol?.length !== 0) {
          const data = await fetch(
            `https://marketdata.tradermade.com/api/v1/live?currency=${Symbol}&api_key=DJTiUpuqDsNRHN2zKBFV`
          );
          const apiResponse = await data.json();
          setLivePrice(apiResponse?.quotes);
        }
      };
    
    
   
    if (loading) {
        return (
            <>
                <div className="loader-section d-flex justify-content-center align-items-center">
                    <Circles
                        height="80"
                        width="80"
                        color="#4fa94d"
                        ariaLabel="circles-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                    />
                </div>
            </>
        );
        
    } else {
        return (
            <>
                 <div className="container-custom">
          <div className="py-4">
            <div className="user-deposit-title">
              <h1>Indices</h1>
            </div>

            <div className="market-area-sw">
              <div  className="">
                <div className="">
                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="pills-top"
                      role="tabpanel"
                      aria-labelledby="pills-home-tab"
                      tabindex="0"
                    >
                      <div
                        className="d-flex gap-2 flex-column my-3"
                        id="coinContainer"
                      >
                        {data.map((data, index) => (
                          <SingleItem
                            index={index}
                            data={data}
                            key={data?._id}
                            livePrice={livePrice}
                          ></SingleItem>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* paginateLinks */}
          <CustomPagination
            data={paginateData}
            handlePage={handlePage}
          ></CustomPagination>
          {/* paginateLinks */}
        </div>
            </>
        );

    }
    
};

export default IndicesPage;