import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useRef, useState } from 'react';
import MobileMenu from '../../../Home/MobileMenu/MobileMenu';
import { Circles } from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';
import { AuthContext } from '../../../../../Contexts/AuthContext/AuthProvider';
import axios from 'axios';
import FlipClockCountdown from '@leenguyen/react-flip-clock-countdown';
import Completed from './../Completed';
import MobileCandleChartPage from '../../MobileCandleChart/MobileCandleChartPage';
import MobilePracticeTradeHistory from '../MobilePracticeTradeHistory/MobilePracticeTradeHistory';
import Loader from '../../../../Loader/Loader';
const MobileEnergisesPracticeTrade = () => {
    
    const [results, setResults] = useState([]);
    const { authUser } = useContext(AuthContext);
    const [dataSet, setDataSet] = useState({})
    const [cryptoData, setCryptoData] = useState([]);
    const [singleCryptoData, setSingleCryptoData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetch(`https://api2.prospectstreetx.com/api/user/trade/market/mobile/energises/view`, {
            method: 'GET',
        })
            .then(res => res.json())
            .then(data => {
                setCryptoData(data?.data);
                setLoading(false);
            });


    }, []);


    const [pair, setpair] = useState("");
    const [price, setprice] = useState(0.00);
    const [CandleChartLoading, setCandleChartLoading] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            fetchData();
        }, 1000)
        return () => clearInterval(interval);
    }, [singleCryptoData])


    const fetchData = async () => {
        if (singleCryptoData) {
            const data = await fetch(`https://marketdata.tradermade.com/api/v1/live?currency=${pair}&api_key=8gZa3XqS1ySivhHbHZjB`);
            const apiResponse = await data.json();
            setprice(apiResponse?.quotes[0].bid);
            setDataSet({ price: apiResponse?.quotes[0].bid });
        }
    }
   

    const handleSelect = (e) => {
        if (e.target.value) {
            setCandleChartLoading(false);
            fetch(`https://api2.prospectstreetx.com/api/admin/energises/single/view/${e.target.value}`, {
                method: 'GET',
            })
                .then(res => res.json())
                .then(data => {
                    setSingleCryptoData(data?.data)
                    setpair(`${data?.data?.symbol}`);
                    setCandleChartLoading(true);
                });
        }


    };

    const [thradeSettingData, setthradeSettingData] = useState([]);


    useEffect(() => {
        fetch(`https://api2.prospectstreetx.com/api/admin/trade/setting/view`, {
            method: 'GET',
        })
            .then(res => res.json())
            .then(data => {
                setthradeSettingData(data?.data);
            });


    }, []);


    // Trade Now 
    const [dataValue, setDataValue] = useState({});

    const handleInputBlur = event => {
        const value = event.target.value;
        const field = event.target.name;
        const newUser = { ...dataValue, };
        newUser[field] = value;
        setDataValue(newUser);
    }

    const refSubmitDisLow = useRef();
    const refSubmitDisHigh = useRef();

    const tradeTimeRef = useRef(null);
    const [tradeTime, setTradeTime] = useState(null)
    const handleSubmitData = (HighLow) => {
        if (price !== 0.00) {
            refSubmitDisLow.current.setAttribute("disabled", true);
            refSubmitDisHigh.current.setAttribute("disabled", true);
            const userData = { ...dataValue, CryptoCurrency: singleCryptoData?._id, Crypto_price: price, user_id: authUser?._id, HighLow: HighLow }
            const config = {
                headers: {
                    'content-type': 'application/json',
                }
            };
            axios
                .post(`https://api2.prospectstreetx.com/api/user/Practice/trade/log/energises/store`, userData, config)
                .then(data => {
                    if (data.data.success === true) {
                        toast.success(`${data.data.message}`, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                        });
                        setResults(data.data.data);
                        let timeObject = new Date();
                        timeObject = new Date(timeObject.getTime() + 1000 * (data.data.data.Time + 1));
                        setTradeTime(timeObject);
                        tradeTimeRef.current.classList.remove("d-none");
                        refSubmitDisLow.current.removeAttribute("disabled");
                        refSubmitDisHigh.current.removeAttribute("disabled");
                    } else {
                        toast.error(`${data.data.message}`, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                        });

                        refSubmitDisLow.current.removeAttribute("disabled");
                        refSubmitDisHigh.current.removeAttribute("disabled");
                    }

                })
                .catch(error => {
                    toast.error(`${error?.response?.data?.message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    refSubmitDisLow.current.removeAttribute("disabled");
                    refSubmitDisHigh.current.removeAttribute("disabled");

                })
        } else {
            toast.error(`Something is wrong try again`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }

    }

    if (loading) {
        return (
            <>
               <Loader />
            </>
        )

    } else {

        return (
            <>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />
                <section className='mobile-version user-login-mobile'>

                    <div className="user-mobile-login-form" >
                        <div className="container-custom pt-3">
                            <h4 className='fs-6'>Energises Practice Trade Now</h4>
                        </div>
                        <div style={{ borderRadius: 2, background: "#000", }} className='d-none ' ref={tradeTimeRef}>
                            <FlipClockCountdown
                                to={tradeTime}
                                className="flip-clock"
                                renderMap={[false, true, true, true]}
                                labels={['HOURS', 'MINUTES', 'SECONDS']} >
                                <Completed data={results} CompletedTrade={setResults} />
                            </FlipClockCountdown >

                        </div>
                        <div className="container-custom py-3">
                            <div className="mobile-future-coin-items pb-2">
                                <div className='pb-2'>


                                    <div className="mobile-future-coin-names d-flex align-items-center justify-content-between">
                                        <div className="mobile-future-coin-name mobile-trade-coin-name">
                                            {
                                                <select name="currency" value={singleCryptoData?._id} onChange={handleSelect}>
                                                    <option selected>
                                                        Select Now
                                                    </option>
                                                    {cryptoData.map((data, idx) => {
                                                        return (
                                                            <option key={data?._id} value={data?._id}>
                                                                {data.name}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            }
                                        </div>

                                        <div className="mobile-future-coin-icon">
                                            {price}$
                                        </div>

                                    </div>
                                    <div className="d-flex justify-content-center py-4">
                    <MobileCandleChartPage
                      Symbol={pair}
                      CandleChartLoading={CandleChartLoading}
                    ></MobileCandleChartPage>
                  </div>
                                    <div className="user-mobile-login-form" >
                                        <div className="container-custom py-3">
                                            <form >

                                                <div className="mb-3">
                                                    <label htmlFor="exampleFormControlInput2" className="form-label d-block">Amount</label>

                                                    <input type="number" onChange={handleInputBlur} name='amount' className='w-100 d-block px-2' id='amount' placeholder='amount' required />
                                                </div>
                                                <div className="mb-3">


                                                    <div className='thradeSettingDataRadio thradeSettingDataRadio-mobile'>
                                                        {thradeSettingData.map((data) => {
                                                            return (
                                                                <>  <input type="radio" name="thradeSetting_id" id={data?._id} value={data?._id} key={data?._id} onChange={handleInputBlur} />
                                                                    <label htmlFor={data?._id} ><FontAwesomeIcon icon="fa-regular fa-clock" /> {data?.Time} {data?.Unit}</label></>
                                                            );
                                                        })
                                                        }
                                                    </div>
                                                </div>

                                                <div className='text-center'>
                                                    <div class="btn-group me-2 trade-btn-low" role="group" aria-label="Second group">
                                                        <button type="button" ref={refSubmitDisLow} onClick={() => handleSubmitData('Low')} className="btn ">Low</button>
                                                    </div>
                                                    <div class="btn-group trade-btn-high" role="group" aria-label="Third group">
                                                        <button type="button" ref={refSubmitDisHigh} onClick={() => handleSubmitData('High')} className="btn ">High</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                    <MobilePracticeTradeHistory results={results}></MobilePracticeTradeHistory>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <MobileMenu />
            </>
        );

    }


};

export default MobileEnergisesPracticeTrade;