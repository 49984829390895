import React, { useRef } from "react";
import "./MobileTradeSideBar.css";
import Offcanvas from "react-bootstrap/Offcanvas";
import Crypto from "./Crypto/Crypto";
import Energises from "./Energises/Energises";
import Forex from "./Forex/Forex";
import Indices from "./Indices/Indices";
import Metal from "./Metal/Metal";
import Stock from "./Stock/Stock";

const MobileTradeSideBar = ({handleClose}) => {
    const CryptoShowRef = useRef();
    const MetalShowRef = useRef();
    const EnergisesShowRef = useRef();
    const StockShowRef = useRef();
    const IndicesShowRef = useRef();
    const ForexShowRef = useRef();

    const CryptoActiveClassRef = useRef();
    const MetalActiveClassRef = useRef();
    const EnergisesActiveClassRef = useRef();
    const StockActiveClassRef = useRef();
    const IndicesActiveClassRef = useRef();
    const ForexActiveClassRef = useRef();

    const MarketShowHandle = (data) => {
        if (data === "Crypto") {
            CryptoShowRef.current.style.display = "block";
            CryptoActiveClassRef.current.classList.add("active");
    
            MetalShowRef.current.style.display = "none";
            MetalActiveClassRef.current.classList.remove("active");

        
            EnergisesShowRef.current.style.display = "none";
            EnergisesActiveClassRef.current.classList.remove("active");

        
            StockShowRef.current.style.display = "none";
            StockActiveClassRef.current.classList.remove("active");

        
            IndicesShowRef.current.style.display = "none";
            IndicesActiveClassRef.current.classList.remove("active");

        
            ForexShowRef.current.style.display = "none";
            ForexActiveClassRef.current.classList.remove("active");

        
        }
      
        if (data === "Metal") {
            CryptoShowRef.current.style.display = "none";
            CryptoActiveClassRef.current.classList.remove("active");
    
            MetalShowRef.current.style.display = "block";
            MetalActiveClassRef.current.classList.add("active");

        
            EnergisesShowRef.current.style.display = "none";
            EnergisesActiveClassRef.current.classList.remove("active");

        
            StockShowRef.current.style.display = "none";
            StockActiveClassRef.current.classList.remove("active");

        
            IndicesShowRef.current.style.display = "none";
            IndicesActiveClassRef.current.classList.remove("active");

        
            ForexShowRef.current.style.display = "none";
            ForexActiveClassRef.current.classList.remove("active");

        
        }
      
        if (data === "Energises") {
            CryptoShowRef.current.style.display = "none";
            CryptoActiveClassRef.current.classList.remove("active");
    
            MetalShowRef.current.style.display = "none";
            MetalActiveClassRef.current.classList.remove("active");

        
            EnergisesShowRef.current.style.display = "block";
            EnergisesActiveClassRef.current.classList.add("active");

        
            StockShowRef.current.style.display = "none";
            StockActiveClassRef.current.classList.remove("active");

        
            IndicesShowRef.current.style.display = "none";
            IndicesActiveClassRef.current.classList.remove("active");

        
            ForexShowRef.current.style.display = "none";
            ForexActiveClassRef.current.classList.remove("active");

        
        }
      
        if (data === "Stock") {
            CryptoShowRef.current.style.display = "none";
            CryptoActiveClassRef.current.classList.remove("active");
    
            MetalShowRef.current.style.display = "none";
            MetalActiveClassRef.current.classList.remove("active");

        
            EnergisesShowRef.current.style.display = "none";
            EnergisesActiveClassRef.current.classList.remove("active");

        
            StockShowRef.current.style.display = "block";
            StockActiveClassRef.current.classList.add("active");

        
            IndicesShowRef.current.style.display = "none";
            IndicesActiveClassRef.current.classList.remove("active");

        
            ForexShowRef.current.style.display = "none";
            ForexActiveClassRef.current.classList.remove("active");

        
        }
      
        if (data === "Indices") {
            CryptoShowRef.current.style.display = "none";
            CryptoActiveClassRef.current.classList.remove("active");
    
            MetalShowRef.current.style.display = "none";
            MetalActiveClassRef.current.classList.remove("active");

        
            EnergisesShowRef.current.style.display = "none";
            EnergisesActiveClassRef.current.classList.remove("active");

        
            StockShowRef.current.style.display = "none";
            StockActiveClassRef.current.classList.remove("active");

        
            IndicesShowRef.current.style.display = "block";
            IndicesActiveClassRef.current.classList.add("active");

        
            ForexShowRef.current.style.display = "none";
            ForexActiveClassRef.current.classList.remove("active");

        
        }
      
        if (data === "Forex") {
            CryptoShowRef.current.style.display = "none";
            CryptoActiveClassRef.current.classList.remove("active");
    
            MetalShowRef.current.style.display = "none";
            MetalActiveClassRef.current.classList.remove("active");

        
            EnergisesShowRef.current.style.display = "none";
            EnergisesActiveClassRef.current.classList.remove("active");

        
            StockShowRef.current.style.display = "none";
            StockActiveClassRef.current.classList.remove("active");

        
            IndicesShowRef.current.style.display = "none";
            IndicesActiveClassRef.current.classList.remove("active");

        
            ForexShowRef.current.style.display = "block";
            ForexActiveClassRef.current.classList.add("active");

        
        }
      
      };
 
      

  return (
    <>
      <Offcanvas.Header>
        <Offcanvas.Title>
        <ul className="nav nav-pills" id="pills-tab" role="tablist">
            <li className="nav-item" >
              <button
                className="nav-link active"
                onClick={()=>MarketShowHandle('Crypto')}
                ref={CryptoActiveClassRef}
              >
                Crypto
              </button> 
            </li>
            <li className="nav-item" >
              <button
                className="nav-link"
                onClick={()=>MarketShowHandle('Metal')}
                ref={MetalActiveClassRef}
              >
                Metal
              </button>
            </li>
           
            <li className="nav-item" >
              <button
                className="nav-link"
                onClick={()=>MarketShowHandle('Energises')}
                ref={EnergisesActiveClassRef}
              >
                Energises
              </button>
            </li>
            <li className="nav-item" >
              <button
                className="nav-link"
                onClick={()=>MarketShowHandle('Stock')}
                ref={StockActiveClassRef}
              >
                Stock
              </button>
            </li>
           
            <li className="nav-item" >
              <button
                className="nav-link"
                onClick={()=>MarketShowHandle('Indices')}
                ref={IndicesActiveClassRef}
              >
                Indices
              </button>
            </li>
            <li className="nav-item" >
              <button
                className="nav-link"
                onClick={()=>MarketShowHandle('Forex')}
                ref={ForexActiveClassRef}
              >
                Forex
              </button>
            </li>
           
          </ul>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="coin_canvas_menu" ref={CryptoShowRef}>
          <Crypto handleClose={handleClose} ></Crypto>
        </div>


        <div className="coin_canvas_menu" ref={MetalShowRef} style={{display:"none"}}>
          <Metal  handleClose={handleClose}></Metal>
        </div>

        <div className="coin_canvas_menu" ref={EnergisesShowRef} style={{display:"none"}}>
          <Energises handleClose={handleClose}></Energises>
        </div>

        <div className="coin_canvas_menu" ref={StockShowRef} style={{display:"none"}}>
          <Stock handleClose={handleClose}></Stock>
        </div>

        <div className="coin_canvas_menu" ref={IndicesShowRef} style={{display:"none"}}>
          <Indices handleClose={handleClose}></Indices>
        </div>

        <div className="coin_canvas_menu" ref={ForexShowRef} style={{display:"none"}}>
          <Forex handleClose={handleClose}></Forex>
        </div>

      </Offcanvas.Body>
    </>
  );
};




export default MobileTradeSideBar;
