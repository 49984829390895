import React from 'react';
import dateFormat from 'dateformat';
const SingleItem = ({ data }) => {
    return (
        <>
            <tr>
                <td>{data?.NetworkType}</td>
                <td>
                    {dateFormat(data?.createdAt, "mmmm,")}&nbsp;{dateFormat(data?.createdAt, "dS")}&nbsp;{dateFormat(data?.createdAt, "yyyy")}
                </td>
                <td>
                    {data?.Amount}$
                </td>
                <td>{data?.Transaction}</td>
                <td>
                    {data.Status === 0 ? <span className="badge bg-warning ">pending</span> : ''}
                    {data.Status === 1 ? <span className="badge bg-success ">accept</span> : ''}
                    {data.Status === 2 ? <span className="badge bg-danger ">rejected</span> : ''}</td>
            </tr>

        </>
    );
};

export default SingleItem;