import React, { useRef, useState } from 'react';
import ChatPage from '../../../LiveChatsPage/ChatPage/ChatPage';

const SupportIcon = () => {


  const [chatPage, setChatPage] = useState(false);
  const ChatPageShowRef = useRef();

  const ChatPageShow = () => {
    ChatPageShowRef.current.style.display = "block";
    setChatPage(true);
  }
  const ChatPageHide = () => {
    ChatPageShowRef.current.style.display = "none";
    setChatPage(false);
  }

  return (
    <>
      <div className="support-btn-area" >
        <button onClick={ChatPageShow} title='Live Chat'>
          <i className="fa-solid fa-headset support-btn-show"></i>
        </button>
      </div>

      <div style={{ display: "none" }} ref={ChatPageShowRef}>
        {(() => {
          if (chatPage) {
            return (
              <ChatPage ChatPageHide={ChatPageHide}></ChatPage>
            )
          }
        })()}

      </div>
    </>
  );
};

export default SupportIcon;