import React from 'react'
import DesktopHome from './DesktopHome';

export default function Home() {
  
 
  return (
    <>
    <DesktopHome></DesktopHome>
    </>
  );


}
