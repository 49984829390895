import React, {  useRef, useState } from 'react';
import DashBoardSideBar from '../UserDashBoard/DashBoardSideBar';
import CryptoPage from './CryptoPage/CryptoPage';
import ForexPage from './ForexPage/ForexPage';
import MetalsPage from './MetalsPage/MetalsPage';
import EnergisesPage from './EnergisesPage/EnergisesPage';
import IndicesPage from './IndicesPage/IndicesPage';
import StocksPage from './StocksPage/StocksPage';


const Trade = () => {

    const [CryptoShow, setCryptoShow] = useState(true);
    const [MetalsShow, setMetalsShow] = useState(false);
    const [EnergisesShow, setEnergisesshow] = useState(false);
    const [IndicesShow, setIndicesShow] = useState(false);
    const [StocksShow, setStocksShow] = useState(false);
    const [ForexShow, setForexhow] = useState(false);


    const CryptoActiveClass = useRef();
    const MetalsActiveClass = useRef();
    const EnergisesActiveClass = useRef();
    const IndicesActiveClass = useRef();
    const StocksActiveClass = useRef();
    const ForexActiveClass = useRef();

    const TradeShow = (data) => {
        if (data === "Crypto") {
            setCryptoShow(true)
            setMetalsShow(false)
            setEnergisesshow(false)
            setIndicesShow(false)
            setStocksShow(false)
            setForexhow(false)
            CryptoActiveClass.current.classList.add("active");
            MetalsActiveClass.current.classList.remove("active");
            EnergisesActiveClass.current.classList.remove("active");
            IndicesActiveClass.current.classList.remove("active");
            StocksActiveClass.current.classList.remove("active");
            ForexActiveClass.current.classList.remove("active");

        }

        if (data === "Metals") {
            setCryptoShow(false)
            setMetalsShow(true)
            setEnergisesshow(false)
            setIndicesShow(false)
            setStocksShow(false)
            setForexhow(false)
            CryptoActiveClass.current.classList.remove("active");
            MetalsActiveClass.current.classList.add("active");
            EnergisesActiveClass.current.classList.remove("active");
            IndicesActiveClass.current.classList.remove("active");
            StocksActiveClass.current.classList.remove("active");
            ForexActiveClass.current.classList.remove("active");


        }
        if (data === "Energises") {
            setCryptoShow(false)
            setMetalsShow(false)
            setEnergisesshow(true)
            setIndicesShow(false)
            setStocksShow(false)
            setForexhow(false)
            CryptoActiveClass.current.classList.remove("active");
            MetalsActiveClass.current.classList.remove("active");
            EnergisesActiveClass.current.classList.add("active");
            IndicesActiveClass.current.classList.remove("active");
            StocksActiveClass.current.classList.remove("active");
            ForexActiveClass.current.classList.remove("active");


        }
        if (data === "Indices") {
            setCryptoShow(false)
            setMetalsShow(false)
            setEnergisesshow(false)
            setIndicesShow(true)
            setStocksShow(false)
            setForexhow(false)
            CryptoActiveClass.current.classList.remove("active");
            MetalsActiveClass.current.classList.remove("active");
            EnergisesActiveClass.current.classList.remove("active");
            IndicesActiveClass.current.classList.add("active");
            StocksActiveClass.current.classList.remove("active");
            ForexActiveClass.current.classList.remove("active");


        }
        if (data === "Stocks") {
            setCryptoShow(false)
            setMetalsShow(false)
            setEnergisesshow(false)
            setIndicesShow(false)
            setStocksShow(true)
            setForexhow(false)
            CryptoActiveClass.current.classList.remove("active");
            MetalsActiveClass.current.classList.remove("active");
            EnergisesActiveClass.current.classList.remove("active");
            IndicesActiveClass.current.classList.remove("active");
            StocksActiveClass.current.classList.add("active");
            ForexActiveClass.current.classList.remove("active");


        }
        if (data === "Forex") {
            setCryptoShow(false)
            setMetalsShow(false)
            setEnergisesshow(false)
            setIndicesShow(false)
            setStocksShow(false)
            setForexhow(true)
            CryptoActiveClass.current.classList.remove("active");
            MetalsActiveClass.current.classList.remove("active");
            EnergisesActiveClass.current.classList.remove("active");
            IndicesActiveClass.current.classList.remove("active");
            StocksActiveClass.current.classList.remove("active");
            ForexActiveClass.current.classList.add("active");


        }

    }

    return (
        <>
            <section className='user-dashboard'>
                <div className="row">
                    <div className="col-3 user-menu-left">
                        <DashBoardSideBar></DashBoardSideBar>
                    </div>
                    <div className="col-9">
                        <div className="user-transaction-history-titile">
                            <h3 className='active'  ref={CryptoActiveClass} onClick={() => TradeShow('Crypto')}>Crypto</h3>
                            <h3 ref={MetalsActiveClass} onClick={() => TradeShow('Metals')}>Metals</h3>
                            <h3 ref={EnergisesActiveClass} onClick={() => TradeShow('Energises')}>Energises(CFDs)</h3>
                            <h3 ref={IndicesActiveClass} onClick={() => TradeShow('Indices')}>Indices(CFDs)</h3>
                            <h3 ref={StocksActiveClass} onClick={() => TradeShow('Stocks')}>Stocks(CFDs)</h3>
                            <h3 ref={ForexActiveClass} onClick={() => TradeShow('Forex')}>Forex</h3>
                        </div>
                        <section className='user-Crypto'>
                            {(() => {
                                if (CryptoShow) {
                                    return (
                                        <CryptoPage></CryptoPage>
                                    )
                                }
                            })()}

                        </section>


                        
                        <section className='user-Crypto'>
                            {(() => {
                                if (MetalsShow) {
                                    return (
                                        <MetalsPage></MetalsPage>
                                    )
                                }
                            })()}

                        </section>


                        <section className='user-Crypto'>
                            {(() => {
                                if (EnergisesShow) {
                                    return (
                                        <EnergisesPage></EnergisesPage>
                                    )
                                }
                            })()}

                        </section>

                        <section className='user-Crypto'>
                            {(() => {
                                if (IndicesShow) {
                                    return (
                                        <IndicesPage></IndicesPage>
                                    )
                                }
                            })()}

                        </section>


                        <section className='user-Crypto'>
                            {(() => {
                                if (StocksShow) {
                                    return (
                                        <StocksPage></StocksPage>
                                    )
                                }
                            })()}

                        </section>

                      
                        <section className='user-Crypto'>
                            {(() => {
                                if (ForexShow) {
                                    return (
                                        <ForexPage></ForexPage>
                                    )
                                }
                            })()}

                        </section>

                    </div>
                </div>

            </section>
        </>
    );

};

export default Trade;