import React, { useContext } from "react";
import "./MobileAssets.css";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../../Contexts/AuthContext/AuthProvider";
import SolanaImage from "./solona.png";
const MobileAssets = () => {
  const { authUser } = useContext(AuthContext);

  return (
    <>
      <section className="assets-area">
        <div className="mobile-view d-block">
          <div className="container-fluid pt-3">
            <section class="mobile-contact-section">
              <div class="card mb-3">
                <div class="card-content">
                  <div className="asset_card">
                    <div className="text-gray-400 mt-1 pb-2">
                      <h4 className="">
                        Total Balance: <br /> ${authUser?.balance}
                      </h4>
                    </div>
                    <div className="d-flex gap-2 mt-2">
                      <Link to="/user/assets/deposit" className="depositBtn">
                        Depsit
                      </Link>
                      <Link to="/user/assets/withdraw" className="withdrowBtn">
                        Withdraw
                      </Link>
                      <Link to="#" className="withdrowBtn bg-primary">
                        Exchange
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <div
              style={{ marginBottom: "30px" }}
              className="card mt-3 p-3 bg-blue"
            >
              <div className="d-flex align-items-center text-center gap-3">
                <div className="flex-grow-1">
                  <div className="text-xm opacity-70"> Your Coin Assets </div>
                  <div className="mt-0"></div>
                </div>
              </div>

              <div className="d-flex gap-2 flex-column my-3" id="coinContainer">
                <div className="bg-dark hot-coin">
                  <div className="d-flex align-items-center gap-2">
                    <div className="mr-2">
                      <img
                        src="https://cdn.coinranking.com/bOabBYkcX/bitcoin_btc.svg"
                        alt=""
                        className="hot-coin-img"
                      />
                    </div>
                    <div className="flex-grow-1">
                      <div className="text-white font-light">
                        <span className="font-medium text-lg">BTC</span>
                      </div>
                      <div className="text-base font-light text-up">$0</div>
                      <div className="text-warning font-light text-up">0</div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center rounded-full align-items-center text-sm text-white gap-3 trend-percent">
                    0%
                    <img src="" alt="" className="trend-img" />
                  </div>
                </div>

                <div className="bg-dark hot-coin">
                  <div className="d-flex align-items-center gap-2">
                    <div className="mr-2">
                      <img
                        src="https://cdn.coinranking.com/rk4RKHOuW/eth.svg"
                        className="hot-coin-img"
                      />
                    </div>
                    <div className="flex-grow-1">
                      <div className="text-white font-light">
                        <span className="font-medium text-lg">ETH</span>
                      </div>
                      <div className="text-base font-light text-up">$0</div>
                      <div className="text-warning font-light text-up">
                        0.0000
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center rounded-full align-items-center text-sm text-white gap-3 trend-percent">
                    1.65%
                    <img src="" alt="" className="trend-img" />
                  </div>
                </div>

                <div className="bg-dark hot-coin">
                  <div className="d-flex align-items-center gap-2">
                    <div className="mr-2">
                      <img
                        src="https://cdn.coinranking.com/mgHqwlCLj/usdt.svg"
                        className="hot-coin-img"
                      />
                    </div>
                    <div className="flex-grow-1">
                      <div className="text-white font-light">
                        <span className="font-medium text-lg">USDT</span>
                      </div>
                      <div className="text-base font-light text-up">$0</div>
                      <div className="text-warning font-light text-up">0</div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center rounded-full align-items-center text-sm text-white gap-3 trend-percent">
                    0%
                    <img src="" alt="" className="trend-img" />
                  </div>
                </div>
                <div className="bg-dark hot-coin">
                  <div className="d-flex align-items-center gap-2">
                    <div className="mr-2">
                      <img
                        src={SolanaImage}
                        className="hot-coin-img"
                      />
                    </div>
                    <div className="flex-grow-1">
                      <div className="text-white font-light">
                        <span className="font-medium text-lg">Solana</span>
                      </div>
                      <div className="text-base font-light text-up">$0</div>
                      <div className="text-warning font-light text-up">0</div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center rounded-full align-items-center text-sm text-white gap-3 trend-percent">
                    0%
                    <img src="" alt="" className="trend-img" />
                  </div>
                </div>
              </div>

              {/* <!--=====Earnings History =====--> */}
              <div className="card mt-3 p-3 bg-dark">
                <div className="d-flex align-items-center text-center gap-3">
                  <div className="flex-grow-1">
                    <div className="text-xm opacity-70">
                      {" "}
                      Your New Coin Assets{" "}
                    </div>
                    <div className="mt-0"></div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <br />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MobileAssets;
