import React from "react";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import logo from "./../../../logo.jpg";
const Footer = () => {

  return (
    <>
      <footer
        className="text-center text-lg-start text-white footer"
        style={{ backgroundColor: "#014670" }}
      >
        {/* <!-- Section: Links  --> */}
        <section className="">
          <div className="container text-center text-md-start pt-5">
            {/* <!-- Grid row --> */}
            <div className="row mt-3">
              {/* <!-- Grid column --> */}
              <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                {/* <!-- Content --> */}
                <h6 className="text-uppercase fw-bold">
                  <LazyLoadImage
                    alt="img"
                    effect="blur"
                    src={logo}
                  />
                </h6>
                <hr
                  className="mb-4 mt-0 d-inline-block mx-auto"
                  style={{
                    width: "60px",
                    backgroundColor: "#7c4dff",
                    height: "2px",
                  }}
                />
                <p>
                  Prospect Capital Corporation is a leading publicly-traded
                  Business Development Company (“BDC”). We make debt and equity
                  investments in U.S. middle market businesses across a range of
                  industries, and seek to deliver steady, attractive returns to
                  our shareholders.
                  <p>We trade on NASDAQ under the ticker symbol “PSEC”.</p>
                </p>
              </div>
              {/* <!-- Grid column --> */}

              {/* <!-- Grid column --> */}
              <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4 footer-menu-link">
                {/* <!-- Links --> */}
                <h6 className="text-uppercase fw-bold"></h6>
                <hr
                  className="mb-4 mt-0 d-inline-block mx-auto"
                  style={{
                    width: "60px",
                    backgroundColor: "#7c4dff",
                    height: "2px",
                  }}
                />
                <p className="desktop-footer-menu">
                  <Link to="/trade" className="text-white">
                    Trade
                  </Link>
                </p>
                <p className="mobile-footer-menu">
                  <Link to="/user/trade" className="text-white">
                    Trade
                  </Link>
                </p>
                <p className="desktop-footer-menu">
                  <Link to="/apply/loan" className="text-white">
                    Loan
                  </Link>
                </p>
                <p className="mobile-footer-menu">
                  <Link to="/user/apply/loan" className="text-white">
                    Loan
                  </Link>
                </p>
                <p className="desktop-footer-menu">
                  <Link to="/bitcoin/mining" className="text-white">
                    Mining
                  </Link>
                </p>
                <p className="mobile-footer-menu">
                  <Link to="/user/bitcoin/mining" className="text-white">
                    Mining
                  </Link>
                </p>
                <p className="desktop-footer-menu">
                  <Link to="/login" className="text-white">
                    Login/Signup
                  </Link>
                </p>
                <p className="mobile-footer-menu">
                  <Link to="/user/login" className="text-white">
                  Login/Signup
                  </Link>
                </p>
                <p>
                  <Link to="/platform/apps" className="text-white">
                    Platform Apps
                  </Link>
                </p>
                <p>
                  <Link to="/announcement" className="text-white">
                    Announcement
                  </Link>
                </p>
              </div>
              {/* <!-- Grid column --> */}

              {/* <!-- Grid column --> */}
              <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4 footer-menu-link">
                {/* <!-- Links --> */}
                <h6 className="text-uppercase fw-bold"></h6>
                <hr
                  className="mb-4 mt-0 d-inline-block mx-auto"
                  style={{
                    width: "60px",
                    backgroundColor: "#7c4dff",
                    height: "2px",
                  }}
                />
                <p>
                  <Link to="/about/us" className="text-white">
                    About Us
                  </Link>
                </p>
              
                <p>
                  <Link to="/privacy/policy" className="text-white">
                    Privacy Policy
                  </Link>
                </p>
                <p>
                  <Link to="/disclaimer" className="text-white">
                    Disclaimer
                  </Link>
                </p>
                <p>
                  <Link to="/blockchain" className="text-white">
                    Blockchain
                  </Link>
                </p>
                <p>
                  <Link to="/invest/plan" className="text-white desktop-invest-plan">
                    Invest Plan
                  </Link>
                  <Link to="/investment/plan" className="text-white mobile-invest-plan">
                    Invest Plan
                  </Link>
                </p>
              </div>
              {/* <!-- Grid column --> */}

              {/* <!-- Grid column --> */}
              <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4 footer-menu-contact-area">
                {/* <!-- Links --> */}
                <h6 className="text-uppercase fw-bold">Contact</h6>
                <hr
                  className="mb-4 mt-0 d-inline-block mx-auto"
                  style={{
                    width: "60px",
                    backgroundColor: "#7c4dff",
                    height: "2px",
                  }}
                />
                <p>
                  <i className="fas fa-home mr-3"></i> 10 East 40th Street, 42ed
                  Floor, New York, NY 10016
                </p>
                <p>
                  <i className="fas fa-envelope mr-3"></i> prospectcapitalx@gmail.com
                </p>
             
                <p>
                  Social networks:
                  <a
                    href="##"
                    className="text-white mx-4"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a
                    href="##"
                    className="text-white me-4"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-linkedin"></i>
                  </a>
                  <a
                    href="https://t.me/infopcxofficial"
                    className="text-white me-4"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa-brands fa-telegram"></i>
                  </a>
                </p>
              </div>
              {/* <!-- Grid column --> */}
            </div>
            {/* <!-- Grid row --> */}
          </div>
        </section>
        {/* <!-- Section: Links  --> */}

        {/* <!-- Copyright --> */}
        <div className="text-center p-3" style={{ backgroundColor: "#0075bb" }}>
          © 2020 Copyright:
          <Link className="text-white" to="/">
            pcc, All rights
          </Link>
        </div>
        {/* <!-- Copyright --> */}
      </footer>

      {/* <SupportIcon></SupportIcon> */}
    </>
  );
};

export default Footer;
