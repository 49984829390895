import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

const Completed = ({ data, CompletedTrade }) => {
    const handleClose = () => setShow(false);
    const [show, setShow] = useState(false);
    const [results, setResults] = useState([])
    const [Status, setStatus] = useState({status:true})

    const CloseModal = () => {
        setResults([]);
        setShow(false);
        setStatus(false);
    }
   
    useEffect(() => {
        if(results?._id){
            if(results?.Result !== null){
                if(show === true){
                    return false;
                }else{
                    CompletedTrade(results);
                    return setShow(true);
                }
               
            }
        }
        if(Status){
            const interval = setInterval(() => {
                fetchData();
            }, 1000)
            return () => clearInterval(interval);
        }
       
    }, [results])


    const fetchData = async () => {
        if (data?._id) {
            const apiData = await fetch(`https://api2.prospectstreetx.com/api/user/Practice/trade/log/single/view/${data?._id}`);
            const apiResponse = await apiData.json();
            setResults(apiResponse?.data);
            
        }
    }

    if (results) {
        return (
            <>
                <section className='trade-modal'>
                    <Modal show={show} onHide={handleClose} >

                        <Modal.Body>

                            <div className="text-center py-5">
                                <i className="fa-solid fa-circle-exclamation display-2 py-3"></i>
                                <h4>
                                    {results?.Result === "Loss" ? "$0.00" : ''}
                                    {results?.Result === "Win" ? `$${results?.Result_Amount}` : ''}
                                    {results?.Result === "Draw" ? `$${results?.Result_Amount}` : ''}  {results?.Result}

                                </h4>
                                <p>Message: Trade</p>
                                <p>Selected Period: {results?.Time}s</p>
                                <p>Price: {results?.Crypto_price}</p>
                                <p>Direction: {results?.HighLow}</p>
                                <p>Amount: ${results?.Amount}</p>
                                <Button onClick={CloseModal} className='mt-5 '>
                                    Close
                                </Button>
                            </div>

                        </Modal.Body>

                    </Modal>
                </section>

            </>
        );
    }




};

export default Completed;