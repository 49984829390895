import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import logo from "./../../../logo.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";
const ForgetPassword = () => {
  const [userData, setUserData] = useState({});
  const [user, setUserValue] = useState({});
  const [errorMessage, setErrorMessage] = useState({});
  const refSubmitDis = useRef();

  const handleSubmit = (event) => {
    refSubmitDis.current.setAttribute("disabled", true);
    event.preventDefault();
    fetch("https://api2.prospectstreetx.com/api/user/auth/passowrd/forget", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(user),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success === false) {
          setErrorMessage(data);
        } else {
          setUserData(data);
          event.target.reset();
        }
        refSubmitDis.current.removeAttribute("disabled");
      })
      .catch((error) => console.log(error));
  };

  const handleInputBlur = (event) => {
    setErrorMessage({});
    const value = event.target.value;
    const field = event.target.name;
    const newUser = { ...user };
    // console.log(newUser);
    newUser[field] = value;
    setUserValue(newUser);
  };

  return (
    <>
      <section className="body-section">
        <section className="login-section">
          <div className="login-area">
            <div className="login-card">
              <div className="login-img">
                <Link to="/">
                  <LazyLoadImage alt="img" effect="blur" src={logo} />
                </Link>
              </div>
              {userData?.success === true ? (
                <span className="text-success text-center ms-2 pb-4  fw-bolder d-block">
                  {userData.message}
                </span>
              ) : (
                ""
              )}
              <div className="login-form-area">
                <form onSubmit={handleSubmit}>
                  <div className="input-group">
                    <label htmlFor="email">email</label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      onBlur={handleInputBlur}
                      placeholder="Email address"
                    />
                    {errorMessage?.success === false ? (
                      <span className="text-danger text-start ms-2 py-1">
                        {errorMessage.message}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="submit-btn-area">
                    <button
                      type="submit"
                      ref={refSubmitDis}
                      className="button-1 submit-btn"
                    >
                      Forgot
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <Link to="/" className="home-btn">
            Home
          </Link>
        </section>
      </section>
    </>
  );
};

export default ForgetPassword;
