import React from 'react';
import { Link } from 'react-router-dom';
import dateFormat from "dateformat";
const ListSingleItems = ({data}) => {
    return (
        <>
              <div className="row news-lists-item">
                  <div className="col-3">
                    <div className="news-time">
                      <span> {dateFormat(data.createdAt, "d/m/yyyy")} </span>
                      <span>{dateFormat(data.createdAt, " h:MM TT")} </span>
                    </div>
                  </div>
                  <div className="col-9">
                    <div className="tag">
                      <span>{data?.category}</span>
                    </div>
                    <div className="news-title">
                      <h5>
                        <Link to={`/news/details/${data?._id}`}>
                         {data?.title}
                        </Link>
                      </h5>
                    </div>

                    <div className="summary">
                      <span><i class="fa-solid fa-square-plus"></i> SUMMARY</span>
                    </div>

                    {/* <div className="summary-detials">
                      <p>
                       {data?.dis}
                      </p>
                      <Link to={`/news/details/${data?._id}`}> Continue Reading</Link>
                    </div> */}
                  </div>
                </div>
        </>
    );
};

export default ListSingleItems;