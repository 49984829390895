import React from 'react';
import "./Disclaimer.css"

const Disclaimer = () => {
    return (
        <>

<section className="disclaimer-area">

<div className="disclaimer-header-area">

    <div className="disclaimer-header">
        <img src="https://images.unsplash.com/photo-1505410603994-c3ac6269711f?ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80"
            alt="" />
        <div className="disclaimer-header-overlay">
            <h2>Disclaimer</h2>
            <p>
                The cryptocurrency market is deeply speculative, with various risks including sudden market
                fluctuations, potential manipulation, and technical issues. PCTC offers a free online trading
                platform for cryptocurrency enthusiasts, but does not take responsibility for the investment
                value of cryptocurrencies, nor does it guarantee any returns.

            </p>
        </div>
    </div>
</div>

<div className="disclaimer-main-section">
    <div className="container">
        <h5>PCTC and its users agree to the following terms:</h5>
        <div className="disclaimer-lists">
            <ol>
                <li>
                    <p>Before engaging in trading, users must thoroughly understand the nature of the market and
                        assess their own level of risk tolerance and financial resources.
                    </p>
                </li>
                <li>
                    <p>Digital asset transactions carry significant risk and users should be prepared to
                        potentially lose their entire investment.
                    </p>
                </li>
                <li>
                    <p>PCTC does not guarantee the accuracy or applicability of any market analysis and cannot
                        be held responsible for any information obtained from third parties. Users accept all
                        risks associated with their transactions.
                    </p>
                </li>

                <li>
                    <p>
                        PCTC may provide user data or take measures in response to legal requirements, and is
                        not responsible for any resulting privacy disclosures or operational issues.

                    </p>
                </li>
                <li>
                    <p>
                        Users are responsible for any financial losses resulting from the suspension or
                        prohibition of digital asset transactions due to changes in laws or regulations.

                    </p>
                </li>
                <li>
                    <p>
                        PCTC retains the ultimate interpretation rights of the "PCTC Service Agreement".

                    </p>
                </li>
            </ol>
        </div>
    </div>

</div>

</section>


        </>
    );
};

export default Disclaimer;