import React, {  useState } from 'react';
import { ToastContainer } from 'react-toastify';
import MobilePracticeTrade from './MobilePracticeTrade';
import MobileMetalsPracticeTrade from './MobileMetalsPracticeTrade/MobileMetalsPracticeTrade';
import MobileEnergisesPracticeTrade from './MobileEnergisesPracticeTrade/MobileEnergisesPracticeTrade';
import MobileIndicesPracticeTrade from './MobileIndicesPracticeTrade/MobileIndicesPracticeTrade';
import MobileStocksPracticeTrade from './MobileStocksPracticeTrade/MobileStocksPracticeTrade';
import MobileForexPracticeTrade from './MobileForexPracticeTrade/MobileForexPracticeTrade';


const MobilePracticeTradePage = () => {

    const [CryptoShow, setCryptoShow] = useState(true);
    const [MetalsShow, setMetalsShow] = useState(false);
    const [EnergisesShow, setEnergisesshow] = useState(false);
    const [IndicesShow, setIndicesShow] = useState(false);
    const [StocksShow, setStocksShow] = useState(false);
    const [ForexShow, setForexhow] = useState(false);



    const handleTradeItems = (e) => {
        const data = e.target.value;
        if (data === "Crypto") {
            setCryptoShow(true)
            setMetalsShow(false)
            setEnergisesshow(false)
            setIndicesShow(false)
            setStocksShow(false)
            setForexhow(false)


        }

        if (data === "Metals") {
            setCryptoShow(false)
            setMetalsShow(true)
            setEnergisesshow(false)
            setIndicesShow(false)
            setStocksShow(false)
            setForexhow(false)



        }
        if (data === "Energises") {
            setCryptoShow(false)
            setMetalsShow(false)
            setEnergisesshow(true)
            setIndicesShow(false)
            setStocksShow(false)
            setForexhow(false)

        }
        if (data === "Indices") {
            setCryptoShow(false)
            setMetalsShow(false)
            setEnergisesshow(false)
            setIndicesShow(true)
            setStocksShow(false)
            setForexhow(false)


        }
        if (data === "Stocks") {
            setCryptoShow(false)
            setMetalsShow(false)
            setEnergisesshow(false)
            setIndicesShow(false)
            setStocksShow(true)
            setForexhow(false)


        }
        if (data === "Forex") {
            setCryptoShow(false)
            setMetalsShow(false)
            setEnergisesshow(false)
            setIndicesShow(false)
            setStocksShow(false)
            setForexhow(true)

        }


    }


    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />


            <section className=''>
                <div className="user-login-title">
                    <div className="container-custom">
                        <div className='pb-2'>
                            <h4>Practice Trade </h4>

                            <div className="Markets-Menu-section">
                                <div className="mobile-future-coin-names  w-100">
                                    <div className="mobile-future-coin-name mobile-trade-coin-name">
                                        {
                                            <select name="currency" className='w-100' onChange={handleTradeItems}>
                                                <option selected value='Crypto'>Cryptocurrency</option>
                                                <option value='Metals'> Metals</option>
                                                <option value='Energises'> Energises (CFDs)</option>
                                                <option value='Indices'> Indices (CFDs)</option>
                                                <option value='Stocks'> Stocks (CFDs)</option>
                                                <option value='Forex'> Forex</option>
                                            </select>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>


            <>
                {(() => {
                    if (CryptoShow) {
                        return (
                            <MobilePracticeTrade></MobilePracticeTrade>
                        )
                    }
                })()}


                {(() => {
                    if (MetalsShow) {
                        return (
                            <MobileMetalsPracticeTrade></MobileMetalsPracticeTrade>
                        )
                    }
                })()}

                {(() => {
                    if (EnergisesShow) {
                        return (
                            <MobileEnergisesPracticeTrade></MobileEnergisesPracticeTrade>
                        )
                    }
                })()}

                {(() => {
                    if (IndicesShow) {
                        return (
                            <MobileIndicesPracticeTrade></MobileIndicesPracticeTrade>
                        )
                    }
                })()}

                {(() => {
                    if (StocksShow) {
                        return (
                            <MobileStocksPracticeTrade></MobileStocksPracticeTrade>
                        )
                    }
                })()}
                {(() => {
                    if (ForexShow) {
                        return (
                            <MobileForexPracticeTrade></MobileForexPracticeTrade>
                        )
                    }
                })()}
            </>

        </>
    );

};
export default MobilePracticeTradePage;