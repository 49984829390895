import React, { useRef } from 'react';
import DepositHistory from './DepositHistory/DepositHistory';
import WithdrawalHistroy from './WithdrawalHistroy/WithdrawalHistroy';
import TransactionHistroy from './TransactionHistroy/TransactionHistroy';
import DashBoardSideBar from '../UserDashBoard/DashBoardSideBar';

const TransactionHistory = () => {

    const DepositShowRef = useRef();
    const WithdrawalRef = useRef();
    const TransactionShowRef = useRef();

    const DepositActiveClass = useRef();
    const WithdrawalActiveClass = useRef();
    const TransactionActiveClass = useRef();

    const HistoryShow = (data) => {
        if (data === "Deposit") {

            DepositShowRef.current.style.display = "block";
            WithdrawalRef.current.style.display = "none";
            TransactionShowRef.current.style.display = "none";

            DepositActiveClass.current.classList.add("active");
            WithdrawalActiveClass.current.classList.remove("active");
            TransactionActiveClass.current.classList.remove("active");

        }

        if (data === "Withdrawal") {

            WithdrawalRef.current.style.display = "block";
            DepositShowRef.current.style.display = "none";
            TransactionShowRef.current.style.display = "none";

            WithdrawalActiveClass.current.classList.add("active");
            DepositActiveClass.current.classList.remove("active");
            TransactionActiveClass.current.classList.remove("active");

        }
        if (data === "Transaction") {
            TransactionShowRef.current.style.display = "block";
            DepositShowRef.current.style.display = "none";
            WithdrawalRef.current.style.display = "none";

            TransactionActiveClass.current.classList.add("active");
            DepositActiveClass.current.classList.remove("active");
            WithdrawalActiveClass.current.classList.remove("active");

        }

    }


    return (
        <>

            <section className='user-dashboard'>
                <div className="row">
                    <div className="col-3 user-menu-left">
                        <DashBoardSideBar></DashBoardSideBar>
                    </div>
                    <div className="col-9">
                        <section className='container-custom'>

                            <div className="user-deposit-title pt-5">
                                <h1>Transaction History</h1>
                            </div>
                            <div className="user-referral-history-area ">
                                <div className="user-transaction-history-titile">
                                    <h3 className='active' ref={DepositActiveClass} onClick={() => HistoryShow('Deposit')}>Deposit</h3>
                                    <h3 ref={WithdrawalActiveClass} onClick={() => HistoryShow('Withdrawal')}>Withdrawal</h3>
                                    <h3 ref={TransactionActiveClass} onClick={() => HistoryShow('Transaction')}>Transaction</h3>

                                </div>
                                <div className="user-referral-history-items" ref={DepositShowRef}>
                                    <DepositHistory></DepositHistory>
                                </div>
                                <div className="user-referral-history-items" style={{ display: 'none' }} ref={WithdrawalRef}>
                                    <WithdrawalHistroy></WithdrawalHistroy>
                                </div>
                                <div className="user-referral-history-items" style={{ display: 'none' }} ref={TransactionShowRef}>
                                    <TransactionHistroy></TransactionHistroy>
                                </div>
                            </div>

                        </section>

                    </div>
                </div>

            </section>


        </>
    );
};

export default TransactionHistory;