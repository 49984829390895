import React from 'react';
import Profile from '../Profile/Profile';
import DashBoardSideBar from './DashBoardSideBar';

const UserDashBoard = () => {


    return (
        <>
            <section className='user-dashboard'>
                <div className="row">
                    <div className="col-3 user-menu-left">
                        <DashBoardSideBar></DashBoardSideBar>
                    </div>
                    <div className="col-9">
                        <Profile></Profile>
                        {/* <PlotChart></PlotChart> */}
                    </div>
                </div>

            </section>
        </>
    );
};

export default UserDashBoard;