import React, { useContext, useEffect,  useState } from "react";
import { AuthContext } from "../../../../../Contexts/AuthContext/AuthProvider";
import { useParams } from "react-router-dom";
import MobileTradingViewWidget from "../../../MobileTradingViewWidget/MobileTradingViewWidget";
import LongModal from "./Modal/LongModal/LongModal";
import ShortModal from "./Modal/ShortModal/ShortModal";
import { Offcanvas } from "react-bootstrap";
import MobileTradeSideBar from "../MobileTradeSideBar/MobileTradeSideBar";
import Loader from "../../../../Loader/Loader";

const MobileStocksTrade = () => {
    const { id, symbol } = useParams();
    const { authUser } = useContext(AuthContext);
  
    const [longmodalshow, setlongmodalshow] = useState(false);
    const [shortModalShow, setShortModalShow] = useState(false);
  
    const [marketData, setMarketData] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
      setLoading(true);
      if(id){
        fetch(
          `https://api2.prospectstreetx.com/api/admin/stocks/single/view/${id}`,
          {
            method: "GET",
          }
        )
          .then((res) => res.json())
          .then((data) => {
            setMarketData(data?.data);
            setLoading(false);
          });
      }
    }, [id]);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    
    if(loading){
      return (
        <>
          <Loader></Loader>
      </>
      );
    }else{

    }
    return (
      <>   
      <Offcanvas
        show={show}
        onHide={handleClose}
       
        className="mobile-trade-offcanvas-area"
      >
        <MobileTradeSideBar  handleClose={handleClose}></MobileTradeSideBar>
      </Offcanvas>
      
        <section className=" mobile-trade-section user-dashboard">
          <div className="row">
            <div className="col-12">
              <div className="trade-area-posce">
                <div className="charts">
                  <div
                    className="charts__head"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasLeft"
                    aria-controls="offcanvasLeft"
                  >
                    <div className="d-flex gap-3">
                      <div className="nav">
                        <img
                          src="https://cryptolly.netlify.app/assets/media/images/icons/menu.svg"
                          alt="MENU"
                          onClick={handleShow}
                        />
                      </div>
                    </div>
                    <div className="nav">
                      <button
                        className="nav__link js-tabs-link active"
                        style={{ color: "lime" }}
                      >
                        ${authUser?.balance}
                      </button>
                    </div>
                  </div>
                </div>
  
                <div className="trade-charts-area-posce">
                  <MobileTradingViewWidget symbol={symbol}></MobileTradingViewWidget>
                </div>
                <div className="d-flex gap-3">
                  <button
                    type="button"
                    className="btn btn-success  w-100 "
                    data-bs-toggle="modal"
                    style={{ borderRadius: "30px" }}
                    onClick={() => setlongmodalshow(true)}
                  >
                    Long
                  </button>
  
                  <button
                    type="button"
                    className="btn btn-danger  w-100"
                    data-bs-toggle="modal"
                    style={{ borderRadius: "30px" }}
                    onClick={() => setShortModalShow(true)}
                  >
                    Short
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
   
        <LongModal
          className="posce-trade-modal"
          marketData={marketData}
          setlongmodalshow={setlongmodalshow}
          longmodalshow={longmodalshow}
          symbol={symbol}
          id={id}
          show={longmodalshow}
          onHide={() => setlongmodalshow(false)}
        />
        <ShortModal
          className="posce-trade-modal "
          marketData={marketData}
          setShortModalShow={setShortModalShow}
          shortModalShow={shortModalShow}
          symbol={symbol}
          id={id}
          show={shortModalShow}
          onHide={() => setShortModalShow(false)}
        />
      </>
    );
};
export default MobileStocksTrade;