import React, { useEffect, useState } from "react";
import SingleItem from "./SingleItem";
import Skeleton from "react-loading-skeleton";
const MobileCryptoChain = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(
      `https://api2.prospectstreetx.com/api/user/trade/log/popular/market/view`,
      {
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setData(data.data);
      });
  }, []);

  const SymbolString = data.reduce(
    (acc, curr) => `${acc}${curr.symbol}${curr.currency},`,
    ""
  );
  const Symbol = SymbolString.substring(0, SymbolString?.length - 1);

  const [livePrice, setLivePrice] = useState([]);

  useEffect(() => {
    fetchData();
  }, [data]);

  const fetchData = async () => {
    if (Symbol?.length !== 0) {
      const data = await fetch(
        `https://marketdata.tradermade.com/api/v1/live?currency=${Symbol}&api_key=-gdndby-Ql_zjSld9pbb`
      );
      const apiResponse = await data.json();
      setLivePrice(apiResponse?.quotes);
      if (apiResponse?.quotes) {
        setLoading(false);
      }
    }
  };






  if (loading) {
    return (
      <>
        <div className="container-custom">
          <Skeleton height={15} count={10} />
        </div>
      </>
    );
  } else {
    return (
      <>
        <div class="market-area-sw">
          <div class="pb-5">
            <div class="container-fluid">
              <div className="tab-conten pb-5" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-top"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                  tabindex="0"
                >
                  <div
                    className="d-flex gap-2 flex-column my-3"
                    id="coinContainer"
                  >
                    {data.map((data, index) => (
                      <SingleItem
                        data={data}
                        key={data?._id}
                        index={index}
                        livePrice={livePrice}
                      ></SingleItem>
                    ))}
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default MobileCryptoChain;
