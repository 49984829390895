import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Button, Modal } from "react-bootstrap";
const NoticeModal = ({ setShow, show, singleData }) => {
  function ModalComponent(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className=" notices-modal-show "
        backdrop="static"
      >
        <Modal.Body>
          <LazyLoadImage
            alt="img"
            effect="blur"
            src={`https://api2.prospectstreetx.com/${singleData?.image}`}
          />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center align-items-center notice-close-btn ">
          <Button onClick={() => setShow(false)}>
            <i className="fa-solid fa-xmark"></i>
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
  return (
    <>
      <ModalComponent show={show} onHide={() => setShow(false)} />
    </>
  );
};

export default NoticeModal;
