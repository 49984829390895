import React from "react";
import { Link } from "react-router-dom";

const SingleItem = ({ data, token, handleModal }) => {
  return (
    <>
      <div className="col-sm-12 col-12 col-md-6 col-lg-3 col-xxl-3 col-xl-3">
        <div
          className="pricingTable blue"
          data-aos="flip-left"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="2000"
        >
          <div className="pricingTable-header">
            <h3 className="title">{data?.promotion_name} </h3>
          </div>
          <div className="price-value">
            <span>
              {data?.percentage}%{" "}
              {data?.instant === 1 ? "DAILY" : data?.period_type }
            </span>
          </div>
          <ul className="pricing-content">
            <li>{data?.period} DAYS</li>
            <li>
              ${data?.min_amount} - ${data?.max_amount}
            </li>
            <li>
              Principal{" "}
              {data?.principal === 1 ? (
                <span> Returns</span>
              ) : data?.principal === 2 ? (
                <span> Included</span>
              ) : (
                ""
              )}
            </li>
          </ul>

          <div className="pricingTable-signup">
            {token ? <button onClick={()=>handleModal(data?._id)}>INVEST</button> : <Link to="/login">INVEST</Link>}
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleItem;
