import React, { useEffect, useState } from "react";
import SingleItem from "./SingleItem";

const Crypto = ({handleClose}) => {
    const [data, setData] = useState([]);


    useEffect(() => {
        fetch(`https://api2.prospectstreetx.com/api/frontend/home/crypto/currency/view`, {
            method: 'GET',
        })
            .then(res => res.json())
            .then(data => {
                setData(data.data);
            });


    }, [])

    
    const SymbolString = data.reduce(
        (acc, curr) => `${acc}${curr.Symbol}${curr.currency},`,
        ""
      );
      const Symbol = SymbolString.substring(0, SymbolString?.length - 1);
    
      const [livePrice, setLivePrice] = useState([]);
    
      useEffect(() => {
        fetchData();
      }, [data]);
    
      const fetchData = async () => {
        if (Symbol?.length !== 0) {
          const data = await fetch(
            `https://marketdata.tradermade.com/api/v1/live?currency=${Symbol}&api_key=9iQMBHV42RgmV60x5iEi`
          );
          const apiResponse = await data.json();
          setLivePrice(apiResponse?.quotes);
        }
      };


  return (
    <>
      <div className="tab-content" id="pills-tabContent">
        <div className="tab-pane fade show active">
          <ul className="coin_menu mt-2">
          {data.map((data, index) => <SingleItem data={data} key={data?._id} index={index} livePrice={livePrice} handleClose={handleClose} ></SingleItem>)}

            {/* <li>
              <a
                className="menu-item active"
                href="https://swoopventures.com/user/trades?coin=DOGE"
              >
                <div className="d-flex justify-content-between gap-1">
                  <div className="text-xl">
                    <span>DOGE</span>
                    <span className="opacity-50 text-base font-light">
                      /USDT
                    </span>
                  </div>

                  <div className="d-flex justify-content-between mt-1">
                    <div className="text-gray-400 font-light text-sm  trade-sidebar-price">
                      0.1
                    </div>
                  </div>
                </div>
              </a>
            </li> */}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Crypto;
