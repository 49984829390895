import React from "react";
import { Link } from "react-router-dom";

const CHARTS = () => {
  return (
    <>
      <section className="chats-section">
        <div className="py-80 py-50 et_pb_section et_pb_section_4 chart-section et_pb_with_background et_section_regular ">
          <div className=" et_pb_row et_pb_row_7 chart-block">
            <div className="row charts-area">
              <div
                id=" corp-profile_chart--diversity"
                className=" col-6 corp-profile_chart et_pb_column et_pb_column_1_2 et_pb_column_15  et_pb_css_mix_blend_mode_passthrough"
              >
                <div className="et_pb_module et_pb_text et_pb_text_19 chart-label et_pb_bg_layout_light">
                  <div className="et_pb_text_inner">
                    <h1>Industry Diversity</h1>
                  </div>
                </div>
                <div className="et_pb_module et_pb_image et_pb_image_8 chart left-chart et-animated et_had_animation aos-init">
                  <span className="et_pb_image_wrap ">
                    <img
                      data-aos="zoom-in-up"
                      data-aos-duration="3000"
                      src="//s23.q4cdn.com/831501628/files/doc_financials/2024/q3/Industry-Diversity-3-31-24.png"
                      sizes="((min-width: 0px) and (max-width: 480px)) 480px, ((min-width: 481px) and (max-width: 980px)) 980px, ((min-width: 981px) and (max-width: 1280px)) 1280px, (min-width: 1281px) 1500px, 100vw"
                      alt=""
                    />
                  </span>
                </div>
              </div>
              <div
                id="ccorp-profile_chart--lending"
                className=" col-6 corp-profile_chart et_pb_column et_pb_column_1_2 et_pb_column_16  et_pb_css_mix_blend_mode_passthrough et-last-child"
              >
                <div className="et_pb_module et_pb_text et_pb_text_20 chart-label chart-label-right et_pb_bg_layout_light">
                  <div className="et_pb_text_inner">
                    <h1>Senior and Secured lending focus</h1>
                  </div>
                </div>
                <div
                  className="et_pb_module et_pb_image et_pb_image_10 chart right-chart et-animated et_had_animation aos-init chats-last-img"
                  style={{ marginTop: "80px" }}
                >
                  {" "}
                  <span className="et_pb_image_wrap ">
                    <img
                      data-aos="zoom-in-up"
                      data-aos-duration="3000"
                      src="//s23.q4cdn.com/831501628/files/doc_financials/2024/q3/Senior-and-Secured-Lending-Graph-3-31-24.png"
                      sizes="((min-width: 0px) and (max-width: 480px)) 480px, ((min-width: 481px) and (max-width: 980px)) 980px, ((min-width: 981px) and (max-width: 1280px)) 1280px, (min-width: 1281px) 1501px, 100vw"
                      alt=""
                    />
                  </span>
                </div>
              </div>
            </div>

            <div class="full-portfolio-btn">
              <Link to="/portfolio">View Full Portfolio</Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CHARTS;
