import React, { useRef, useState } from "react";
import "./MobileContact.css";
import tel from "./telegram.webp"
import mas from "./massenger.png"
import live from "./live.jpg"
import ChatPage from "../../../LiveChatsPage/ChatPage/ChatPage";
const MobileContact = () => {
    const [chatPage, setChatPage] = useState(false);
    const ChatPageShowRef = useRef();
  
    const ChatPageShow = () => {
      ChatPageShowRef.current.style.display = "block";
      setChatPage(true);
    }
    const ChatPageHide = () => {
      ChatPageShowRef.current.style.display = "none";
      setChatPage(false);
    }
  return (
    <>
      <section class="mobile-contact-section">
        <div className="container">
          <div class="card mb-3">
            <div class="card-content">
              <div className="d-flex justify-content-between align-items-center">
                <div className="name">
                  <h4>Telegram Support</h4>
                </div>
                <div className="logo">
                  <img
                    src={tel}
                    alt=""
                    width="40px"
                    height="40px"
                  />
                </div>
                <a href="https://t.me/infopcxofficial" target="_blank" rel="noopener noreferrer">
                PCX  Official
                  </a>
              </div>
             
            </div>
          </div>
          <div class="card mb-3">
            <div class="card-content">
              <div className="d-flex justify-content-between align-items-center">
                <div className="name">
                  <h4>
                  Email Consultation : 
                  </h4>
                  <span>prospectcapitalx@gmail.com</span>
                </div>
                <div className="logo">
                  <img
                    src={mas}
                    alt=""
                    width="40px"
                    height="40px"
                  />
                </div>
              </div>
             
            </div>
          </div>
          <div class="card mb-3">
            <div class="card-content">
              <div className="d-flex justify-content-between align-items-center">
                <div className="name">
                  <h4>
                  Live Chat
                  </h4>
                </div>
                <div className="logo">
                  <img
                    src={live}
                    alt=""
                    width="40px"
                    height="40px"
                  />
                </div>
                <button onClick={ChatPageShow}>
                PCX  Live Chat
                  </button>
              </div>
             
            </div>
          </div>
        </div>
      </section>

      <div style={{ display: "none" }} ref={ChatPageShowRef}>
        {(() => {
          if (chatPage) {
            return (
              <ChatPage ChatPageHide={ChatPageHide}></ChatPage>
            )
          }
        })()}

      </div>
    </>
  );
};

export default MobileContact;
