import React from 'react';
import { NavLink } from 'react-router-dom';

const SingleItem = ({ data, index ,livePrice, handleClose,}) => {
    return (
        <>
              <li >
              <NavLink onClick={handleClose}
                className="menu-item "
                to={`/user/trade/crypto/${data?.Symbol}${data?.currency}/${data?._id}`}
                end >
                <div className="d-flex justify-content-between gap-1">
                  <div className="text-xl">
                    <span>{data?.Name}</span>
                    <span className="opacity-50 text-base font-light">
                    <br />  {data?.Symbol}
                    </span>
                  </div>
                  <div className="d-flex justify-content-between mt-1">
                    <div className="text-gray-400 font-light text-sm  trade-sidebar-price">
                    {livePrice[index]?.ask}$
                    </div>
                  </div>
                </div>
              </NavLink>
            </li> 
        </>
    );
};

export default SingleItem;