import React from "react";
import { Link } from "react-router-dom";

const PROSPECT = () => {
  return (
    <>
      <div className="et_pb_section et_pb_section_5 info-section et_pb_with_background et_section_regular py-50">
        <div className="et_pb_row et_pb_row_9 ">
          <div className="row prospect-area g-0">
            <div className="col-8">
              <div className="et_pb_column et_pb_column_1_2 et_pb_column_18 latest  et_pb_css_mix_blend_mode_passthrough">
                <div className="et_pb_module et_pb_text et_pb_text_22 title et_pb_bg_layout_dark  et_pb_text_align_left">
                  <div className="et_pb_text_inner">
                    <h1 className="h1-25">Latest From Prospect</h1>
                  </div>
                </div>
                <div className="et_pb_module et_pb_divider et_pb_divider_0 latest-divider et_pb_divider_position_ et_pb_space">
                  <div className="et_pb_divider_internal"></div>
                </div>
                <div className="et_pb_module et_pb_code et_pb_code_0 ticker-box irwStockQuote">
                  <div className="et_pb_code_inner">
                    <div>
                      <p></p>
                      <div className="row prospect-details g-0">
                        <div className="col-8">
                          <div className="left-column-ticker">
                            <div className="company-name">
                              {" "}
                              <span id="company">
                                Prospect Capital Corporation
                              </span>
                            </div>
                            <div className="stock-exchange-symbol">
                              {" "}
                              <span id="exchange">(NASDAQ:</span>
                              <span id="ticker-symbol">PSEC)</span>
                            </div>
                            <div className="stock-date">
                              {" "}
                              <span id="stock-date">
                                As of July 9, 2024 4:00 PM
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="right-column-ticker">
                            <div className="stock-value">
                              <div className="stock-price">
                                {" "}
                                <span id="stock-price">5.51</span>
                              </div>
                              <div className="delta">
                                {" "}
                                <span
                                  id="delta-indicator"
                                  className="fa fa-caret-up text-success positive"
                                  aria-hidden="true"
                                ></span>
                                <span
                                  id="delta-value"
                                  className="stock-change text-success positive"
                                >
                                  0.00{" "}
                                </span>
                                <span
                                  id="delta-percent"
                                  className="percChange text-success positive"
                                >
                                  (0.00%)
                                </span>
                              </div>
                              <div className="volume">
                                {" "}
                                <span id="volume">Vol: 1,150,186</span>
                              </div>
                            </div>
                            <p></p>
                            <div className="stock-delay">
                              {" "}
                              <span id="stock-delay">
                                *Minimum 20 minute delay
                              </span>
                            </div>
                            <p></p>
                          </div>
                        </div>
                      </div>

                      <p></p>

                      <p></p>
                    </div>
                  </div>
                </div>
                <div className="et_pb_module et_pb_divider et_pb_divider_1 ticker-divider et_pb_divider_position_ et_pb_space">
                  <div className="et_pb_divider_internal"></div>
                </div>

                {/* <!-- PRESS RELEASE SECTION --> */}
                <div className="et_pb_module et_pb_blog_0 .blog-posts et_pb_blog_grid_wrapper">
                  <div className="et_pb_blog_grid clearfix et_pb_bg_layout_light ">
                    <div className="et_pb_ajax_pagination_container">
                      <div
                        className="et_pb_salvattore_content"
                        data-columns="1"
                      >
                        <div className="" id="hp-news-widget">
                          <div className="row hp-news-widget-area gx-4">
                            <div className="col-6">
                              <article
                                id="post-1564"
                                className="et_pb_post clearfix et_pb_no_thumb post-1564 post type-post status-publish format-standard hentry category-press-release"
                              >
                                <h2 className="entry-title">
                                  <a href="/News/news-details/2024/Prospect-Capital-Announces-March-2024-Financial-Results-and-Declares-84th-Consecutive-0.06-Dividend/default.aspx">
                                    Prospect Capital Announces March 2024
                                    Financial Results and Declares 84th
                                    Consecutive $0.06 Dividend
                                  </a>
                                </h2>
                                <p className="post-meta">
                                  <span className="published">
                                    May 08, 2024
                                  </span>{" "}
                                  |
                                  <a href="/News/" rel="category tag">
                                    Press Release
                                  </a>
                                </p>
                                <div className="post-content">
                                  <div className="post-content-inner">
                                    <p>
                                      NEW YORK, May 08, 2024 (GLOBE NEWSWIRE) -
                                      Prospect Capital Corporation (NASDAQ:
                                      PSEC) (“Prospect”, “our”, or “we”) today
                                      announced financial results for our fiscal
                                      quarter ended March 31, 2024. FINANCIAL
                                      RESULTS All amounts in $000’s except per
                                      share...
                                    </p>
                                  </div>
                                  <a
                                    href="/News/news-details/2024/Prospect-Capital-Announces-March-2024-Financial-Results-and-Declares-84th-Consecutive-0.06-Dividend/default.aspx"
                                    className="more-link"
                                  >
                                    read more
                                  </a>
                                </div>
                              </article>
                            </div>
                            <div className="col-6">
                              <article
                                id="post-1564"
                                className="et_pb_post clearfix et_pb_no_thumb post-1564 post type-post status-publish format-standard hentry category-press-release"
                              >
                                <h2 className="entry-title">
                                  <a href="/News/news-details/2024/Prospect-Capital-Schedules-Third-Fiscal-Quarter-Earnings-Release-and-Conference-Call/default.aspx">
                                    Prospect Capital Schedules Third Fiscal
                                    Quarter Earnings Release and Conference Call
                                  </a>
                                </h2>
                                <p className="post-meta">
                                  <span className="published">
                                    May 07, 2024
                                  </span>{" "}
                                  |
                                  <a href="/News/" rel="category tag">
                                    Press Release
                                  </a>
                                </p>
                                <div className="post-content">
                                  <div className="post-content-inner">
                                    <p>
                                      NEW YORK, May 07, 2024 (GLOBE NEWSWIRE) -
                                      Prospect Capital Corporation (NASDAQ:
                                      PSEC) (the “Company”) today announced it
                                      expects to file with the Securities and
                                      Exchange Commission its report on Form
                                      10-Q containing results for the fiscal
                                      quarter end...
                                    </p>
                                  </div>
                                  <a
                                    href="/News/news-details/2024/Prospect-Capital-Schedules-Third-Fiscal-Quarter-Earnings-Release-and-Conference-Call/default.aspx"
                                    className="more-link"
                                  >
                                    read more
                                  </a>
                                </div>
                              </article>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-4">
              <div
                className="et_pb_column et_pb_column_1_2 et_pb_column_19 ir  et_pb_css_mix_blend_mode_passthrough et-last-child investor-icon-area"
                id="ir-widgets"
                style={{ paddingLeft: "40px" }}
              >
                <div className="et_pb_module et_pb_text et_pb_text_23 title et_pb_bg_layout_dark  et_pb_text_align_left">
                  <div className="et_pb_text_inner">
                    <h1 className="h1-25">Investor Relations</h1>
                  </div>
                </div>
                <div className="et_pb_module et_pb_divider et_pb_divider_2 ir-divider et_pb_divider_position_ et_pb_space">
                  <div className="et_pb_divider_internal"></div>
                </div>
                <div className="d-flex align-items-center py-4 investor-icon">
                  <div
                    id="ir-2"
                    className="et_pb_module et_pb_code et_pb_code_2 icon ir-link"
                  >
                    <div className="et_pb_code_inner">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        id="ir-2"
                        className="style-svg ir-link replaced-svg svg-replaced-0"
                        x="0px"
                        y="0px"
                        viewBox="0 0 612.5 612.5"
                        style={{ enableBackground: "new 0 0 612.5 612.5" }}
                      >
                        <g id="Layer_1">
                          <g>
                            <g>
                              <path d="M306.3,28c37.6,0,74,7.4,108.3,21.9c33.1,14,62.9,34.1,88.5,59.6c25.6,25.6,45.6,55.3,59.6,88.5     c14.5,34.3,21.9,70.7,21.9,108.3s-7.4,74-21.9,108.3c-14,33.1-34.1,62.9-59.6,88.5c-25.6,25.6-55.3,45.6-88.5,59.6     c-34.3,14.5-70.7,21.9-108.3,21.9s-74-7.4-108.3-21.9c-33.1-14-62.9-34.1-88.5-59.6c-25.6-25.6-45.6-55.3-59.6-88.5     C35.4,380.3,28,343.8,28,306.3s7.4-74,21.9-108.3c14-33.1,34.1-62.9,59.6-88.5c25.6-25.6,55.3-45.6,88.5-59.6     C232.2,35.4,268.7,28,306.3,28 M306.3,0C137.1,0,0,137.1,0,306.3s137.1,306.3,306.3,306.3s306.3-137.1,306.3-306.3     S475.4,0,306.3,0L306.3,0z"></path>
                            </g>
                            <g>
                              <g>
                                <path d="M466.4,187.1v230.3H146.2V187.1H466.4 M488.4,165.1H124.2v274.3h364.2V165.1L488.4,165.1z"></path>
                              </g>
                              <g>
                                <path d="M302.1,288.1c-1.3,0-2,0-48.8,20.2l6.3-50.5c0.2-1.9-0.7-3.7-2.4-4.7c-0.6-0.3-1.2-0.6-1.9-0.7      c-2.6-0.3-5.2-0.5-7.8-0.5c-7,0-13.7,1.2-20,3.2c-9.4,3.1-17.8,8.2-24.7,14.9c-0.1,0.1-0.1,0.2-0.2,0.2      c-4,3.9-7.5,8.3-10.3,13.2c-4,7-6.8,14.8-7.9,23.2c-2,16,2.5,31.2,11.3,43.4c0.6,0.8,1.1,1.6,1.7,2.3c3.6,4.4,7.7,8.4,12.3,11.7      c8.6,6.2,18.8,10.4,30.1,11.8c2.6,0.3,5.2,0.5,7.8,0.5c32.1,0,59.2-23.5,63.1-54.7c1.3-10.4-0.1-21-4.1-30.7      C305.9,289.2,304.1,288.1,302.1,288.1z"></path>
                                <path d="M317.3,248.8c-2.9-4-6.3-7.5-10.2-10.5c-6.4-4.9-14.1-8.2-22.3-9.6c-0.8-0.2-1.7-0.4-2.5-0.5c-0.9-0.1-1.7,0.2-2.3,0.8      c-0.4,0.4-0.8,0.9-0.8,1.6l-1.2,9.8l-1.4,11.3l-3.2,25.5c-0.1,1,0.3,1.9,1.1,2.5c0.5,0.4,1.1,0.5,1.7,0.5c0.4,0,0.8-0.1,1.1-0.2      l44.2-18.3c0.7-0.3,1.2-0.8,1.5-1.5c0.3-0.7,0.3-1.4,0-2.1C321.3,254.8,319.5,251.7,317.3,248.8z"></path>
                                <path d="M422.4,368c4.7,0,8.6-3.8,8.6-8.4c0-4.6-3.8-8.4-8.6-8.4h-13.5h-50.4c-4.7,0-8.6,3.8-8.6,8.4c0,4.6,3.8,8.4,8.6,8.4h49      H422.4z"></path>
                                <path d="M358.5,319.4h53.2h10.7c4.7,0,8.6-3.8,8.6-8.4c0-4.6-3.8-8.4-8.6-8.4h-9.3h-54.6c-4.7,0-8.6,3.8-8.6,8.4      C349.9,315.6,353.8,319.4,358.5,319.4z"></path>
                                <path d="M358.5,271.5h57.3h6.6c4.7,0,8.6-3.8,8.6-8.4c0-4.6-3.8-8.4-8.6-8.4h-5.2h-58.7c-4.7,0-8.6,3.8-8.6,8.4      C349.9,267.7,353.8,271.5,358.5,271.5z"></path>
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </div>
                  </div>
                  <div
                    id="ir-2"
                    className="et_pb_module et_pb_text et_pb_text_24 label ir-link et_pb_bg_layout_light  et_pb_text_align_left"
                  >
                    <div className="et_pb_text_inner">
                      <p>
                        <a
                          href="https://s23.q4cdn.com/831501628/files/doc_presentation/2024/2024-03-31-psec-overview.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Corporate Presentation
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center ">
                  <div
                    id="ir-4"
                    className="et_pb_module et_pb_code et_pb_code_3 icon ir-link"
                  >
                    <div className="et_pb_code_inner">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        id="ir-4"
                        className="style-svg ir-link replaced-svg svg-replaced-1"
                        x="0px"
                        y="0px"
                        viewBox="0 0 612.5 612.5"
                        style={{ enableBackground: "new 0 0 612.5 612.5" }}
                      >
                        <g id="Layer_1">
                          <g>
                            <path d="M422.6,424l-18.9,0.9V190.4l-209.8-16.3h-0.5c-7.3,0-13.7-2-17.5-4.4c-1.9-1.2-3.2-2.5-3.8-3.4c-0.6-1-0.7-1.4-0.7-1.9    c0-0.4,0.1-0.9,0.7-1.9c0.9-1.4,3.3-3.6,7.1-5.1c3.8-1.6,8.7-2.7,14.2-2.7h0.1l229.1-6.4V424z M356.7,278l-151-12.1l2-25.6    l151,12.1L356.7,278z M317.9,339.2l-87.1-6.8l2-25.6l87.1,6.8L317.9,339.2z M193.2,131.9c-11.1,0.1-21.3,2.8-29.5,7.9    c-4.1,2.6-7.8,5.9-10.5,10c-2.8,4.1-4.6,9.2-4.6,14.6v274.3c0,5.4,1.8,10.5,4.6,14.6c4.2,6.2,10.2,10.5,17.1,13.4    c6.8,2.9,14.4,4.4,22.6,4.5l210.8,16.4v-39.8l41.7-1.9V124.8L193.2,131.9z"></path>
                            <g>
                              <path d="M306.3,28c37.6,0,74,7.4,108.3,21.9c33.1,14,62.9,34.1,88.5,59.6c25.6,25.6,45.6,55.3,59.6,88.5     c14.5,34.3,21.9,70.7,21.9,108.3s-7.4,74-21.9,108.3c-14,33.1-34.1,62.9-59.6,88.5c-25.6,25.6-55.3,45.6-88.5,59.6     c-34.3,14.5-70.7,21.9-108.3,21.9s-74-7.4-108.3-21.9c-33.1-14-62.9-34.1-88.5-59.6c-25.6-25.6-45.6-55.3-59.6-88.5     C35.4,380.3,28,343.8,28,306.3s7.4-74,21.9-108.3c14-33.1,34.1-62.9,59.6-88.5c25.6-25.6,55.3-45.6,88.5-59.6     C232.2,35.4,268.7,28,306.3,28 M306.3,0C137.1,0,0,137.1,0,306.3s137.1,306.3,306.3,306.3s306.3-137.1,306.3-306.3     S475.4,0,306.3,0L306.3,0z"></path>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </div>
                  </div>
                  <div
                    id="ir-4"
                    className="et_pb_module et_pb_text et_pb_text_25 label ir-link et_pb_bg_layout_light  et_pb_text_align_left"
                  >
                    <div className="et_pb_text_inner">
                      <p>
                        <a
                          href="https://s23.q4cdn.com/831501628/files/doc_financials/2023/ar/PSEC-10-K-Q4-2023.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Annual Report
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center py-4 investor-icon">
                  <div
                    id="ir-4"
                    className="et_pb_module et_pb_code et_pb_code_3 icon ir-link"
                  >
                    <div className="et_pb_code_inner">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        id="ir-4"
                        className="style-svg ir-link replaced-svg svg-replaced-1"
                        x="0px"
                        y="0px"
                        viewBox="0 0 612.5 612.5"
                        style={{ enableBackground: "new 0 0 612.5 612.5" }}
                      >
                        <g id="Layer_1">
                          <g>
                            <path d="M422.6,424l-18.9,0.9V190.4l-209.8-16.3h-0.5c-7.3,0-13.7-2-17.5-4.4c-1.9-1.2-3.2-2.5-3.8-3.4c-0.6-1-0.7-1.4-0.7-1.9    c0-0.4,0.1-0.9,0.7-1.9c0.9-1.4,3.3-3.6,7.1-5.1c3.8-1.6,8.7-2.7,14.2-2.7h0.1l229.1-6.4V424z M356.7,278l-151-12.1l2-25.6    l151,12.1L356.7,278z M317.9,339.2l-87.1-6.8l2-25.6l87.1,6.8L317.9,339.2z M193.2,131.9c-11.1,0.1-21.3,2.8-29.5,7.9    c-4.1,2.6-7.8,5.9-10.5,10c-2.8,4.1-4.6,9.2-4.6,14.6v274.3c0,5.4,1.8,10.5,4.6,14.6c4.2,6.2,10.2,10.5,17.1,13.4    c6.8,2.9,14.4,4.4,22.6,4.5l210.8,16.4v-39.8l41.7-1.9V124.8L193.2,131.9z"></path>
                            <g>
                              <path d="M306.3,28c37.6,0,74,7.4,108.3,21.9c33.1,14,62.9,34.1,88.5,59.6c25.6,25.6,45.6,55.3,59.6,88.5     c14.5,34.3,21.9,70.7,21.9,108.3s-7.4,74-21.9,108.3c-14,33.1-34.1,62.9-59.6,88.5c-25.6,25.6-55.3,45.6-88.5,59.6     c-34.3,14.5-70.7,21.9-108.3,21.9s-74-7.4-108.3-21.9c-33.1-14-62.9-34.1-88.5-59.6c-25.6-25.6-45.6-55.3-59.6-88.5     C35.4,380.3,28,343.8,28,306.3s7.4-74,21.9-108.3c14-33.1,34.1-62.9,59.6-88.5c25.6-25.6,55.3-45.6,88.5-59.6     C232.2,35.4,268.7,28,306.3,28 M306.3,0C137.1,0,0,137.1,0,306.3s137.1,306.3,306.3,306.3s306.3-137.1,306.3-306.3     S475.4,0,306.3,0L306.3,0z"></path>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </div>
                  </div>
                  <div
                    id="ir-4"
                    className="et_pb_module et_pb_text et_pb_text_25 label ir-link et_pb_bg_layout_light  et_pb_text_align_left"
                  >
                    <div className="et_pb_text_inner">
                      <p>
                      <a
                          href="https://companiesmarketcap.com/prospect-capital/marketcap"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                         Market Capital Report
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pt-3 ">
            <div className="row align-items-center SUBSCRIBE-area g-0">
              <div className="col-8">
                <div className="et_pb_module et_pb_code et_pb_code_1">
                  <div className="et_pb_code_inner">
                    <div>
                      <div className="formbox-editor-799246">
                        <div style={{ display: "inline" }}>
                          <div className="email-icon">
                            {" "}
                            <img
                              src="//s23.q4cdn.com/831501628/files/images/irw/Newsletter-Icon@3x.png"
                              alt=""
                            />
                          </div>
                          <p></p>
                          <div className="email-top">
                            <div className="formbox-title-799246 ">
                              SUBSCRIBE TO PROSPECT UPDATES
                            </div>
                          </div>
                          <div className="email-bottom">
                            <div
                              className="email-button "
                              style={{ maxWidth: "100%", cursor: "pointer" }}
                            >
                              <a
                                href="/investors/EmailNotification/"
                                id=""
                                className=""
                                style={{ color: "#fff" }}
                              >
                                SUBSCRIBE
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-4">
                <div className=" sub-btn">
                  <Link to="button" className="button-87">
                    Apps
                  </Link>
                  <Link to="button" className="button-87">
                    Sign Up
                  </Link>
                  <Link to="button" className="button-87">
                    Login
                  </Link>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PROSPECT;
