import React, { useEffect, useState } from 'react';
import Energises from './Energises';

const EnergisesPage = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        fetch(`https://api2.prospectstreetx.com/api/user/trade/market/forex/view`, {
            method: 'GET',
        })
            .then(res => res.json())
            .then(data => {
                setData(data.data);
                setLoading(false);
            });


    }, [])

    
        
  const SymbolString = data.reduce(
    (acc, curr) => `${acc}${curr.base_currency}${curr.quote_currency},`,
    ""
  );
  const Symbol = SymbolString.substring(0, SymbolString?.length - 1);

  const [livePrice, setLivePrice] = useState([]);

  useEffect(() => {
    fetchData();
  }, [data]);

  const fetchData = async () => {
    if (Symbol?.length !== 0) {
      const data = await fetch(
        `https://marketdata.tradermade.com/api/v1/live?currency=${Symbol}&api_key=l2cBbXnp1M8kqDaVjC2H`
      );
      const apiResponse = await data.json();
      setLivePrice(apiResponse?.quotes);
    }
  };




    return (
        <>
            <Energises EnergisesData={data} livePrice={livePrice} ></Energises>
        </>
    );


};


export default EnergisesPage;