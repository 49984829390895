import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../../Contexts/AuthContext/AuthProvider";
import SingleItem from "./SingleItem";
import axios from "axios";
import CustomPaginationMobile from "../../../../CustomPagination/CustomPaginationMobile";

const MobilePracticeTradeHistory = ({results}) => {
  const { authUser } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [paginateData, setPaginateData] = useState([]);

  useEffect(() => {
    if (authUser?._id) {
      fetch(
        `https://api2.prospectstreetx.com/api/user/Practice/trade/log/history/${authUser?._id}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setData(data.data);
          setPaginateData(data);
        });
    }
  }, [results, authUser?._id]);

  const handlePage = (event) => {
    try {
      const pageNumber = event?.selected + 1;
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      axios
        .get(
          `https://api2.prospectstreetx.com/api/user/Practice/trade/log/history/${authUser?._id}?page=${pageNumber}`,
          config
        )
        .then((response) => {
          setData(response?.data?.data);
          setPaginateData(response?.data);
        })
        .catch((error) => {});
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <section>
        <div className="mobile-history-table">
          <div className="user-deposit-history my-5">
            <div className="user-referral-history-titile">
              <h3>Practice Trade History</h3>
            </div>
            <div className="user-referral-history-area  ">
              <div className="user-referral-history-items trade-history-table">
                <table className="user-referral-history-table ">
                  <thead>
                    <tr class="table-headers">
                      <th>S.N.</th>
                      <th>Crypto&nbsp;Currency</th>
                      <th>Amount</th>
                      <th>High/Low</th>
                      <th>Result</th>
                      <th>Status</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((data, index) => (
                      <SingleItem
                        data={data}
                        index={index}
                        key={data?._id}
                        paginateData={paginateData}
                      ></SingleItem>
                    ))}
                  </tbody>
                </table>
              </div>
              {/* paginateLinks */}
              <CustomPaginationMobile
                data={paginateData}
                handlePage={handlePage}
              ></CustomPaginationMobile>
              {/* paginateLinks */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MobilePracticeTradeHistory;
