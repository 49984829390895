import React from 'react';
import "./Blockchain.css"
const Blockchain = () => {
    return (
        <>
            <section className="privacy-policy-section">
                <div className="privacy-policy-title">
                    <h5>Blockchain</h5>
                </div>
                <div className="container">

                    <div className="blockchain-main-area">
                        <div className="blockchain-core-area">
                            <p>A blockchain is a sequence of blocks, each containing information, linked together nsuring safety
                                as long as one server is operational. Nodes provide storage and computing power. Modifying
                                blockchain information requires the approval of morin chronological order. This chain is stored
                                on all servers (nodes) in the system, ee than half of the nodes and changes to all nodes, making
                                tampering extremely difficult. Blockchain's two core features are data immutability and
                                decentralization, making recorded information more authentic and reliable, which helps address
                                mutual mistrust.</p>

                            <p>
                                <span>
                                    Origin:
                                </span>
                                Blockchain originated from Bitcoin. On November 1, 2008, a person known as Satoshi Nakamoto
                                published the article "Bitcoin: A Peer-to-Peer Electronic Cash System," outlining the electronic
                                cash system's architecture based on P2P network technology, encryption technology, timestamp
                                technology, and blockchain technology. This marked the birth of Bitcoin. Two months later, the
                                theory was put into practice, and on January 3, 2009, the first genesis block (serial number 0)
                                was created. A few days later, on January 9, 2009, a block with serial number 1 appeared,
                                connecting with the genesis block to form a chain, marking the birth of the blockchain.

                            </p>
                            <p>
                                <span>
                                    Concept Definition:
                                </span>
                                In a narrow sense, blockchain is a chained data structure that connects data blocks sequentially
                                in chronological order, forming a tamper-proof and forgery-resistant distributed ledger ensured
                                by cryptography. In a broader sense, blockchain technology uses this data structure to verify
                                and store data, employs distributed node consensus algorithms to generate and update data, uses
                                cryptography to secure data transmission and access, and utilizes smart contracts composed of
                                automated script codes. This constitutes a new distributed infrastructure and computing paradigm
                                for programming and managing data.


                            </p>
                            <p>
                                <span>
                                    Development History:
                                </span>
                                In 2008, Satoshi Nakamoto first proposed the concept of blockchain, which later became the core
                                component of the electronic currency Bitcoin, serving as a public ledger for all transactions.
                                Utilizing a peer-to-peer network and distributed timestamp servers, blockchain databases can be
                                managed autonomously. This innovation made Bitcoin the first digital currency to solve the
                                double-spending problem, inspiring other applications.
                                <br />
                                <br />
                                In 2014, "Blockchain 2.0" emerged as a term for a decentralized blockchain database. This
                                second-generation programmable blockchain is seen by economists as a programming language that
                                allows users to write sophisticated and intelligent agreements. It can enable income from
                                dividends of completed freight orders or shared certificates, bypass transactions, and eliminate
                                intermediaries. Blockchain 2.0 enhances privacy, empowers individuals to monetize their
                                information, and secures returns for intellectual property owners. It also allows for storing an
                                individual's "permanent digital ID and image" and addresses "potential social wealth
                                distribution" inequality.
                                <br />
                                <br />
                                On January 10, 2019, the State Internet Information Office issued the "Blockchain Information
                                Service Management Regulations." On October 24, 2019, during the 18th collective study of the
                                Political Bureau of the Central Committee, General Secretary Xi Jinping emphasized blockchain as
                                an important breakthrough for independent innovation of core technologies and called for
                                accelerating the development of blockchain technology and industrial innovation. As a result,
                                "blockchain" has garnered significant public attention and become a societal focus.

                            </p>

                        </div>
                        <div className="blockchain-type-area">
                            <h6>Types of Blockchain</h6>
                            <p><b># </b> Public Blockchains: Public blockchains allow any individual or group worldwide to send
                                transactions, which can be effectively confirmed by the blockchain. Anyone can participate in
                                the consensus process. These are the earliest and most widely used blockchains. Major virtual
                                digital currencies, such as Bitcoin, are based on public blockchains, with only one blockchain
                                corresponding to each currency globally.
                            </p>
                            <p><b># </b> Consortium Blockchains: Consortium blockchains involve multiple pre-selected nodes
                                designated by a group to act as bookkeepers. The generation of each block is jointly decided by
                                these pre-selected nodes, which participate in the consensus process. Other access nodes can
                                participate in transactions but do not engage in the bookkeeping process. This setup remains
                                managed bookkeeping but becomes distributed, with the number of pre-selected nodes and the
                                determination of each block's bookkeeper being the main risk points. Limited queries can be made
                                by others through the blockchain's open API.
                            </p>
                            <p><b># </b> Private Blockchains: Private blockchains use general ledger technology solely for
                                bookkeeping by a company or individual. The storage schemes are not much different from other
                                uses. While traditional finance experiments with private blockchains, applications for public
                                blockchains like Bitcoin have already been industrialized. Applications for private blockchains
                                are still being explored.

                            </p>

                        </div>

                        <div className="blockchain-key-features">
                            <h5>Key Features of Blockchain</h5>
                            <p>
                                <span>
                                    #Decentralization:
                                </span>
                                Blockchain technology operates without the need for third-party management agencies or hardware
                                facilities and lacks central control. Through distributed accounting and storage, each node
                                handles self-verification, information transmission, and management independently.
                                Decentralization is the most prominent and essential feature of blockchain.

                            </p>
                            <p>
                                <span>
                                    #Openness:
                                </span>
                                Blockchain technology is built on open-source principles. Apart from the encrypted private
                                information of transaction parties, blockchain data is accessible to everyone. Anyone can query
                                blockchain data and develop related applications through public interfaces, ensuring high
                                transparency of the entire system's information.

                            </p>
                            <p>
                                <span>
                                    #Independence:
                                </span>
                                The blockchain system operates independently based on consensus-based specifications and
                                protocols, such as Bitcoin's hash algorithm. It does not rely on third parties, allowing all
                                nodes to automatically and securely verify and exchange data within the system without human
                                intervention.

                            </p>
                            <p>
                                <span>
                                    #Security:
                                </span>
                                Blockchain's security is robust as long as no single entity controls 51% of all data nodes,
                                preventing arbitrary manipulation and modification of network data. This safeguard makes the
                                blockchain inherently secure and protects against subjective and artificial data changes.

                            </p>
                            <p>
                                <span>
                                    #Anonymity:
                                </span>
                                Technically, the identity information of each block node does not need to be disclosed or
                                verified, allowing information transmission to remain anonymous, except when legal requirements
                                mandate disclosure.
                            </p>

                        </div>

                        <div className="blockchain-system-area">
                            <h5>### Components of a Blockchain System</h5>
                            <div className="disclaimer-lists">
                                <ol>
                                    <li>
                                        **Data Layer**
                                        <p>
                                            <span>- **Components**: </span>
                                            Underlying data blocks, data encryption, timestamps
                                            <br />
                                            <span>- **Functions**: </span> Encapsulates basic data and algorithms, ensuring
                                            secure and
                                            time-verified information.

                                        </p>
                                    </li>
                                    <li>
                                        **Network Layer**
                                        <p>
                                            <span>
                                                - **Components**:
                                            </span>
                                            Distributed networking mechanisms, data dissemination mechanisms, data verification
                                            mechanisms

                                            <br />
                                            <span>
                                                - **Functions**:
                                            </span>
                                            **: Manages how data is shared and verified across the network.

                                        </p>
                                    </li>
                                    <li>
                                        **Consensus Layer**
                                        <p>
                                            <span>
                                                - **Components**:
                                            </span>
                                            Various consensus algorithms

                                            <br />
                                            <span>
                                                - **Functions**:
                                            </span>
                                            **: Facilitates agreement among network nodes on the state of the blockchain.


                                        </p>
                                    </li>
                                    <li>
                                        **Incentive Layer**

                                        <p>
                                            <span>
                                                - **Components**:
                                            </span>
                                            **: Economic incentives issuance and distribution mechanisms

                                            <br />
                                            <span>
                                                - **Functions**:
                                            </span>
                                            Integrates economic factors to motivate participation and maintain the blockchain
                                            system.



                                        </p>
                                    </li>
                                    <li>
                                        . **Contract Layer**

                                        <p>
                                            <span>
                                                - **Components**:
                                            </span>
                                            Scripts, algorithms, smart contracts

                                            <br />
                                            <span>
                                                - **Functions**:
                                            </span>
                                            **: Provides the foundation for programmable features, enabling automated and
                                            flexible transactions.

                                        </p>
                                    </li>
                                    <li>
                                        **Application Layer**

                                        <p>
                                            <span>
                                                - **Components**:
                                            </span>
                                            Various application scenarios and cases

                                            <br />
                                            <span>
                                                - **Functions**:
                                            </span>
                                            **: Encapsulates specific uses of blockchain technology across different sectors.

                                        </p>
                                    </li>

                                </ol>
                            </div>

                        </div>

                        <div className="blockchain-technology-area">
                            <div className="blockchain-key-features">
                                <h5>
                                    ### Representative Innovations in Blockchain Technology
                                </h5>
                                <p>
                                    <span>
                                        - **Chained Block Structure with Timestamps**:
                                    </span>
                                    Ensures data integrity and chronological order.

                                </p>
                                <p>
                                    <span>
                                        - **Consensus Mechanism of Distributed Nodes**:
                                    </span>
                                    Secures agreement on blockchain updates without central authority.


                                </p>
                                <p>
                                    <span>
                                        - **Economic Incentives Based on Consensus Computing Power**:
                                    </span>
                                    Encourages active participation and network security.


                                </p>
                                <p>
                                    <span>
                                        - **Flexible and Programmable Smart Contracts**:
                                    </span>
                                    Allows for automated, condition-based transactions and operations.
                                </p>

                            </div>
                        </div>


                        <div className="blockchain-system-area blockchain-technologies">
                            <h5>Seven Core Technologies of Blockchain
                            </h5>
                            <div className="disclaimer-lists">
                                <ol>
                                    <li>
                                        Distributed Ledger
                                        <p>
                                            <span>- **Definition**: </span>
                                            Transaction bookkeeping is completed by multiple nodes distributed in different
                                            locations, with each node recording a complete account.

                                            <br />
                                            <span>- **Key Features**: </span>
                                            - Each node stores complete data according to the blockchain structure, unlike
                                            traditional distributed storage which divides data into parts. <br />
                                            - Nodes are independent and equal, ensuring data consistency through a consensus
                                            mechanism without a central authority. <br />
                                            - Prevents single-node control, ensuring security and reliability of the account
                                            data.


                                        </p>
                                    </li>

                                    <li>
                                        Asymmetric Encryption
                                        <p>
                                            <span>
                                                - **Definition**:
                                            </span>
                                            Ensures that transaction information is public while account identity information is
                                            highly encrypted.

                                            <br />
                                            <span>
                                                - **Key Features**:
                                            </span>

                                            - Data security and personal privacy are maintained.<br />
                                            - Only the data owner can authorize access to account identity information.

                                        </p>
                                    </li>

                                    <li>
                                        Consensus Mechanism
                                        <p>
                                            <span>
                                                - **Definition**:
                                            </span>
                                            The method by which all accounting nodes reach a consensus on the validity of a
                                            record.

                                            <br />
                                            <span>
                                                - **Key Features**:
                                            </span>
                                            - Prevents tampering and ensures record validity. <br />
                                            - Different mechanisms for different scenarios, balancing efficiency and security.
                                            <br />
                                            - "Minority obeys the majority" principle, where "majority" can mean computing
                                            power, shares, etc. <br />
                                            - Nodes have equal rights to propose consensus results, recognized by others and
                                            forming the final consensus.


                                        </p>
                                    </li>

                                    <li>
                                        Smart Contracts
                                        <p>
                                            <span>
                                                - **Definition**:
                                            </span>
                                            Automated execution of predefined rules and terms based on credible and immutable
                                            data.

                                            <br />
                                            <span>
                                                - **Key Features**:
                                            </span>
                                            - Examples include automating claims settlement in insurance. <br />
                                            - Improves risk management capabilities for businesses, such as insurance companies.
                                            <br />
                                            - Facilitates policyholder risk management and company risk supervision.


                                        </p>
                                    </li>

                                    <li>
                                        Chained Block Structure
                                        <p>
                                            <span>
                                                - **Definition**:
                                            </span>
                                            Data is stored in blocks that are chained together in chronological order.

                                            <br />
                                            <span>
                                                - **Key Features**:
                                            </span>
                                            - Ensures data integrity and security through a sequence of blocks. <br />
                                            - Each block contains a timestamp, securing the timeline of data. <br />


                                        </p>
                                    </li>

                                    <li>
                                        Peer-to-Peer Network
                                        <p>
                                            <span>
                                                - **Definition**:
                                            </span>
                                            A network architecture where each node can communicate directly with others.

                                            <br />
                                            <span>
                                                - **Key Features**:
                                            </span>
                                            - Eliminates the need for a central server.<br />
                                            - Enhances resilience and fault tolerance.<br />


                                        </p>
                                    </li>

                                    <li>
                                        Economic Incentives
                                        <p>
                                            <span>
                                                - **Definition**:
                                            </span>
                                            Integration of economic factors into the blockchain to motivate participation.
                                            <br />
                                            <span>
                                                - **Key Features**:
                                            </span>

                                            - Includes issuance and distribution mechanisms for rewards. <br />
                                            - Encourages nodes to contribute to the network’s security and efficiency.


                                        </p>
                                    </li>

                                </ol>
                            </div>

                        </div>

                        <div className="blockchain-system-area blockchain-technologies">
                            <h5>
                                Eight Applications of Blockchain Technology
                            </h5>
                            <div className="disclaimer-lists">
                                <ol>
                                    <li>
                                        Financial Sector
                                        <p>

                                            Blockchain has immense potential in financial areas like international exchange,
                                            letters of credit, equity registration, and stock exchanges. It can eliminate
                                            third-party intermediaries, enabling direct peer-to-peer transactions, reducing
                                            costs, and speeding up payments.
                                            <br />
                                            <span>
                                                - **Example**:
                                            </span>
                                            Visa's B2B Connect for cross-border payments, Citibank's Citicoin, and China's
                                            digital renminbi payment service in Xiong'an New District.

                                        </p>
                                    </li>

                                    <li>
                                        Internet of Things (IoT) and Logistics
                                        <p>
                                            Blockchain can enhance IoT and logistics by reducing costs, tracing item production
                                            and delivery, and improving supply chain management efficiency.

                                            <br />
                                            <span>
                                                - **Example**:
                                            </span>
                                            Blockchain combined with big data solutions can secure transactions and improve the
                                            efficiency of IoT transactions and intelligent logistics.

                                        </p>
                                    </li>

                                    <li>
                                        Public Service
                                        <p>
                                            Blockchain can transform public management, energy, and transportation by providing
                                            decentralized DNS services, ensuring system and firmware integrity, and securing IoT
                                            data transmission.

                                            <br />
                                            <span>
                                                - **Example**:
                                            </span>
                                            Using blockchain to monitor the status and integrity of important infrastructure
                                            software and firmware.

                                        </p>
                                    </li>

                                    <li>
                                        Digital Rights
                                        <p>

                                            Blockchain can authenticate and prove the existence of works like text, video, and
                                            audio, ensuring ownership authenticity and uniqueness. It supports real-time
                                            transaction recording and full lifecycle management of digital copyrights.
                                            <br />
                                            <span>
                                                - **Example**:
                                            </span>
                                            Mediachain by Mine Labs for digital image copyright protection using IPFS file
                                            system.

                                        </p>
                                    </li>

                                    <li>
                                        Insurance
                                        <p>

                                            Smart contracts can automate insurance claims, reducing management and operational
                                            costs for insurance companies.
                                            <br />
                                            <span>
                                                - **Example**:
                                            </span>
                                            LenderBot, launched by Stratumn, Deloitte, and Lemonway, allows registration for
                                            micro-insurance products via Facebook Messenger.

                                        </p>
                                    </li>

                                    <li>
                                        Public Welfare
                                        <p>
                                            Blockchain's reliable and tamper-proof data storage is suitable for public welfare
                                            scenarios, enhancing transparency and social supervision.

                                            <br />
                                            <span>
                                                - **Example**:
                                            </span>
                                            Storing donation project details, fundraising, and recipient feedback on the
                                            blockchain.

                                        </p>
                                    </li>

                                    <li>
                                        Healthcare
                                        <p>
                                            Blockchain can secure and streamline the management of medical records, ensuring
                                            data integrity and patient privacy.

                                            <br />
                                            <span>
                                                - **Example**:
                                            </span>
                                            Patients can grant access to their medical data securely, enabling better healthcare
                                            coordination and research.

                                        </p>
                                    </li>

                                    <li>
                                        Supply Chain Management
                                        <p>
                                            Blockchain can enhance supply chain transparency, traceability, and efficiency by
                                            recording every step of the product journey from origin to consumer.
                                            <br />
                                            <span>
                                                - **Example**:
                                            </span>
                                            Using blockchain to track the journey of food products, ensuring quality and
                                            authenticity.

                                        </p>
                                    </li>



                                </ol>
                            </div>

                        </div>

                        <div className="blockchain-system-area">
                            <h5>Nice Problems Faced in Blockchain Technology</h5>
                            <div className="disclaimer-lists">
                                <ol>
                                    <li>
                                        **Conceptual, Systemic, and Legal Restrictions**:

                                        <p>
                                            <span>
                                                - **Current Restrictions**:
                                            </span>
                                            Blockchain's decentralized nature and self-management challenge traditional systems
                                            and legal frameworks, creating friction.

                                            <br />
                                            <span>
                                                - **Global Perspectives**:
                                            </span>
                                            Countries have different attitudes towards blockchain applications, leading to
                                            regulatory uncertainty and hindering its adoption.
                                        </p>
                                    </li>

                                    <li>
                                        **Technical Challenges**:

                                        <p>
                                            <span>
                                                - **Initial Stage**:
                                            </span>
                                            Blockchain is in its early development stages, lacking mature, intuitive products
                                            similar to Internet technologies.

                                            <br />
                                            <span>
                                                - **Block Capacity**:
                                            </span>
                                            The growing data size in each block presents storage, verification, and capacity
                                            challenges that need to be addressed.
                                        </p>
                                    </li>

                                    <li>
                                        **Competitive Technological Challenges**:

                                        <p>
                                            <span>
                                                - **Competing Technologies**:
                                            </span>

                                            Other technologies, such as quantum communication, offer similar security and
                                            efficiency benefits, posing competition to blockchain.

                                        </p>
                                    </li>

                                    <li>

                                        **Scalability**:
                                        <p>
                                            <span>
                                                - **Transaction Speed**:
                                            </span>
                                            Blockchain currently struggles with processing a high volume of transactions
                                            quickly, impacting its scalability for large-scale applications.

                                        </p>
                                    </li>

                                    <li>
                                        **Energy Consumption**:

                                        <p>
                                            <span>
                                                - **Proof of Work (PoW)**:
                                            </span>

                                            The consensus mechanism used by many blockchains, like Bitcoin, consumes significant
                                            energy, raising environmental concerns.

                                        </p>
                                    </li>

                                    <li>
                                        **Interoperability**:

                                        <p>
                                            <span>
                                                - **Cross-Chain Communication**:
                                            </span>

                                            Different blockchain networks often cannot communicate or share data, limiting their
                                            functionality and integration potential.

                                        </p>
                                    </li>

                                    <li>
                                        **Security and Privacy**:

                                        <p>
                                            <span>
                                                - **51% Attack**:
                                            </span>
                                            If a single entity controls more than half of the network’s computational power, it
                                            can manipulate the blockchain, threatening security.

                                            <br />
                                            <span>
                                                - **Privacy Concerns**:
                                            </span>
                                            Despite being anonymous, blockchain transactions are transparent, leading to
                                            potential privacy issues for users.

                                        </p>
                                    </li>

                                    <li>
                                        **Regulation and Compliance**:

                                        <p>
                                            <span>
                                                - **Regulatory Uncertainty**:
                                            </span>
                                            The lack of clear regulations across jurisdictions creates challenges for blockchain
                                            adoption, especially in financial and legal sectors.



                                        </p>
                                    </li>

                                    <li>
                                        **User Adoption and Understanding**:

                                        <p>
                                            <span>
                                                - **Complexity**:
                                            </span>
                                            The technical complexity and newness of blockchain technology can deter user
                                            adoption and understanding, requiring substantial educational efforts.


                                        </p>
                                    </li>


                                </ol>
                            </div>

                        </div>

                    </div>

                </div>


            </section>
        </>
    );
};

export default Blockchain;