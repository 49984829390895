import React from "react";
import Diligent from "./Diligent/Diligent";
import ABOUtPROSPECTCAPITAL from "./ABOUtPROSPECTCAPITAL/ABOUtPROSPECTCAPITAL";
import INVEST from "./INVEST/INVEST";
import QUOTE from "./QUOTE/QUOTE";
import CHARTS from "./CHARTS/CHARTS";
import PROSPECT from "./PROSPECT/PROSPECT";
import UltimatePlatform from "./UltimatePlatform/UltimatePlatform";
import TradingPlatform from "./TradingPlatform/TradingPlatform";
import Features from "./Features/Features";


const DesktopHome = () => {
    return (
        <>
          <section className="body-section">
        {/* <!-- diligent area  --> */}

        <Diligent />

        {/* <!-- diligent area  end--> */}

        {/* <!-- ABOUT PROSPECT CAPITAL --> */}

        <ABOUtPROSPECTCAPITAL />

        {/* <!-- ABOUT PROSPECT CAPITAL end --> */}

        {/* <!-- WHY INVEST IN PROSPECT? --> */}

        {/* <!-- -----------------------------------WHY INVEST IN PROSPECT  ----------------------------------- --> */}
        <INVEST />

        {/* <!-- WHY INVEST IN PROSPECT? end --> */}

        <TradingPlatform />

        {/* <!-- -----------------------------------QUOTE  ----------------------------------- --> */}
        <QUOTE />

        {/* <!-- -----------------------------------QUOTE  end----------------------------------- --> */}

        {/* <!-- ----------------------------------- CHARTS  ----------------------------------- --> */}
        <CHARTS />

        {/* <!-- ----------------------------------- CHARTS end ----------------------------------- --> */}

        {/* <!-- -----------------------------------LATEST FROM PROSPECT  ----------------------------------- --> */}
        <PROSPECT />

        {/* <!-- -----------------------------------LATEST FROM PROSPECT end  ----------------------------------- --> */}
      </section>
      {/* <!-- -----------------------------------ultimate platform  ----------------------------------- --> */}

      <UltimatePlatform></UltimatePlatform>

      {/* <!-- -----------------------------------ultimate platform end  ----------------------------------- --> */}

      <Features></Features>
        </>
    )
};

export default DesktopHome;