import React, { useContext, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { AuthContext } from "../../../../Contexts/AuthContext/AuthProvider";
import { toast } from "react-toastify";
import axios from "axios";
 
const InvestModal = (props) => {
  const { singledata, setmodalshow } = props;
  const { authUser } = useContext(AuthContext);

  // Handle submit form

  const refSubmitDis = useRef();
  const [InputData, setInputData] = useState([]);

  const handleSubmitData = async (event) => {
    event.preventDefault();
    refSubmitDis.current.setAttribute("disabled", true);
    const storeData = { ...InputData, user_id: authUser?._id };
    try {
      const config = {
        headers: {
          "content-type": "application/json",
          authorization: `Beare ${authUser.token}`,
        },
      };

      await axios
        .post(
          `https://api2.prospectstreetx.com/api/user/tnvestment/plan/store/${singledata?._id}`,
          storeData,
          config
        )
        .then((data) => {
          toast.success(`${data.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          refSubmitDis.current.removeAttribute("disabled");
          if(data?.data?.success){
            setmodalshow(false);
          }
          event.target.reset();
        })
        .catch((error) => {
          toast.error(`${error?.response?.data?.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          refSubmitDis.current.removeAttribute("disabled");
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleMiningInputBlur = (event) => {
    const value = event.target.value;
    const field = event.target.name;
    const newUser = { ...InputData };
    newUser[field] = value;
    setInputData(newUser);
  };

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4 class="modal-title">Invest Plan</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <strong>Name: </strong>
            {singledata?.promotion_name}
          </p>
          <p>
            <strong>Percentage:</strong>{" "}
            <span style={{ color: "#fff" }}>{singledata?.percentage}%</span>
          </p>

          <br />
          <p className="mb-1">
            <strong>Amount</strong>
          </p>
          <form onSubmit={handleSubmitData}>
            <div class="bargaining__fieldset">
              <div id="timeSelection" class="time-selection mt-2">
                <div class="period-buttons"></div>
              </div>
              <input
                class="field__input bargaining__field"
                type="number"
                name="amount"
                placeholder="Amount($)"
                required
                id="amount"
                onBlur={handleMiningInputBlur}
              />
            </div>

            <p className="pb-2">
              <strong>Available Balance: </strong> {authUser?.balance}$
            </p>

            <button
              className="btn btn-success  w-100 "
              data-bs-toggle="modal"
              style={{ borderRadius: "30px" }}
              ref={refSubmitDis}
            >
              Submit
            </button>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default InvestModal;
