import React, { useContext, useEffect, useState } from "react";
import SingleItem from "./SingleItem";
import { AuthContext } from "../../../../Contexts/AuthContext/AuthProvider";
import Loader from "../../../Loader/Loader";
import InvestModal from "../../../Page/InvestPage/InvestModal/InvestModal";
const MobileInvestPlan = () => {
    const { token } = useContext(AuthContext);

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      fetch(`https://api2.prospectstreetx.com/api/user/tnvestment/plan/view`, {
        method: "GET",
      })
        .then((res) => res.json())
        .then((data) => {
          setData(data?.data);
          setLoading(false);
        });
    }, []);
  
    const [Modalshow, setModalshow] = useState(false);
    const [SingleData, setSingleData] = useState([]);
  
    const handleModal = (id) => {
      if (id) {
        fetch(`https://api2.prospectstreetx.com/api/user/tnvestment/plan/view/${id}`, {
          method: "GET",
        })
          .then((res) => res.json())
          .then((data) => {
            setSingleData(data?.data);
          });
      }
      setModalshow(true);
    };
  
    if (loading) {
      return (
        <>
          <Loader />
        </>
      );
    } else {
      return (
        <>
          <InvestModal
            className="posce-trade-modal"
            show={Modalshow}
            setmodalshow={setModalshow}
            singledata={SingleData}
            onHide={() => setModalshow(false)}
          />
  
          <section className="investment-plan-section mobile-user-investment-plan-section">
            <div className="planwrap">
              <div className="container">
                <div className="demo">
                  <div className="row gy-3">
                    {data?.length !== 0 ? (
                      data.map((data, index) => {
                        if (data) {
                          return (
                            <SingleItem
                              data={data}
                              index={index}
                              key={data._id}
                              token={token}
                              handleModal={handleModal}
                            ></SingleItem>
                          );
                        }
                      })
                    ) : (
                      <>
                        <div className="text-center h-50">
                          <h6> Data not found</h6>
                        </div>
                      </>
                    )}
                  </div>
                 
                </div>
              </div>
            </div>
          </section>
  
        </>
      );
    }
  };
export default MobileInvestPlan;