import React from 'react';
import dateFormat from "dateformat";
const SingleItem = ({ data, index, paginateData }) => {
    const tradeDate = data?.InTime;


    return (
        <>
            <tr>
                <td>{(parseFloat(index + 1) + (parseFloat(paginateData?.page) * parseFloat(paginateData?.limit))) - 10}</td>
                <td >{data?.Crypto}</td>
                <td>{data?.Amount}$</td>
                <td>
                    {data?.HighLow === "High"
                        ?
                        <span className="badge bg-success">High</span>
                        :
                        <span className="badge bg-danger">Low</span>
                    }
                </td>
                <td>
                    {data?.Result === null
                        ?
                        <span className="badge bg-warning ">Pending</span>
                        :
                        ''
                    }
                    {data?.Result === 'Win'
                        ?
                        <span className="badge bg-success">Win</span>
                        :
                        ''
                    }
                    {data?.Result === 'Loss'
                        ?
                        <span className="badge bg-danger">Loss</span>
                        :
                        ''
                    }
                    {data?.Result === 'Draw'
                        ?
                        <span className="badge bg-dark">Draw</span>
                        :
                        ''
                    }
                </td>
                <td>
                    {data?.Result === null
                        ?
                        <span className="badge bg-warning ">Running</span>
                        :
                        <span className="badge bg-success">Completed</span>
                    }
                </td>
                <td >{dateFormat(tradeDate, "dd.mm.yyyy,")}&nbsp;{dateFormat(tradeDate, "h:MM:ss")}&nbsp;{dateFormat(tradeDate, "TT")}
                </td>
            </tr>
        </>
    );
};

export default SingleItem;