import React, { useContext, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../Contexts/AuthContext/AuthProvider";
import logo from "./../../../logo.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";

const MobileRegisterForm = () => {
  const { LoginWithEmail } = useContext(AuthContext);

  const navigate = useNavigate();
  const location = useLocation();
  const userFrom = location.state?.from?.pathname || "/user/dashboard";

  const [userData, setUserData] = useState({});
  const [user, setUserValue] = useState({});
  const [userEmailError, setUserEmailError] = useState({});
  const refSubmitEmail = useRef();

  const handleRegister = (event) => {
    refSubmitEmail.current.setAttribute("disabled", true);
    event.preventDefault();
    fetch("https://api2.prospectstreetx.com/api/user/auth/signup/email", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(user),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success === false) {
          setUserEmailError(data);
          refSubmitEmail.current.removeAttribute("disabled");
        } else {
          setUserData(data);
        }
      })
      .catch((error) => console.log(error));
  };

  const handleInputBlur = (event) => {
    setUserEmailError({});
    const value = event.target.value;
    const field = event.target.name;
    const newUser = { ...user };
    // console.log(newUser);
    newUser[field] = value;
    setUserValue(newUser);
  };
  // Verify Code Section

  const [userCode, setUserCode] = useState({});
  const [userCodeData, setUserCodeData] = useState({});
  const [userCodeError, setUserCodeError] = useState({});
  const refSubmitCode = useRef();

  const handleRegisterVerifyCode = (event) => {
    refSubmitCode.current.setAttribute("disabled", true);
    event.preventDefault();
    setResend({});
    fetch("https://api2.prospectstreetx.com/api/user/auth/signup/email/verify", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        authorization: `Beare ${userData.token}`,
      },
      body: JSON.stringify(userCode),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success === false) {
          setUserCodeError(data);
          refSubmitCode.current.removeAttribute("disabled");
        } else {
          setUserCodeData(data);
        }
      })
      .catch((error) => console.log(error));
  };

  const CodehandleInputBlur = (event) => {
    const value = event.target.value;
    const field = event.target.name;
    const newUser = { ...setUserCode, email: userData?.data?.email };
    // console.log(newUser);
    newUser[field] = value;
    setUserCode(newUser);
  };

  // resend
  const refSubmitCodeResend = useRef();
  const [resend, setResend] = useState({});
  const handleResendCode = (event) => {
    event.preventDefault();
    refSubmitEmail.current.setAttribute("disabled", true);
    setUserCodeError({});
    setResend({});
    const data = { email: userData.data.email };
    fetch("https://api2.prospectstreetx.com/api/user/auth/signup/email", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success === false) {
          console.log(data);
        } else {
          refSubmitEmail.current.removeAttribute("disabled");
          setResend(data);
        }
      })
      .catch((error) => console.log(error));
  };

  const refSubmitPassword = useRef();
  const [userPassword, setUserPassword] = useState({});
  const [userPasswordError, setUserPasswordError] = useState({});

  const handleRegisterPassword = (event) => {
    event.preventDefault();
    refSubmitPassword.current.setAttribute("disabled", true);
    console.log(userPassword);
    fetch("https://api2.prospectstreetx.com/api/user/auth/signup", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(userPassword),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success === false) {
          setUserPasswordError(data);
          refSubmitPassword.current.removeAttribute("disabled");
        } else {
          setUserCode(data);
          const user = data;
          localStorage.setItem("ID", JSON.stringify(user.data));
          const expires = new Date(Date.now() + 365 * 24 * 60 * 60 * 1000).toUTCString();
          document.cookie = `gffex_token=OiJpcWJhbDExMSIsInVzZXJfaWQiOiI2M2VhNmE3MmJ1c2VyX25hbWMzODM5NX0VzZXJfaWQiOiI2M2InVzZXJfaWQiOiI2M2VhNmE3MmU4N2U5ZWJkNGM; expires=${expires}; path=/`;

          document.cookie = `mobile_version_gffex_token=OiJpcWJhbDExMSIsInVzZXJfaWQiOiI2M2VhNmE3MmJ1c2VyX25hbWMzODM5NX0VzZXJfaWQiOiI2M2InVzZXJfaWQiOiI2M2VhNmE3MmU4N2U5ZWJkNGM; expires=${expires}; path=/`;
          event.target.reset();
          console.log(user.data);
          if (user.data) {
            LoginWithEmail(user.data);
            navigate(userFrom, { replace: true });
          }
        }
      })
      .catch((error) => console.log(error));
  };

  const passwordhandleInputBlur = (event) => {
    setUserPasswordError({});
    const value = event.target.value;
    const field = event.target.name;
    const newUser = { ...userPassword, email: userCode?.email };
    // console.log(newUser);
    newUser[field] = value;
    setUserPassword(newUser);
  };

  
  
  const [passwordType, setPasswordType] = useState("password");
  const eyeActiveRef = useRef();

  const showPassword = () => {
    if(passwordType === "password"){
      setPasswordType("text");
      eyeActiveRef.current.classList.add("active");
    }else{
      setPasswordType("password");
      eyeActiveRef.current.classList.remove("active");
    }
  };

  
  const [CPasswordType, setCPasswordType] = useState("password");
  const eyeCpasswordActiveRef = useRef();

  const showCPassword = () => {
    if(CPasswordType === "password"){
      setCPasswordType("text");
      eyeCpasswordActiveRef.current.classList.add("active");
    }else{
      setCPasswordType("password");
      eyeCpasswordActiveRef.current.classList.remove("active");
    }
  };


  return (
    <>

      <section className="body-section">
        <section className="login-section">
          <div className="login-area">
            <div className="login-card">
              <div className="login-img">
                <Link to="/">
                  <LazyLoadImage
                    alt="img"
                    effect="blur"
                    src={logo}
                  />
                </Link>
              </div>

              {resend?.success === true ? (
                <span className=" text-start ms-2 pb-4 d-block text-success">
                  Code Resend Successfull
                </span>
              ) : (
                ""
              )}

              {userPasswordError?.success === false ? (
                <span className="text-danger text-start ms-2 pb-4 d-block">
                  {userPasswordError.message}
                </span>
              ) : (
                ""
              )}

              <div className="login-form-area">
                <form
                  onSubmit={handleRegister}
                  style={{ display: userData.success === true ? "none" : "" }}
                >
                  <div className="input-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      onBlur={handleInputBlur}
                      placeholder="Email address"
                    />
                    <span className="text-danger text-start ms-2">
                      {userEmailError?.message}
                    </span>
                  </div>

                  <div className="submit-btn-area">
                    <button
                      type="submit"
                      ref={refSubmitEmail}
                      className="button-1 submit-btn"
                    >
                      Signup
                    </button>
                    <Link to="/user/login" className="button-1 submit-btn">
                      Login
                    </Link>
                  </div>
                </form>
                <form
                  onSubmit={handleRegisterVerifyCode}
                  style={{
                    display:
                      userCodeData.success === true
                        ? "none"
                        : userData.success === true
                          ? ""
                          : "none",
                  }}
                >
                  <div className="input-group">
                    <label htmlFor="code">Verify code</label>
                    <input
                      type="text"
                      id="code"
                      name="code"
                      onBlur={CodehandleInputBlur}
                      placeholder="Enter your code"
                    />
                    <span className="text-danger text-start ms-2">
                      {userCodeError?.message}
                    </span>
                  </div>

                  <div className="submit-btn-area">
                    <button
                      type="submit"
                      ref={refSubmitCode}
                      className="button-1 submit-btn"
                    >
                      Verify
                    </button>

                  </div>
                </form>
                <form onClick={handleResendCode} style={{
                  display:
                    userCodeData.success === true
                      ? "none"
                      : userData.success === true
                        ? ""
                        : "none",
                }}>
                  <div className="submit-btn-area">
                    <button
                      type="submit"
                      ref={refSubmitCodeResend}
                      className="button-1 submit-btn"
                    >
                      Resend code
                    </button>
                  </div>
                </form>
                <form
                  onSubmit={handleRegisterPassword}
                  style={{ display: userCodeData.success === true ? "" : "none" }}
                >
                  <div className="input-group">
                    <label htmlFor="code">First Name</label>
                    <input
                      type="text"
                      id="fname"
                      name="fname"
                      onBlur={passwordhandleInputBlur}
                      placeholder="Enter your first name"
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="code">Last Name</label>
                    <input
                      type="text"
                      id="lname"
                      name="lname"
                      onBlur={passwordhandleInputBlur}
                      placeholder="Enter your last name"
                    />
                  </div>
                  <div className="input-group toggle-eye">
                    <label htmlFor="code">Password</label>
                    <input
                      type={passwordType}
                      id="password"
                      name="password"
                      onBlur={passwordhandleInputBlur}
                      placeholder="Enter your password"
                    />
                     <button type="button" onClick={showPassword}  ref={eyeActiveRef}>
                      <i className="fa fa-eye"></i>
                    </button>
                  </div>
                  <div className="input-group toggle-eye">
                    <label htmlFor="code">Confirm Password</label>
                    <input
                      type={CPasswordType}
                      id="cpassword"
                      name="cpassword"
                      onBlur={passwordhandleInputBlur}
                      placeholder="Enter your confirm password"
                    />
                     <button type="button" onClick={showCPassword}  ref={eyeCpasswordActiveRef}>
                      <i className="fa fa-eye"></i>
                    </button>
                  </div>

                  <div className="submit-btn-area">
                    <button
                      type="submit"
                      ref={refSubmitPassword}
                      className="button-1 submit-btn"
                    >
                      Confirm
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <Link to="/" className="home-btn">
            Home
          </Link>
        </section>
      </section>



    </>
  );
};


export default MobileRegisterForm;