import React from "react";

const FAQ = ({ faq, index, toggleFAQ }) => {
  return (


    <div
      className={"faq " + (faq.open ? "open" : "")}
      key={index}
      onClick={() => toggleFAQ(index)}
    >

<div className="faq-mobile">
<div className="faq-question">{faq.question}</div>
      <div className="faq-answer">
        
        <div className="faq-answer-area">

            <h5>Originations Strategy</h5>

            <span> {faq?.Originations}</span>

        </div>
        <div className="faq-answer-area">

            <h5>Industry</h5>

            <span> {faq?.Industry}</span>

        </div>
        <div className="faq-answer-area">

            <h5>Region</h5>

            <span> {faq?.Region}</span>

        </div>
        <div className="faq-answer-area">

            <h5>Secured Debt</h5>

            <span>{faq?.Secured} </span>

        </div>
        <div className="faq-answer-area">

            <h5> Unsecured Debt</h5>

            <span> {faq?.Unsecured} </span>

        </div>
        <div className="faq-answer-area">

            <h5>  Equity / Other</h5>

            <span> {faq?.Equity} </span>

        </div>
        
       
        
        </div>
</div>

<div className="faq-tablet">
<div className="faq-question">
  <div className="row">
    <div className="col-3">
      <span> {faq.question}</span>
    </div>
    <div className="col-3">
    <span>{faq?.Originations}</span>
    </div>
    <div className="col-3">
    <span>  {faq?.Industry}</span>
    </div>
    <div className="col-3">
    <span>{faq?.Region}</span>
    </div>
  </div>
  
  </div>
      <div className="faq-answer">
        <div className="faq-answer-area">

            <h5>Secured Debt</h5>

            <span>{faq?.Secured} </span>

        </div>
        <div className="faq-answer-area">

            <h5> Unsecured Debt</h5>

            <span> {faq?.Unsecured} </span>

        </div>
        <div className="faq-answer-area">

            <h5>  Equity / Other</h5>

            <span> {faq?.Equity} </span>

        </div>
        
       
        
        </div>
</div>

      
    </div>
  );
};

export default FAQ;
