import React, { useEffect, useState } from 'react';
import SingleItem from './SingleItem';

const Indices = ({handleClose}) => {
    const [data, setData] = useState([]);
    useEffect(() => {
        fetch(`https://api2.prospectstreetx.com/api/user/trade/market/indices/view`, {
            method: 'GET',
        })
            .then(res => res.json())
            .then(data => {
                setData(data?.data);
            });
    }, []);

    const SymbolString = data.reduce((acc, curr) =>
        `${acc}${curr.symbol},`, ''
    );

      const Symbol = SymbolString.substring(0, SymbolString?.length - 1);
    
      const [livePrice, setLivePrice] = useState([]);
    
      useEffect(() => {
        fetchData();
      }, [data]);
    
      const fetchData = async () => {
        if (Symbol?.length !== 0) {
          const data = await fetch(
            `https://marketdata.tradermade.com/api/v1/live?currency=${Symbol}&api_key=9iQMBHV42RgmV60x5iEi`
          );
          const apiResponse = await data.json();
          setLivePrice(apiResponse?.quotes);
        }
      };


    return (
        <>
            <div className="tab-content" id="pills-tabContent">
        <div className="tab-pane fade show active">
          <ul className="coin_menu mt-2">
          {data.map((data, index) => <SingleItem data={data} key={data?._id} index={index} livePrice={livePrice} handleClose={handleClose}></SingleItem>)}

          </ul>
        </div>
      </div>
        </>
    );
};

export default Indices;