import React from "react";
import "./COMMISION.css";
const COMMISION = () => {
  return (
    <>
    <section className="commision-section">
    <div className="referralwrap">
        <div className="container">
          <div className="referral-top">
            <div className="left" data-aos="zoom-in-up"  data-aos-duration="2000">
              <h3
                className="bounceInDown wow"
                style={{ visibility: "visible", animationName: "bounceInDown" }}
              >
                Referral <span>commission</span>
              </h3>
              <h2
                className="zoomIn wow"
                style={{ visibility: "visible", animationName: "zoomIn" }}
              >
                3 levels: <span>3%-1%-1%</span>
              </h2>
              <div className="present">
                <p>
                  Spread the word about the platform to your friends, family,
                  and social media followers. Make sure to include your unique
                  referral link so that you get commission for the referrals.
                </p>
                <div className="icon">
                  <img
                    src="https://bemarg.com/styles/images/affiliates.png"
                    alt=""
                    style={{ filter: "hue-rotate(130deg)" }}
                  />
                </div>
              </div>

              <div className="info">
                <img
                  src="https://bemarg.com/styles/images/attention.png"
                  alt=""
                />
                You don't even need an active deposit to receive affiliate
                commission.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    
    </>
  );
};

export default COMMISION;
