import React from "react";

const SingleItem = ({
  data,
  handleInputBlur,
  handleSubmitData,
  setFindLoanId,
  refSubmitDis,
}) => {
  // {data?.}
  if (data?.template === 1) {
    return (
      <>
        <div class="col-sm-6 col-md-6 loan-card">
          <div class="card text-center">
            <div class="title">
              <div class="loan-plane-icon">
                <i class="fa fa-paper-plane" aria-hidden="true"></i>
              </div>
              <h2>{data?.name}</h2>
            </div>
            <div class="price">
              <h4>
                {data?.percentage}%
                <span class="text-small">
                  &nbsp;/ {data?.period === 1 ? "Day" : "Days"}{" "}
                </span>
              </h4>
            </div>
            <div class="option">
              <ul>
                <li>
                  <i class="fa fa-check" aria-hidden="true"></i>Take Minimum: $
                  {data?.minimum_amount}
                </li>
                <li>
                  <i class="fa fa-check" aria-hidden="true"></i>Take Maximum: $
                  {data?.maximum_amount}
                </li>
                <li>
                  <i class="fa fa-check" aria-hidden="true"></i>Per Installment:{" "}
                  {data?.percentage}%
                </li>
                <li>
                  <i class="fa fa-check" aria-hidden="true"></i>
                  Installment Interval: {data?.period === 1 ? "Day" : "Days"}
                </li>
                <li>
                  <i class="fa fa-check" aria-hidden="true"></i>Total
                  Installment: 1
                </li>
              </ul>
            </div>
            <form onSubmit={handleSubmitData}>
              <div class="modal-body">
                <div class="form-group">
                  <label for="amount" class="required">
                    Amount
                  </label>
                  <div class="input-group  custom-input-group">
                    <input
                      type="number"
                      step="any"
                      name="amount"
                      class="form-control form--control"
                      placeholder="Enter An Amount"
                      id="amount"
                      onBlur={handleInputBlur}
                      required
                    />
                    <span class="input-group-text "> USD </span>
                  </div>
                </div>
                <button
                  type="submit"
                  class=" text-white mt-2 submit-btn  w-100"
                  onClick={() => setFindLoanId(data?._id)}
                  ref={refSubmitDis}
                >
                  Apply Loan
                </button>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
  if (data?.template === 2) {
    return (
      <>
        <div class="col-sm-6 col-md-6 loan-card">
          <div class="card text-center">
            <div class="title">
              <div class="loan-plane-icon">
                <i class="fa fa-paper-plane" aria-hidden="true"></i>
              </div>
              <h2>{data?.name}</h2>
            </div>
            <div class="price">
              <h4>
                {data?.percentage}%
                <span class="text-small">
                  &nbsp;/ {data?.period === 1 ? "Day" : "Days"}{" "}
                </span>
              </h4>
            </div>
            <div class="option">
              <ul>
                <li>
                  <i class="fa fa-check" aria-hidden="true"></i>Take Minimum: $
                  {data?.minimum_amount}
                </li>
                <li>
                  <i class="fa fa-check" aria-hidden="true"></i>Take Maximum: $
                  {data?.maximum_amount}
                </li>
                <li>
                  <i class="fa fa-check" aria-hidden="true"></i>Per Installment:{" "}
                  {data?.percentage}%
                </li>
                <li>
                  <i class="fa fa-check" aria-hidden="true"></i>
                  Installment Interval: {data?.period === 1 ? "Day" : "Days"}
                </li>
                <li>
                  <i class="fa fa-check" aria-hidden="true"></i>Total
                  Installment: 1
                </li>
              </ul>
            </div>
            <form onSubmit={handleSubmitData}>
              <div class="modal-body">
                <div class="form-group">
                  <label for="amount" class="required">
                    Amount
                  </label>
                  <div class="input-group  custom-input-group">
                    <input
                      type="number"
                      step="any"
                      name="amount"
                      class="form-control form--control"
                      placeholder="Enter An Amount"
                      id="amount"
                      onBlur={handleInputBlur}
                      required
                    />
                    <span class="input-group-text "> USD </span>
                  </div>
                </div>
                <button
                  type="submit"
                  class=" text-white mt-2 submit-btn  w-100"
                  onClick={() => setFindLoanId(data?._id)}
                  ref={refSubmitDis}
                >
                  Apply Loan
                </button>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
  if (data?.template === 3) {
    return (
      <>
        <div class="col-sm-12 col-md-6 loan-card">
          <div class="card text-center">
            <div class="title">
              <div class="loan-plane-icon">
                <i class="fa fa-paper-plane" aria-hidden="true"></i>
              </div>
              <h2>{data?.name}</h2>
            </div>
            <div class="price">
              <h4>
                {data?.percentage}%
                <span class="text-small">
                  &nbsp;/ {data?.period === 1 ? "Day" : "Days"}{" "}
                </span>
              </h4>
            </div>
            <div class="option">
              <ul>
                <li>
                  <i class="fa fa-check" aria-hidden="true"></i>Take Minimum: $
                  {data?.minimum_amount}
                </li>
                <li>
                  <i class="fa fa-check" aria-hidden="true"></i>Take Maximum: $
                  {data?.maximum_amount}
                </li>
                <li>
                  <i class="fa fa-check" aria-hidden="true"></i>Per Installment:{" "}
                  {data?.percentage}%
                </li>
                <li>
                  <i class="fa fa-check" aria-hidden="true"></i>
                  Installment Interval: {data?.period === 1 ? "Day" : "Days"}
                </li>
                <li>
                  <i class="fa fa-check" aria-hidden="true"></i>Total
                  Installment: 1
                </li>
              </ul>
            </div>
            <form onSubmit={handleSubmitData}>
              <div class="modal-body">
                <div class="form-group">
                  <label for="amount" class="required">
                    Amount
                  </label>
                  <div class="input-group  custom-input-group">
                    <input
                      type="number"
                      step="any"
                      name="amount"
                      class="form-control form--control"
                      placeholder="Enter An Amount"
                      id="amount"
                      onBlur={handleInputBlur}
                      required
                    />
                    <span class="input-group-text "> USD </span>
                  </div>
                </div>
                <button
                  type="submit"
                  class=" text-white mt-2 submit-btn  w-100"
                  onClick={() => setFindLoanId(data?._id)}
                  ref={refSubmitDis}
                >
                  Apply Loan
                </button>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
};

export default SingleItem;
