import React, { useRef } from "react";
import "./MobileTradePage.css";
import Crypto from "./Market/Crypto/Crypto";
import Metal from "./Market/Metal/Metal";
import Energises from "./Market/Energises/Energises";
import Stock from "./Market/Stock/Stock";
import Indices from "./Market/Indices/Indices";
import Forex from "./Market/Forex/Forex";

const MobileTradePage = () => {
  const CryptoShowRef = useRef();
  const MetalShowRef = useRef();
  const EnergisesShowRef = useRef();
  const StockShowRef = useRef();
  const IndicesShowRef = useRef();
  const ForexShowRef = useRef();

  const CryptoActiveClassRef = useRef();
  const MetalActiveClassRef = useRef();
  const EnergisesActiveClassRef = useRef();
  const StockActiveClassRef = useRef();
  const IndicesActiveClassRef = useRef();
  const ForexActiveClassRef = useRef();

  const MarketShowHandle = (data) => {
    if (data === "Crypto") {
      CryptoShowRef.current.style.display = "block";
      CryptoActiveClassRef.current.classList.add("active");

      MetalShowRef.current.style.display = "none";
      MetalActiveClassRef.current.classList.remove("active");

      EnergisesShowRef.current.style.display = "none";
      EnergisesActiveClassRef.current.classList.remove("active");

      StockShowRef.current.style.display = "none";
      StockActiveClassRef.current.classList.remove("active");

      IndicesShowRef.current.style.display = "none";
      IndicesActiveClassRef.current.classList.remove("active");

      ForexShowRef.current.style.display = "none";
      ForexActiveClassRef.current.classList.remove("active");
    }

    if (data === "Metal") {
      CryptoShowRef.current.style.display = "none";
      CryptoActiveClassRef.current.classList.remove("active");

      MetalShowRef.current.style.display = "block";
      MetalActiveClassRef.current.classList.add("active");

      EnergisesShowRef.current.style.display = "none";
      EnergisesActiveClassRef.current.classList.remove("active");

      StockShowRef.current.style.display = "none";
      StockActiveClassRef.current.classList.remove("active");

      IndicesShowRef.current.style.display = "none";
      IndicesActiveClassRef.current.classList.remove("active");

      ForexShowRef.current.style.display = "none";
      ForexActiveClassRef.current.classList.remove("active");
    }

    if (data === "Energises") {
      CryptoShowRef.current.style.display = "none";
      CryptoActiveClassRef.current.classList.remove("active");

      MetalShowRef.current.style.display = "none";
      MetalActiveClassRef.current.classList.remove("active");

      EnergisesShowRef.current.style.display = "block";
      EnergisesActiveClassRef.current.classList.add("active");

      StockShowRef.current.style.display = "none";
      StockActiveClassRef.current.classList.remove("active");

      IndicesShowRef.current.style.display = "none";
      IndicesActiveClassRef.current.classList.remove("active");

      ForexShowRef.current.style.display = "none";
      ForexActiveClassRef.current.classList.remove("active");
    }

    if (data === "Stock") {
      CryptoShowRef.current.style.display = "none";
      CryptoActiveClassRef.current.classList.remove("active");

      MetalShowRef.current.style.display = "none";
      MetalActiveClassRef.current.classList.remove("active");

      EnergisesShowRef.current.style.display = "none";
      EnergisesActiveClassRef.current.classList.remove("active");

      StockShowRef.current.style.display = "block";
      StockActiveClassRef.current.classList.add("active");

      IndicesShowRef.current.style.display = "none";
      IndicesActiveClassRef.current.classList.remove("active");

      ForexShowRef.current.style.display = "none";
      ForexActiveClassRef.current.classList.remove("active");
    }

    if (data === "Indices") {
      CryptoShowRef.current.style.display = "none";
      CryptoActiveClassRef.current.classList.remove("active");

      MetalShowRef.current.style.display = "none";
      MetalActiveClassRef.current.classList.remove("active");

      EnergisesShowRef.current.style.display = "none";
      EnergisesActiveClassRef.current.classList.remove("active");

      StockShowRef.current.style.display = "none";
      StockActiveClassRef.current.classList.remove("active");

      IndicesShowRef.current.style.display = "block";
      IndicesActiveClassRef.current.classList.add("active");

      ForexShowRef.current.style.display = "none";
      ForexActiveClassRef.current.classList.remove("active");
    }

    if (data === "Forex") {
      CryptoShowRef.current.style.display = "none";
      CryptoActiveClassRef.current.classList.remove("active");

      MetalShowRef.current.style.display = "none";
      MetalActiveClassRef.current.classList.remove("active");

      EnergisesShowRef.current.style.display = "none";
      EnergisesActiveClassRef.current.classList.remove("active");

      StockShowRef.current.style.display = "none";
      StockActiveClassRef.current.classList.remove("active");

      IndicesShowRef.current.style.display = "none";
      IndicesActiveClassRef.current.classList.remove("active");

      ForexShowRef.current.style.display = "block";
      ForexActiveClassRef.current.classList.add("active");
    }
  };

  return (
    <>
      <section className="mobile-user-trade-section">
        <div className="d-lg-none">
          <div className="bg-all mobile-view d-block d-lg-none">
          
            <section className="section_two">
              <div className="section_div container">
                <div className="section_div_div crypto">
                  <button
                    onClick={() => MarketShowHandle("Crypto")}
                    onclick="openList('a1', 'b1', 'blue');"
                    className="section_div_btn"
                  >
                    <i
                      className="fa-brands fa-bitcoin section_div_btn_svg"
                      style={{ color: "#4b0082" }}
                    ></i>
                  </button>
                  <h6
                    style={{ color: "gray" }}
                    ref={CryptoActiveClassRef}
                    className="section_div_p active"
                  >
                    Cryptocurrency
                  </h6>
                </div>

                <div className="section_div_div metal">
                  <button
                    onClick={() => MarketShowHandle("Metal")}
                    ref={MetalActiveClassRef}
                    onclick="openList('a2', 'b2', '#FF1493');"
                    className="section_div_btn"
                  >
                    <i
                      style={{ color: "#ff1493" }}
                      className="fas bg-white section_div_i  fa-industry section_div_btn_svg"
                    ></i>
                  </button>
                  <h6
                    ref={MetalActiveClassRef}
                    className="section_div_p"
                    style={{ color: "gray" }}
                  >
                    Metal
                  </h6>
                </div>

                <div className="section_div_div energises">
                  <button
                    onClick={() => MarketShowHandle("Energises")}
                    onclick="openList('a3', 'b3', '#4B0082');"
                    className="section_div_btn"
                  >
                    <svg
                      style={{ color: "blue" }}
                      className="section_div_btn_svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      {" "}
                      <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>{" "}
                      <polyline points="7.5 4.21 12 6.81 16.5 4.21"></polyline>{" "}
                      <polyline points="7.5 19.79 7.5 14.6 3 12"></polyline>{" "}
                      <polyline points="21 12 16.5 14.6 16.5 19.79"></polyline>{" "}
                      <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>{" "}
                      <line x1="12" y1="22.08" x2="12" y2="12"></line>
                    </svg>
                  </button>
                  <h6
                    ref={EnergisesActiveClassRef}
                    className="section_div_p"
                    style={{ color: "gray" }}
                  >
                    Energises
                  </h6>
                </div>
              </div>
              <div className="section_div_two container">
                <div className="section_div_div stock">
                  <button
                    onClick={() => MarketShowHandle("Stock")}
                    onclick="openList('a4', 'b4', 'orange');"
                    className="section_div_btn"
                  >
                    <i
                      className="fa-brands fa-meta section_div_btn_svg"
                      style={{ color: "orange" }}
                    ></i>
                  </button>
                  <h6
                    ref={StockActiveClassRef}
                    className="section_div_p"
                    style={{ color: "gray" }}
                  >
                    Stock
                  </h6>
                </div>

                <div className="section_div_div indices">
                  <button
                    onClick={() => MarketShowHandle("Indices")}
                    onclick="openList('a5', 'b5', 'green');"
                    className="section_div_btn"
                  >
                    <svg
                      style={{ color: "green" }}
                      className="section_div_btn_svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z"></path>
                      <path d="M12.971 3.54l6 3.333A2 2 0 0120 8.62v6.536a2 2 0 0 1 -1.029 1.748l-6 3.333a2 2 0 0 1 -1.942 0l-6-3.333A2 2 0 014 15.157V8.62a2 2 0 0 1 1.029 -1.748l6-3.333a2 2 0 0 1 1.942 0z"></path>
                    </svg>
                  </button>
                  <h6
                    ref={IndicesActiveClassRef}
                    className="section_div_p"
                    style={{ color: "gray" }}
                  >
                    Indices
                  </h6>
                </div>

                <div className="section_div_div forex">
                  <button
                    onClick={() => MarketShowHandle("Forex")}
                    onclick="openList('a6', 'b6','orangered');"
                    className="section_div_btn"
                  >
                    <i
                      className="fa-solid fa-sack-dollar section_div_btn_svg"
                      style={{ color: "orangered" }}
                    ></i>
                  </button>
                  <h6
                    ref={ForexActiveClassRef}
                    className="section_div_p"
                    style={{ color: "gray" }}
                  >
                    Forex
                  </h6>
                </div>
              </div>
            </section>
            <div className="tab-content" ref={CryptoShowRef}>
              <Crypto></Crypto>
            </div>

            <div
              className="tab-content"
              ref={MetalShowRef}
              style={{ display: "none" }}
            >
              <Metal></Metal>
            </div>

            <div
              className="tab-content"
              ref={EnergisesShowRef}
              style={{ display: "none" }}
            >
              <Energises></Energises>
            </div>

            <div
              className="tab-content"
              ref={StockShowRef}
              style={{ display: "none" }}
            >
              <Stock></Stock>
            </div>

            <div
              className="tab-content"
              ref={IndicesShowRef}
              style={{ display: "none" }}
            >
              <Indices></Indices>
            </div>

            <div
              className="tab-content"
              ref={ForexShowRef}
              style={{ display: "none" }}
            >
              <Forex></Forex>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MobileTradePage;
