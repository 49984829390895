import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../Contexts/AuthContext/AuthProvider";

const Diligent = () => {
  const { token } = useContext(AuthContext);
  return (
    <>
      <div
        className="et_pb_section et_pb_section_0 intro-section et_section_regular diligent-section"
        // style={{ height: "900px" }}
      >
        <div className="et_pb_row et_pb_row_0 et_pb_gutters1 et_pb_row_full_witdh">
          <div className="et_pb_column et_pb_column_1_2 et_pb_column_0  et_pb_css_mix_blend_mode_passthrough">
            <div className="et_pb_module et_pb_text et_pb_text_0 et_pb_bg_layout_light  et_pb_text_align_left"></div>
          </div>
          <div className="et_pb_column et_pb_column_1_2 et_pb_column_1  et_pb_css_mix_blend_mode_passthrough et-last-child devices-350">
            <div
              className="et_pb_module et_pb_text et_pb_text_1 intro-block et_pb_bg_layout_light  et_pb_text_align_left"
              data-aos="zoom-in"
            >
              <div className="et_pb_text_inner">
                <div className="intro-statement">
                  <h1>
                    <span className="intro-qualifier">Diligent.</span>{" "}
                    <span className="intro-qualifier">Dynamic.</span>
                    <span className="intro-qualifier">Determined.</span>
                  </h1>
                  <p>
                    Prospect Capital Corporation is a leading publicly-traded
                    Business Development Company (“BDC”). We make debt and
                    equity investments in U.S. middle market businesses across a
                    range of industries, and seek to deliver steady, attractive
                    returns to our shareholders.
                  </p>
                  <p>We trade on NASDAQ under the ticker symbol “PSEC”.</p>
                </div>
                {token ? (
                  ""
                ) : (
                  <div className="diligent-signup">
                    <Link to="/register" className="diligent-signup-desktop">
                      Register
                    </Link>
                    <Link
                      to="/user/register"
                      className="diligent-signup-mobile"
                    >
                      Register
                    </Link>
                  </div>
                )}

                <div className="intro-stat-block">
                  <div className="third">
                    <span className="intro-number h1-25 ">$7.9B</span>
                    <span className="intro-label">Assets</span>
                  </div>
                  <div className="third">
                    <span className="intro-number h1-25 ">400+</span>
                    <span className="intro-label">Investments Funded</span>
                  </div>
                  <div className="third et_column_last">
                    <span className="intro-number h1-25 ">$4.3B</span>
                    <span className="intro-label">Dividends Declared</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Diligent;
