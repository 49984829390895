import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import SingleItem from './SingleItem';
import IndicesSingleItem from './IndicesSingleItem';
const Stocks = ({ StocksData, StockslivePrice, Indicesdata, IndiceslivePrice }) => {

    return (
        <>
    <div class="tab-content" id="pills-tabContent">
              <div
                class="tab-pane fade show active"
                id="pills-top"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
                tabindex="0"
              >
                
                <div class="d-flex gap-2 flex-column my-3" id="coinContainer">
                {StocksData.map((data, index) => <SingleItem data={data} key={data?._id} index={index} StockslivePrice={StockslivePrice}></SingleItem>)}
                
                {Indicesdata.map((data, index) => <IndicesSingleItem data={data} key={data?._id} index={index} IndiceslivePrice={IndiceslivePrice}></IndicesSingleItem>)}

                    </div>
              </div>

             
            </div>


        </>
    );
};

export default Stocks;