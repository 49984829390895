import React, { useContext, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../Contexts/AuthContext/AuthProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "./../../../logo.jpg";
import Notices from "../../Home/Notices/Notices";
import SideBarMenu from "./SideBarMenu";
import ChatPage from "../../LiveChatsPage/ChatPage/ChatPage";
import "./Header.css";
const Header = () => {
  const { token } = useContext(AuthContext);

  const [isOpenUserSidebar, setIsopenUserSidebar] = useState(false);

  const ToggleUserSidebar = () => {
    isOpenUserSidebar === true
      ? setIsopenUserSidebar(false)
      : setIsopenUserSidebar(true);
  };
  const [isOpenAnnouncementSidebar, setIsopenAnnouncementSidebar] =
    useState(false);

  const ToggleAnnouncementSidebar = () => {
    isOpenAnnouncementSidebar === true
      ? setIsopenAnnouncementSidebar(false)
      : setIsopenAnnouncementSidebar(true);
  };

  // Chat Page

  const [chatPage, setChatPage] = useState(false);
  const ChatPageShowRef = useRef();

  const ChatPageShow = () => {
    ChatPageShowRef.current.style.display = "block";
    setChatPage(true);
  };
  const ChatPageHide = () => {
    ChatPageShowRef.current.style.display = "none";
    setChatPage(false);
  };

  // Chat Page End

  // const [logoData, setLogoData] = useState([]);
  // useEffect(() => {
  //   fetch(`https://api2.prospectstreetx.com/api/frontend/home/header/view`, {
  //     method: "GET",
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       setLogoData(data.HeaderData);
  //     });
  // }, []);

  const [menuShow, setMenuShow] = useState(false);
  const menuShowRef = useRef();

  const handleMobileMenu = () => {
    if (menuShow === false) {
      menuShowRef.current.style.display = "block";
      setMenuShow(true);
    } else {
      menuShowRef.current.style.display = "none";
      setMenuShow(false);
    }
  };
  const [subMenuShow, setSubMenuShow] = useState(false);
  const SubMenuShowRef = useRef();
  const SubMenuActiveRef = useRef();
  const MenuActiveRef = useRef();

  const handleMobileSubMenu = () => {
    if (subMenuShow === false) {
      SubMenuActiveRef.current.classList.add("active");
      MenuActiveRef.current.classList.add("menu-active");
      SubMenuShowRef.current.style.display = "block";
      setSubMenuShow(true);
    } else {
      SubMenuShowRef.current.style.display = "none";
      SubMenuActiveRef.current.classList.remove("active");
      MenuActiveRef.current.classList.remove("menu-active");
      setSubMenuShow(false);
    }
  };

  return (
    <>
      <section className="desktop-menu">
        <header id="main-header" className="et-fixed-header ">
          <div className="d-flex align-items-center justify-content-between px-4 et_menu_container custom-container">
            <div className="logo_container">
              <span className="logo_helper"></span>
              <Link
                to="/"
                id="trackE3"
                title="
                        
                    "
              >
                <img
                  src={logo}
                  alt="Prospect Capital | PSEC"
                  id="logo"
                  data-height-percentage="54"
                  data-actual-width="684"
                  data-actual-height="108"
                />
              </Link>
            </div>
            <div
              id="et-top-navigation"
              data-height="85"
              data-fixed-height="30"
              style={{ paddingLeft: " 570px" }}
            >
              <nav id="top-menu-nav">
                <ul id="top-menu" className="nav">
                  <li className="">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="">
                    <Link to="/trade">Trade</Link>
                  </li>
                  <li className="">
                    <Link to="/apply/loan">Loan</Link>
                  </li>
                  <li className="">
                    <Link to="/bitcoin/mining">Mining</Link>
                  </li>
                  <li className="">
                    <Link to="/invest/plan">Invest Plan</Link>
                  </li>
                  <li className="">
                    <Link to="/portfolio">Portfolio</Link>
                  </li>
                  <li className="">
                    <Link to="/news">News</Link>
                  </li>
                  <li className="dropdwon-menu-show">
                    <Link to="/#">Support</Link>

                    <ul className="sub-menu" style={{ display: "block" }}>
                      <li className="menu-item">
                        <Link to="/contact/us">Contact Us </Link>
                      </li>
                      <li className="menu-item">
                        {token ? (
                          <button onClick={ChatPageShow}>Live Chat</button>
                        ) : (
                          <Link to="/login">Live Chat</Link>
                        )}
                      </li>
                      <li className="menu-item">
                        <a
                          href="https://t.me/infopcxofficial"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Telegram
                        </a>
                      </li>
                    </ul>
                    {/* <Nav>
                      <NavDropdown
                        id="nav-dropdown-dark-example"
                        title="Support"
                        menuVariant="dark"
                      >
                        <Link to="/contact/us">Contact Us </Link>


                        {token ?
                          <button onClick={ChatPageShow}>Live Chat</button>
                          :
                          <></>
                        }

                      </NavDropdown>
                    </Nav> */}
                  </li>
                </ul>
              </nav>
              <div id="et_top_search">
                <span id="et_search_icon"></span>
              </div>
            </div>
            <div className="header-right d-flex align-items-center justify-content-end">
              {token ? (
                <>
                  <div
                    className="sidebar-toggle-Btn"
                    onClick={ToggleUserSidebar}
                  >
                    <FontAwesomeIcon icon="fa-regular fa-user" />
                  </div>
                  <div
                    className="sidebar-toggle-Btn"
                    onClick={ToggleAnnouncementSidebar}
                  >
                    <FontAwesomeIcon icon="fa-solid fa-bullhorn" />
                  </div>
                </>
              ) : (
                <Link to="/login" className="btn btn-primary">
                  Login
                </Link>
              )}
            </div>
          </div>
        </header>
      </section>

      <section className="mobile-menu">
        <header id="main-header" className="et-fixed-header">
          <div className="container clearfix et_menu_container">
            <div className="logo_container">
              <Link
                to="/"
                id="trackE3"
                title="
                        
                    "
              >
                <img
                  src="//s23.q4cdn.com/831501628/files/images/irw/Prospect_Capital_Logo_FINAL_CMYK.jpg"
                  alt="Prospect Capital | PSEC"
                  id="logo"
                  data-height-percentage="54"
                  data-actual-width="684"
                  data-actual-height="108"
                />
              </Link>
            </div>
            <div
              id="et-top-navigation"
              data-height="85"
              data-fixed-height="30"
              style={{ paddingLeft: " 570px" }}
            >
              <div id="et_mobile_nav_menu">
                <div className="mobile_nav closed">
                  <div
                    className="d-flex justify-content-between align-items-center mobile_nav-svg"
                    onClick={handleMobileMenu}
                  >
                    <span className="select_page">Select Page</span>
                    <i className="fa-solid fa-bars"></i>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- #et-top-navigation --> */}
          </div>
          {/* <!-- .container --> */}
        </header>

        <section id="mobile-header_main" className="mobile-header">
          <nav
            id="mobile-main-nav-mobi"
            className="mobile-main-nav"
            style={{ display: "none" }}
            ref={menuShowRef}
          >
            <ul>
              <li className="">
                <Link to="/" onClick={handleMobileMenu}>
                  Home
                </Link>
              </li>
              <li className="">
                <Link to="/user/trade" onClick={handleMobileMenu}>
                  Trade
                </Link>
              </li>
              <li className="">
                <Link to="/user/apply/loan" onClick={handleMobileMenu}>
                  Loan
                </Link>
              </li>
              <li className="">
                <Link to="/user/bitcoin/mining" onClick={handleMobileMenu}>
                  Mining
                </Link>
              </li>
              <li className="">
                <Link to="/investment/plan" onClick={handleMobileMenu}>
                  Invest Plan
                </Link>
              </li>
              <li className="">
                <Link to="/portfolio" onClick={handleMobileMenu}>
                  Portfolio
                </Link>
              </li>
              <li className="">
                <Link to="/news" onClick={handleMobileMenu}>
                  News
                </Link>
              </li>
              <li
                className="mobile-menu-item mobile-menu-item-has-children"
                onClick={handleMobileSubMenu}
                ref={MenuActiveRef}
              >
                <Link to="#"> Support</Link>
                <span className="arrow" ref={SubMenuActiveRef}></span>
                <ul
                  className="mobile-sub-menu"
                  style={{ display: "none" }}
                  ref={SubMenuShowRef}
                >
                  <li className="mobile-menu-item">
                    <Link to="/contact/us" onClick={handleMobileMenu}>
                      Contact Us
                    </Link>
                  </li>
                  <li className="mobile-menu-item">
                    {token ? (
                      <Link onClick={ChatPageShow} to="#">
                        Live Chat
                      </Link>
                    ) : (
                      <Link to="/user/login">Live Chat</Link>
                    )}
                  </li>
                  <li className="mobile-menu-item">
                    <a
                      href="https://t.me/infopcxofficial"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Telegram
                    </a>
                  </li>
                </ul>
              </li>
              <li className="">
                <Link to="/user/login" onClick={handleMobileMenu}>
                  Login
                </Link>
              </li>
            </ul>
          </nav>
        </section>
      </section>

      {/* User Sidebar */}
      <div
        className={`sidebar sidebar-bg ${
          isOpenUserSidebar === true ? "active" : ""
        }`}
      >
        <SideBarMenu ToggleUserSidebar={ToggleUserSidebar}></SideBarMenu>
      </div>

      {/* Announcement */}
      <div
        className={`sidebar ${
          isOpenAnnouncementSidebar === true ? "active" : ""
        }`}
      >
        <Notices
          ToggleAnnouncementSidebar={ToggleAnnouncementSidebar}
        ></Notices>
      </div>

      <div style={{ display: "none" }} ref={ChatPageShowRef}>
        {(() => {
          if (chatPage) {
            return <ChatPage ChatPageHide={ChatPageHide}></ChatPage>;
          }
        })()}
      </div>
    </>
  );
};

export default Header;
