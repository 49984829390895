import React from "react";
import "./AboutsUs.css";
const AboutsUs = () => {
  return (
    <>
      <section className="privacy-policy-section about-section">
        <div className="privacy-policy-title">
          <h5>About Prospect</h5>
        </div>
        <div className="container">
          <div className="privacy-policy about-area">
            <h2>
              Prospect Capital Corporation is a leading publicly-traded Business
              Development Company (“BDC”). We make debt and equity investments
              in U.S. middle market businesses across a range of industries, and
              seek to deliver steady, attractive returns to our shareholders.
              <br />
              We trade on NASDAQ under the ticker symbol “PSEC”.
            </h2>
            <p>
              PSEC is a publicly-traded closed-end investment company that has
              elected to be treated as a business development company under the
              Investment Company Act of 1940, as amended. PSEC completed its
              initial public offering in 2004.
              <br />
              <br />
              PSEC invests primarily in first-lien and second-lien senior loans
              and mezzanine debt, which in some cases include an equity
              component. We provide capital to middle-market companies and
              private equity financial sponsors for refinancings, leveraged
              buyouts, acquisitions, recapitalizations, later-stage growth
              investments, and capital expenditures. PSEC’s portfolio is
              diversified across a wide variety of industries, including
              manufacturing, industrials, energy, business services, financial
              services, food, healthcare, and media, as well as many other
              sectors. PSEC also invests in other high income producing
              strategies including collateralized loan obligations (CLOs),
              marketplace lending, and multi-family real estate.
              <br />
              <br />
              Our investment objective is to generate both current income and
              long-term capital appreciation through debt and equity
              investments. We seek to maximize returns and protect risk for our
              investors by applying rigorous credit analysis to make and monitor
              our investments. PSEC is a yield-oriented investor and has paid a
              continuous, regular dividend to its investors since inception.
              <br />
              <br />
              PSEC is managed by Prospect Capital Management L.P. (“PCM”). PCM
              has been registered as an investment advisor with the United
              States Securities and Exchange Commission since 2004. PCM, its
              predecessors and affiliates have a 35-year history of investing in
              companies and managing high-yielding debt and equity investments,
              using both private partnership and publicly-traded closed-end
              structures.
            </p>
          </div>
          <div className="privacy-policy about-area p-0">
            <div className="blockchain-type-area py-3">
              <h6>What is a BDC?</h6>
            </div>
            <h2>
              A BDC is a closed-end investment company that focuses on investing
              in small-sized and medium-sized private companies rather than
              large public companies.
            </h2>
            <p>
              Since their creation by Congress in 1980, BDCs have become an
              important source of capital by lending to American businesses that
              might not otherwise obtain financing from the banking or capital
              markets. Our industry today has grown to approximately 50 publicly
              traded BDCs, which pay no corporate income tax if, among other
              requirements, they distribute at least 90% of their taxable income
              to shareholders.
              <br />
              <br />
              BDCs are regulated under the Securities Act of 1933 and the
              Investment Company Act of 1940. As a result, shareholders of BDCs
              receive the investor protections of our securities laws while
              having an opportunity to participate in the types of investments
              that otherwise are only available to deep-pocketed investors
              through private partnerships. In addition, BDCs offer advantages
              to the companies that are in need of investment capital to grow.
              For many of the companies in which a BDC invests, traditional
              sources of financing like bank lending or public offerings are
              unavailable. BDCs are also required to offer managerial assistance
              to the companies in which they invest.
              <br />
              <br />
              In summary, BDCs provide substantial benefits to the American
              economy, providing an alternative source of capital for
              small-sized and medium-sized private companies subject to public
              disclosure and transparency. BDCs also serve as a tax-efficient
              and income-oriented investment vehicle for shareholders.
            </p>
          </div>

          <div className="blockchain-system-area">
            <h5>Why Invest in Prospect?</h5>
            <div className="privacy-policy about-area p-0">
              <h2>
                Prospect Capital Corporation is among the oldest and largest
                BDCs. Throughout our 20 years as a public company, we have
                provided consistent returns to our shareholders through our
                disciplined approach to investing in the U.S. middle market.
              </h2>
            </div>
            <div className="disclaimer-lists">
              <ol>
                <li>
                  Attractive Dividend Yield
                  <p>
                    PSEC is a yield-oriented investor and has paid a continuous,
                    regular dividend to its investors since inception. We have
                    declared dividends to common shareholders totaling
                    approximately $4.3 billion, since our 2004 IPO1. We have
                    also declared 84 consecutive $0.06 per share dividends to
                    common shareholders.
                  </p>
                </li>
                <li>
                  Focus on Senior and Secured Lending
                  <p>
                    PSEC is focused on providing senior and secured term loans
                    to U.S. middle market businesses. Of our total investments,
                    81% are in the form of loans secured by a first lien or
                    other secured debt2. For the quarter ended on March 31,
                    2024, 91% of our total investment income was interest income
                    on loans.
                  </p>
                </li>
                <li>
                  Proven Origination Strategies
                  <p>
                    Our team has developed a broad and deep network of U.S.
                    middle market relationships over many years of investing,
                    including extensive relationships with private equity firms,
                    other capital providers, business owners and managers, and
                    intermediaries.
                  </p>
                </li>
                <li>
                  Dividend Reinvestment Plan
                  <p>
                    We encourage any shareholder interested in participating in
                    our dividend reinvestment plan (also known as a “DRIP” or
                    “DRP”) to contact his or her broker to make sure such DRIP
                    participation election has been made for the benefit of such
                    shareholder. In making such DRIP election, be sure to
                    specify to your broker the desire to participate in the
                    "Prospect Capital Corporation DRIP plan through DTC" that
                    issues shares based on 95% of the market price (a 5%
                    discount to the market price), and not the broker's own
                    "synthetic DRIP” plan with 0% discount.
                  </p>
                </li>
                <li>
                  Broad Investment Portfolio
                  <p>
                    Since its 2004 IPO, PSEC has made over 400 investments
                    totaling more than $20.7 billion of capital with 122 current
                    portfolio companies spanning 36 separate industries.
                  </p>
                </li>
                <li>
                  Large and Experienced Team
                  <p>
                    We cover the U.S. middle market with a team of over 120
                    professionals with experience investing across a range of
                    industries and through multiple economic and investing
                    cycles, with offices in New York, Florida, and Connecticut.
                    Our investment professionals are supported by a dedicated
                    team of attorneys, accountants, and other specialists.
                  </p>
                </li>
                <li>
                  Conservative, Strong Capitalization
                  <p>
                    With $7.9 billion of assets, PSEC is among the largest of
                    the BDCs. We benefit from a strong balance sheet with
                    long-term matched-book funding, reasonable leverage, and a
                    high level of unencumbered assets. As affirmation of our
                    financial profile, we have investment grade ratings from
                    S&P, Moody’s, Kroll, DBRS, and Egan-Jones. Management and
                    affiliates of Prospect own approximately 27% of outstanding
                    PSEC shares as of March 31, 2024, so we are very much
                    aligned with our shareholders.
                  </p>
                </li>
                <li>
                  Direct Stock Purchase Plan
                  <p>
                    Prospect Capital Corporation offers the opportunity to
                    directly purchase its stock through a Direct Stock Purchase
                    Plan administered by Equiniti Trust Company, LLC.
                  </p>
                </li>
              </ol>
            </div>
          </div>

          <div className="about-content-last">
            <span>
              All information derived from PSEC filings and management, and is
              as of 03/31/2024 unless otherwise noted.
              <br />
              <br />
              Past performance does not guarantee future results and current
              performance may be lower or higher than the performance contained
              herein. An investment in PSEC’s common stock may fluctuate such
              that an investor’s shares, when sold, may be worth more or less
              than their original cost.
              <br />
              <br />
              Investors are advised to carefully consider the investment
              objective, risks, charges and expenses of PSEC before investing. A
              prospectus dated February 10, 2023, and a prospectus supplement
              dated December 29, 2023, have been filed with the Securities and
              Exchange Commission. The supplemental prospectus and prospectus
              contain information about PSEC and should be read carefully before
              investing. A more detailed discussion of risks and uncertainties
              is contained in the prospectus supplement and prospectus.
              <br />
              <br />
              This information page does not constitute an offer to sell or a
              solicitation of an offer to buy nor will there be any sale of the
              shares referred to in this information page in any state or
              jurisdiction in which such offer, solicitation or sale would be
              unlawful prior to the registration or qualification under the
              securities laws of such state or jurisdiction.
              <br />
              <br />
              The information contained herein may contain forward-looking
              statements. Any such statements are likely to be affected by
              unknowable future events and conditions, most of which are not
              under PSEC’s control and that PSEC may not have considered;
              accordingly, such statements cannot be guarantees or assurances of
              any aspect of future performance, and actual developments and
              results may vary materially. Such statements speak only as of the
              time when made, and PSEC undertakes no obligation to update any
              such statement now or in the future.
              <br />
              <br />
              Certain information discussed in this presentation (including
              information relating to portfolio companies) was derived from
              third party sources and has not been independently verified and,
              accordingly, Prospect Capital Corporation makes no representation
              or warranty in respect of this information.
              <br />
              <br />
              1Dividends may not continue at the current level if at all.
              2Includes underlying collateral in securitizations, represented by
              Prospect's investments in CLOs.
            </span>
          </div>
        </div>

        <div className="portfolio-more-btn py-4 text-center">
          <a
            href="https://companiesmarketcap.com/prospect-capital/marketcap/"
             target="_blank"
                      rel="noopener noreferrer"
            className="btn btn-primary py-2 px-4"
          >
           More Information
          </a>
        </div>

      </section>
    </>
  );
};

export default AboutsUs;
