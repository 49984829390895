import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import ListSingleItems from "./ListSingleItems";
import GridSingleItems from "./GridSingleItems";
import { Circles } from "react-loader-spinner";
import CustomPaginationMobile from "../../../CustomPagination/CustomPaginationMobile";
import Loader from "../../../Loader/Loader";
const News = () => {
  const [loading, setLoading] = useState(true);
  const [paginateData, setPaginateData] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch(`https://api2.prospectstreetx.com/api/frontend/home/news/view`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data.data);
        setPaginateData(data);
        setLoading(false);
      });
  }, []);

  const handlePage = (event) => {
    try {
      const pageNumber = event?.selected + 1;
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      axios
        .get(
          `https://api2.prospectstreetx.com/api/frontend/home/news/view?page=${pageNumber}`,
          config
        )
        .then((response) => {
          setData(response?.data?.data);
          setPaginateData(response?.data);
        })
        .catch((error) => {});
    } catch (error) {
      console.log(error);
    }
  };

  const ListsShowRef = useRef();
  const GridShowRef = useRef();
  const handeSHow = (data) => {
    switch (data) {
      case "lists":
        ListsShowRef.current.style.display = "block";
        GridShowRef.current.style.display = "none";
        break;
      case "grids":
        GridShowRef.current.style.display = "block";
        ListsShowRef.current.style.display = "none";
        break;

      default:
        break;
    }
  };

  if(loading){
    return (
      <>
        <Loader></Loader>
    </>
    );
  }else{

    return (
      <>
        <main className="mobile-news-section news-section">
          <div className="container">
            <div className="news-breadcrumb">
              <h5>News</h5>
            </div>
            <div className="news-dis-area">
              <p>
                Prospect Capital Corporation (NASDAQ: PSEC) is a leading provider
                of flexible private debt and equity capital to sponsor-owned and
                non-sponsor-owned middle-market companies in the United States and
                Canada. PSEC is a publicly-traded closed-end investment company
                (NASDAQ: PSEC) that has elected to be regulated as a business
                development company under the Investment Company Act of 1940, as
                amended. PSEC is managed by Prospect Capital Management L.P.
              </p>
            </div>
            <div className="news-menu-area">
              <div className="irwTabs col-xs-12">
                <ul className="irwCommonTabs">
                  <li className="active">
                    <a className="news_tag" rel="all" href="#all">
                      All Releases
                    </a>
                  </li>
                  <li>
                    <a className="news_tag" rel="earnings" href="#earnings">
                      Earnings Releases
                    </a>
                  </li>
                  <li>
                    <a className="news_tag" rel="portfolio" href="#portfolio">
                      Portfolio Releases
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="news search-bar-area">
              <div className="row">
                <div className="col-12">
                  <h5>Search Press Releases</h5>
                  <div>
                    <div className="row">
                      <div className="col-9">
                        <div className="search-input-area">
                          <input
                            type="text"
                            className="form-control search_input"
                            name="filterBy"
                          />
                          <span className="fa fa-search form-control-feedback filterBy"></span>
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="filter-option-area">
                          <select
                            className="form-control module_options-year"
                            id="newsYear"
                          >
                            <option value="-1">All Years</option>
                            <option value="2024">2024</option>
                            <option value="2023">2023</option>
                            <option value="2022">2022</option>
                            <option value="2021">2021</option>
                            <option value="2020">2020</option>
                            <option value="2019">2019</option>
                            <option value="2018">2018</option>
                            <option value="2017">2017</option>
                            <option value="2016">2016</option>
                            <option value="2015">2015</option>
                            <option value="2014">2014</option>
                            <option value="2013">2013</option>
                            <option value="2012">2012</option>
                            <option value="2011">2011</option>
                            <option value="2010">2010</option>
                            <option value="2009">2009</option>
                            <option value="2008">2008</option>
                            <option value="2007">2007</option>
                            <option value="2006">2006</option>
                            <option value="2005">2005</option>
                            <option value="2004">2004</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
  
            <div className="news-view-area">
              <span onClick={() => handeSHow("lists")}>
                <i className="fa-solid fa-list"></i> List View{" "}
              </span>
              <span onClick={() => handeSHow("grids")}>
                <i className="fa-solid fa-grip"></i> Grid View
              </span>
            </div>
  
            <div className="main-newes-area">
              <div className="news-lists-area" ref={ListsShowRef}>
                <div className="news-lists-header">
                  <div className="row">
                    <div className="col-3">
                      <span> Date</span>
                    </div>
                    <div className="col-9">
                      <span> Headline</span>
                    </div>
                  </div>
                </div>
                <div className="news-lists-items">
                  {data.length !== 0 ? (
                    data.map((data, index) => {
                      if (data) {
                        return (
                          <ListSingleItems
                            data={data}
                            index={index}
                            key={data._id}
                            paginateData={paginateData}
                          ></ListSingleItems>
                        );
                      }
                    })
                  ) : (
                    <tr>
                      <td className="text-muted text-center" colspan="100%">
                        Data not found
                      </td>
                    </tr>
                  )}
                </div>
              </div>
  
              <div
                className="news-grid-area"
                ref={GridShowRef}
                style={{ display: "none" }}
              >
                <div className="row row-cols-1 row-cols-md-3 g-4">
  
                {data.length !== 0 ? (
                    data.map((data, index) => {
                      if (data) {
                        return (
                          <GridSingleItems
                            data={data}
                            index={index}
                            key={data._id}
                            paginateData={paginateData}
                          ></GridSingleItems>
                        );
                      }
                    })
                  ) : (
                    <tr>
                      <td className="text-muted text-center" colspan="100%">
                        Data not found
                      </td>
                    </tr>
                  )}
  
                  
                </div>
              </div>
  
      {/* paginateLinks */}
      <CustomPaginationMobile
                          data={paginateData} handlePage={handlePage}
                        ></CustomPaginationMobile>
                        {/* paginateLinks */}

            </div>
          </div>
        </main>
      </>
    );
  }



};

export default News;
