import React, { useEffect, useContext, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "./../../../logo.jpg";
import "./MobileUserHeader.css";
import { AuthContext } from "../../../Contexts/AuthContext/AuthProvider";
import user from "./user.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import MobileSideBarMenu from "./MobileSideBarMenu/MobileSideBarMenu.js";
import { toast } from "react-toastify";
const MobileUserHeader = () => {
  const { LoginWithEmail, authUser } = useContext(AuthContext);

  const [show, setShow] = useState(false);
  const ShowMenuRef = useRef();
  const ShowMenuclassRef = useRef();

  const handleShow = () => {
    if (show) {
      ShowMenuRef.current.style.display = "none";
      setShow(false);
    } else {
      ShowMenuRef.current.style.display = "block";
      setShow(true);
    }
  };

  const from = "/";
  const navigate = useNavigate();
  const [remove, setremove] = useState(false);

  const SignOut = () => {
    document.cookie = `gffex_token=; expires=${new Date(
      0
    ).toUTCString()}; path=/`;
    document.cookie = `mobile_version_gffex_token=; expires=${new Date(
      0
    ).toUTCString()}; path=/`;
    localStorage.removeItem("ID");
    const data = null;
    LoginWithEmail(data);
    if (authUser === null) {
      navigate(from, { replace: true });
      toast.error(`Signout Successfull`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    setremove(true);
  };
  const logout = localStorage.getItem("ID");

  useEffect(() => {
    if (!logout && remove === true) {
      navigate(from, { replace: true });
      handleShow();
    }
  }, [logout, remove, navigate]);

  return (
    <>
      <div className="mobile-home-slider-menu-area mobile-header-menu-area mobile-user-dashborad-menu-area">
        <div className="d-flex justify-content-between align-items-center customer-support-mobile">
          <Link to="/user/dashboard" id="trackE3" className="mobile-logo-link">
            <LazyLoadImage
              effect="blur"
              src={logo}
              alt="Prospect Capital | PSEC"
              id="logo"
              data-height-percentage="54"
              data-actual-width="684"
              data-actual-height="108"
              className="mobile-user-dashborad-logo"
            />

            {/* <img
              src={logo}
              alt="Prospect Capital | PSEC"
              id="logo"
              data-height-percentage="54"
              data-actual-width="684"
              data-actual-height="108"
            /> */}
          </Link>

          <nav className="mobile-user-profile-dropdwon">
            <div className="profile" onClick={handleShow}>
              <div className="img-box">
                <i className="fa-solid fa-user"></i>
              </div>
            </div>
          </nav>
        </div>
      </div>

      <div style={{ display: "none" }} ref={ShowMenuRef}>
        <MobileSideBarMenu
          handleShow={handleShow}
          SignOut={SignOut}
          authUser={authUser}
        ></MobileSideBarMenu>
      </div>
    </>
  );
};

export default MobileUserHeader;
